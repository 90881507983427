<template>
  <div class="verify-email-container">
    <div class="verify-email-content">
      <div class="icon-container">
        <span class="mail-icon">✉️</span>
      </div>
      <h1 class="title">Verify Your Email</h1>
      <p class="description">
        To continue using JobBuddy, please verify your email address by clicking the link we sent
        you.
      </p>
      <div class="actions">
        <button
          class="action-button primary"
          @click="resendVerificationEmail"
          :disabled="isResending"
        >
          <span v-if="!isResending">Resend Verification Email</span>
          <span v-else>Sending...</span>
        </button>
        <button class="action-button secondary" @click="reloadPage">I've Verified My Email</button>
      </div>
      <div v-if="showResendSuccess" class="success-message">
        ✓ Verification email sent successfully!
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import store from "../store";
import { UserApiService } from "@/services/UserApiService";
import router from "../router";
import { onMounted, ref } from "vue";

const isResending = ref(false);
const showResendSuccess = ref(false);

onMounted(() => {
  if (sessionStorage.getItem("redirectToDashboard") === "true") {
    sessionStorage.removeItem("redirectToDashboard");
    router.push({ name: "Subscribe" });
  }
});

async function resendVerificationEmail() {
  try {
    isResending.value = true;
    store.dispatch("toggleLoader", true);
    await UserApiService.resendVerificationEmail();
    showResendSuccess.value = true;
    setTimeout(() => {
      showResendSuccess.value = false;
    }, 3000);
  } catch (error) {
    console.error("Error sending verification email:", error);
  } finally {
    isResending.value = false;
    store.dispatch("toggleLoader", false);
  }
}

async function reloadPage() {
  sessionStorage.setItem("redirectToDashboard", "true");
  window.location.reload();
}
</script>

<style scoped>
.verify-email-container {
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2rem;
  background-color: #f8f9fa;
}

.verify-email-content {
  max-width: 500px;
  width: 100%;
  padding: 3rem;
  background: white;
  border-radius: 12px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  text-align: center;
  animation: fadeIn 0.5s ease-out;
}

.icon-container {
  margin-bottom: 1.5rem;
}

.mail-icon {
  font-size: 4rem;
  animation: bounce 2s infinite;
}

.title {
  font-size: 2rem;
  font-weight: 600;
  color: #2c3e50;
  margin-bottom: 1rem;
}

.description {
  color: #6c757d;
  line-height: 1.6;
  margin-bottom: 2rem;
}

.actions {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.action-button {
  width: 100%;
  padding: 0.8rem 1.5rem;
  border: none;
  border-radius: 6px;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.2s ease;
}

.action-button:disabled {
  opacity: 0.7;
  cursor: not-allowed;
}

.primary {
  background-color: #007bff;
  color: white;
}

.primary:hover:not(:disabled) {
  background-color: #0056b3;
}

.secondary {
  background-color: #e9ecef;
  color: #495057;
}

.secondary:hover {
  background-color: #dee2e6;
}

.success-message {
  margin-top: 1rem;
  color: #28a745;
  animation: fadeIn 0.3s ease-out;
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes bounce {
  0%,
  20%,
  50%,
  80%,
  100% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(-20px);
  }
  60% {
    transform: translateY(-10px);
  }
}
</style>
