<template>
  <div
    id="app"
    :class="{
      'has-navbar': !showSidebar,
      'home-page': $route.name === 'Home',
    }"
  >
    <!-- Only show navbar when sidebar is not present -->
    <nav
      v-if="!showSidebar"
      class="navbar navbar-expand-lg fixed-top"
      :class="{
        'navbar-landing': $route.name === 'Home',
        'navbar-light bg-white shadow-sm': $route.name !== 'Home',
      }"
    >
      <div class="container">
        <router-link to="/" class="navbar-brand-container d-flex align-items-center">
          <img
            src="/media/jobbuddymascot.webp"
            alt="JobBuddy Logo"
            class="navbar-logo"
            width="32"
            height="32"
          />
          <h2 class="navbar-brand fw-bold mb-0">Job<span class="text-accent">Buddy</span></h2>
        </router-link>
        <button
          class="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarText"
        >
          <span class="navbar-toggler-icon" />
        </button>
        <div id="navbarText" class="collapse navbar-collapse">
          <ul class="navbar-nav ms-auto align-items-center">
            <li class="nav-item">
              <router-link to="/subscribe" class="nav-link">Pricing</router-link>
            </li>
            <li class="nav-item" v-if="!isAuthenticated" @click="login()">
              <router-link to="" class="nav-link">Login</router-link>
            </li>
            <li class="nav-item" v-else>
              <router-link to="/dashboard" class="nav-link">Dashboard</router-link>
            </li>
            <!-- Login button for desktop -->
            <li class="nav-item d-none d-md-block ms-3">
              <button
                v-if="!isAuthenticated"
                class="btn btn-light rounded-pill px-4"
                @click="signup()"
              >
                Sign up
                <i class="bi bi-arrow-right ps-2"></i>
              </button>
              <div v-else class="dropdown">
                <button
                  type="button"
                  class="btn btn-light dropdown-toggle px-4 rounded-pill"
                  data-bs-toggle="dropdown"
                >
                  <img
                    v-if="isAuthenticated"
                    width="25"
                    class="rounded-circle me-2"
                    :src="user.picture"
                  />
                  {{ user.name }}
                </button>
                <ul class="dropdown-menu dropdown-menu-end">
                  <li>
                    <router-link :to="{ name: 'Dashboard' }" class="dropdown-item">
                      Dashboard
                    </router-link>
                  </li>
                  <li>
                    <router-link :to="{ name: 'Profile' }" class="dropdown-item">
                      Profile
                    </router-link>
                  </li>
                  <li><hr class="dropdown-divider" /></li>
                  <li>
                    <a href="#" class="dropdown-item" @click="logoutUser">Logout</a>
                  </li>
                </ul>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </nav>
    <body class="bg-transparent min-vh-100">
      <AuthenticatedLayout>
        <router-view />
      </AuthenticatedLayout>
    </body>
    <!-- Only show footer when sidebar is not present -->
    <footer v-if="!showSidebar" class="footer py-5">
      <div class="container">
        <div class="row g-4">
          <div class="col-lg-4 col-md-6">
            <div class="footer-brand mb-4">
              <h2 class="mb-0 fw-bold">Job<span class="text-primary">Buddy</span></h2>
              <p class="text-muted mt-2 mb-0">AI tools to get you hired faster</p>
            </div>
            <div class="social-links mt-3">
              <a href="https://twitter.com/jobbuddytech" target="_blank" class="me-3">
                <i class="bi bi-twitter-x"></i>
              </a>
              <a href="https://www.linkedin.com/company/104235434" target="_blank" class="me-3">
                <i class="bi bi-linkedin"></i>
              </a>
              <a href="https://www.producthunt.com/@jobbuddy" target="_blank">
                <i class="bi bi-product-hunt"></i>
              </a>
            </div>
          </div>

          <div class="col-lg-3 col-md-6 offset-lg-1">
            <h6 class="fw-bold mb-3">Resources</h6>
            <ul class="list-unstyled">
              <li class="mb-2">
                <router-link :to="{ name: 'Courses' }">Free Courses Guide 2025</router-link>
              </li>
              <li class="mb-2">
                <router-link :to="{ name: 'ResumeExamples' }">Resume Examples</router-link>
              </li>
              <li class="mb-2">
                <router-link :to="{ name: 'CoverLetterExamples' }"
                  >Cover Letter Examples</router-link
                >
              </li>
              <li class="mb-2">
                <router-link :to="{ name: 'ChatGPTBlog' }">JobBuddy vs ChatGPT</router-link>
              </li>
            </ul>
          </div>

          <div class="col-lg-2 col-md-6">
            <h6 class="fw-bold mb-3">Company</h6>
            <ul class="list-unstyled">
              <li class="mb-2">
                <router-link :to="{ name: 'TermsOfService' }">Terms of Service</router-link>
              </li>
              <li class="mb-2">
                <router-link :to="{ name: 'PrivacyPolicy' }">Privacy Policy</router-link>
              </li>
              <li class="mb-2">
                <router-link :to="{ name: 'Subscribe' }">Pricing</router-link>
              </li>
            </ul>
          </div>

          <div class="col-lg-2 col-md-6">
            <h6 class="fw-bold mb-3">More</h6>
            <ul class="list-unstyled">
              <li class="mb-2">
                <a href="mailto:hello@jobbuddytech.com">Contact Us</a>
              </li>
              <li class="mb-2">
                <a href="https://jobbuddytech.getrewardful.com/" target="_blank">Affiliates</a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </footer>
  </div>
</template>

<script lang="ts">
import { useAuth0 } from "@auth0/auth0-vue";
import { ref, onMounted, computed, watch } from "vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import AuthenticatedLayout from "@/layouts/AuthenticatedLayout.vue";
import { State } from "@/store";
import "vue3-toastify/dist/index.css";
import { initPostHog, identifyUser } from "@/config/posthog";
import posthog from "posthog-js";

export default {
  name: "App",
  components: {
    AuthenticatedLayout,
  },
  setup() {
    const store = useStore<State>();
    const router = useRouter();
    const { loginWithRedirect, logout, user, isAuthenticated } = useAuth0();

    // Define which routes should show the sidebar (and hide the navbar)
    const sidebarRoutes = [
      "Dashboard",
      "MyJobs",
      "TaskBoard",
      "Profile",
      "CreateApplication",
      "Application",
      "EditResume",
      "UploadResume",
      "SetUpProfile",
      "Payment",
      "InterviewPrep",
      "EmailGenerator",
      "MatchAnalysis",
      "Stats",
      "Feedback",
      "VerifyEmail",
      "PaymentSuccess",
      "InterviewPractice",
      "InterviewAnalysis",
      "Resumes",
      "AIResumeEditorWithResume",
      "AIResumeEditorWithoutResume",
      "NewAIResume",
      "CoverLetters",
      "CoverLetterGenerator",
      "InterviewPracticeSelector",
    ];

    // Compute whether to show the sidebar
    const showSidebar = computed(() => {
      return sidebarRoutes.includes(router.currentRoute.value.name as string);
    });

    const login = () => {
      loginWithRedirect({ appState: { target: "/dashboard" } });
    };

    const signup = () => {
      loginWithRedirect({
        screen: "signup",
        screen_hint: "signup",
        appState: { target: "/dashboard" },
      });
    };

    const logoutUser = () => {
      logout({ returnTo: window.location.origin });
    };

    onMounted(() => {
      const htmlElement = document.documentElement;
      if (htmlElement) {
        htmlElement.dataset.bsTheme = "light";
      }

      // Initialize PostHog
      initPostHog();

      // Capture initial page view
      posthog.capture("$pageview");
    });

    // Watch for authentication state changes
    watch(
      () => isAuthenticated.value,
      (newValue) => {
        if (newValue && user.value) {
          identifyUser(user.value.sub as string, user.value.email, user.value.name);
        }
      },
      { immediate: true }
    );

    // Watch for route changes to track page views
    watch(
      () => router.currentRoute.value,
      (to) => {
        posthog.capture("$pageview", {
          $current_url: window.location.href,
          path: to.path,
          route_name: to.name,
        });
      },
      { immediate: true }
    );

    return {
      login,
      signup,
      logoutUser,
      user,
      isAuthenticated,
      showSidebar,
    };
  },
};
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  position: relative;
}

/* Add padding when navbar is present, except on homepage */
#app.has-navbar:not(.home-page) {
  padding-top: 80px;
}

.navbar {
  transition: all 0.3s ease;
  padding: 1rem 0;
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
  height: 64px; /* Fixed height for consistency */
}

.navbar-landing {
  background-color: rgba(13, 110, 253, 0.15);
}

.navbar-landing .nav-link,
.navbar-landing .navbar-brand {
  color: white !important;
  text-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
  padding-bottom: 1px;
}

.navbar-landing .nav-link:hover {
  color: rgba(255, 255, 255, 0.9) !important;
}

.navbar .nav-link {
  font-weight: 500;
  padding: 0.5rem 1rem;
  transition: color 0.2s ease;
}

.navbar-brand-container {
  text-decoration: none;
  color: inherit;
  display: flex;
  align-items: center;
  gap: 0.25rem;
  height: 40px;
}

.navbar-brand {
  font-size: 1.5rem;
  margin: 0;
  padding: 0;
  display: flex;
  align-items: center;
  line-height: 1;
  height: 100%;
  color: #212529 !important;
}

.navbar-logo {
  width: 32px;
  height: 32px;
  object-fit: contain;
  display: block;
  margin-bottom: 4px;
  margin-right: 0 !important;
}

.text-accent {
  color: #0d6efd;
}

/* Override for landing page */
.navbar-landing .navbar-brand,
.navbar-landing .text-accent {
  color: #fff !important;
  text-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
}

.btn-light {
  background: rgba(255, 255, 255, 0.2);
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
  border: 1px solid rgba(255, 255, 255, 0.1);
  color: white;
  font-weight: 500;
  text-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
}

.btn-light:hover {
  background: rgba(255, 255, 255, 0.3);
  color: white;
  transform: translateY(-1px);
}

.dropdown-menu {
  border: none;
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
}

.footer {
  background: #f8f9fa;
  border-top: 1px solid #e9ecef;
}

.footer a {
  text-decoration: none;
  color: #6c757d;
  transition: color 0.2s ease;
}

.footer a:hover {
  color: #0d6efd;
  text-decoration: none;
}

.footer h6 {
  color: #212529;
  margin-bottom: 1rem;
}

.footer-brand h2 {
  font-size: 1.75rem;
}

.social-links a {
  color: #6c757d;
  font-size: 1.25rem;
  transition: all 0.2s ease;
}

.social-links a:hover {
  color: #0d6efd;
}

.btn-light {
  background: white;
  border: 1px solid #e9ecef;
  color: #6c757d;
  transition: all 0.2s ease;
}

.btn-light:hover {
  background: #f8f9fa;
  color: #0d6efd;
  border-color: #0d6efd;
  transform: translateY(-1px);
}

@media (max-width: 768px) {
  .footer {
    text-align: center;
  }

  .social-links {
    justify-content: center;
    margin-bottom: 2rem;
  }

  .footer h6 {
    margin-top: 1.5rem;
  }

  .footer .btn-light {
    width: 100%;
    margin-bottom: 0.5rem;
  }
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active in <2.1.8 */ {
  opacity: 0;
}
.help-bar {
  cursor: pointer;
}
</style>
