export const loginProtectedRoutes = [
  "Dashboard",
  "Profile",
  "CreateApplication",
  "Payment",
  "VerifyEmail",
  "ApplicationView",
  "EditResume",
  "VoiceAssistant",
];
