export function useSectionFormatting(resume: any) {
  // Function to format the section name from the location string
  function formatSectionName(location: string): string {
    if (!location) return "General";

    // Extract the first part of the location path (before any dots or array indices)
    let section = location.split(".")[0];

    // For work experience, try to get the company name
    if (section.startsWith("workExperience")) {
      const workIndex = section.match(/\[(\d+)\]/)?.[1];
      if (workIndex !== undefined && resume?.workExperience?.[workIndex]) {
        const company = resume.workExperience[workIndex].company;
        return `Work Experience (${company})`;
      }
    }

    // Remove array indices if present
    section = section.replace(/\[\d+\]/g, "");

    // Format the section name for display
    switch (section.toLowerCase()) {
      case "workexperience":
        return "Work Experience";
      case "education":
        return "Education";
      case "skills":
        return "Skills";
      case "summary":
        return "Summary";
      case "projects":
        return "Projects";
      case "achievements":
        return "Achievements";
      case "certifications":
        return "Certifications";
      case "languages":
        return "Languages";
      case "contact":
        return "Contact";
      case "personaldetails":
        return "Personal Details";
      default:
        // Capitalize the first letter and add spaces before capital letters
        return section
          .replace(/([A-Z])/g, " $1")
          .replace(/^./, (str) => str.toUpperCase())
          .trim();
    }
  }

  // Function to get the CSS class for the section badge
  function getSectionClass(location: string): string {
    if (!location) return "badge-general";

    const section = location
      .split(".")[0]
      .replace(/\[\d+\]/g, "")
      .toLowerCase();

    // Check if this is a skill group suggestion
    if (section === "additionalskills" || section === "skills") {
      return "badge-skills";
    }

    switch (section) {
      case "workexperience":
        return "badge-experience";
      case "education":
        return "badge-education";
      case "skills":
        return "badge-skills";
      case "summary":
        return "badge-summary";
      case "projects":
        return "badge-projects";
      case "achievements":
        return "badge-achievements";
      default:
        return "badge-general";
    }
  }

  return {
    formatSectionName,
    getSectionClass
  };
} 