<template>
  <div class="resumes-view">
    <!-- New Resume Modal -->
    <CreateResumeModal ref="createResumeModal" />

    <!-- Download Modal -->
    <DownloadModal ref="downloadModal" :resume-id="selectedResumeId" />

    <!-- Main Content -->
    <div class="content-header">
      <div class="view-controls">
        <h2>My Resumes</h2>
        <div class="view-toggles">
          <button
            class="view-toggle"
            :class="{ active: viewMode === 'grid' }"
            @click="viewMode = 'grid'"
          >
            <i class="bi bi-grid"></i>
          </button>
          <button
            class="view-toggle"
            :class="{ active: viewMode === 'list' }"
            @click="viewMode = 'list'"
          >
            <i class="bi bi-list"></i>
          </button>
        </div>
      </div>
      <div class="sort-controls">
        <select v-model="sortBy" class="sort-select">
          <option value="created">Created Date</option>
          <option value="modified">Last Modified</option>
          <option value="name">Name</option>
        </select>
      </div>
    </div>

    <!-- Resumes Grid/List -->
    <div :class="['resumes-container', viewMode]">
      <!-- Create New Resume Card -->
      <div class="resume-card new-resume" @click="createNewResume">
        <div class="card-content">
          <i class="bi bi-plus-circle"></i>
          <h3>Create new resume</h3>
        </div>
      </div>

      <!-- Loading State - Skeleton Cards -->
      <template v-if="loading">
        <div v-for="i in 5" :key="`skeleton-${i}`" class="resume-card skeleton-card">
          <div class="skeleton-image"></div>
          <div class="card-info">
            <div class="skeleton-title"></div>
            <div class="skeleton-date"></div>
            <div class="skeleton-actions">
              <div class="skeleton-action"></div>
              <div class="skeleton-action"></div>
              <div class="skeleton-action"></div>
            </div>
          </div>
        </div>
      </template>

      <!-- No Resumes State -->
      <div v-else-if="resumes.length === 0" class="empty-state">
        <i class="bi bi-file-earmark-text"></i>
        <h3>No resumes yet</h3>
        <p>Create your first resume to get started</p>
      </div>

      <!-- Existing Resumes -->
      <template v-else>
        <div
          v-for="resume in sortedResumes"
          :key="resume.id"
          class="resume-card"
          @click="openResume(resume.id)"
        >
          <div class="preview-image">
            <img
              :src="getPreviewImage(resume)"
              :alt="resume.name"
              :class="{
                'template-blur':
                  !resume.previewUrl ||
                  resume.previewUrl === '/path/to/preview1.jpg' ||
                  resume.previewUrl === '/path/to/preview2.jpg',
              }"
            />
          </div>
          <div class="card-info">
            <h3>{{ resume.name }}</h3>
            <p class="last-modified">Last modified {{ formatDate(resume.lastModified) }}</p>
            <div class="card-actions">
              <button class="btn-action" @click.stop="duplicateResume(resume.id)">
                <i class="bi bi-files"></i>
              </button>
              <button class="btn-action" @click.stop="downloadResume(resume.id)">
                <i class="bi bi-download"></i>
              </button>
              <button class="btn-action delete" @click.stop="deleteResume(resume.id)">
                <i class="bi bi-trash"></i>
              </button>
            </div>
          </div>
        </div>
      </template>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref, computed, onMounted } from "vue";
import { useRouter } from "vue-router";
import { format } from "date-fns";
import { ResumeTemplates } from "../data/Templates";
import { DocumentApiService } from "../services/DocumentApiService";
import type { ResumeListItem } from "../../../server/src/models/dtos/ResumesList";
import { toast } from "vue3-toastify";
import "vue3-toastify/dist/index.css";
import CreateResumeModal from "../components/resume-editor/CreateResumeModal.vue";
import DownloadModal from "../components/DownloadModal.vue";

const router = useRouter();
const viewMode = ref("grid");
const sortBy = ref("modified");
const loading = ref(true);
const resumes = ref<ResumeListItem[]>([]);
const createResumeModal = ref<InstanceType<typeof CreateResumeModal> | null>(null);
const downloadModal = ref<InstanceType<typeof DownloadModal> | null>(null);
const selectedResumeId = ref<string | null>(null);

// Fetch resumes on component mount
onMounted(async () => {
  await fetchResumes();
});

// Function to fetch resumes
async function fetchResumes() {
  loading.value = true;
  try {
    const response = await DocumentApiService.getUserResumes();
    resumes.value = response.data.map((resume) => ({
      ...resume,
      id: resume.id || crypto.randomUUID(),
      name: resume.name || "Untitled Resume",
      lastModified: resume.lastModified ? new Date(resume.lastModified) : new Date(),
      previewUrl: resume.previewUrl || null,
    }));
  } catch (error) {
    console.error("Error fetching resumes:", error);
    toast.error("Failed to load resumes. Please try again.", {
      position: toast.POSITION.TOP_RIGHT,
      autoClose: 3000,
    });
  } finally {
    loading.value = false;
  }
}

// Add computed property for getting preview image with fallback
const getPreviewImage = (resume: ResumeListItem) => {
  if (resume.previewUrl) {
    return resume.previewUrl;
  }
  // Since we're using the default template, return the first template's preview image
  return ResumeTemplates[0].previewImage;
};

const sortedResumes = computed(() => {
  return [...resumes.value].sort((a, b) => {
    let dateA: Date, dateB: Date;

    switch (sortBy.value) {
      case "created":
        dateA = new Date(a.id);
        dateB = new Date(b.id);
        return dateB.getTime() - dateA.getTime();
      case "modified":
        dateA = a.lastModified;
        dateB = b.lastModified;
        return dateB.getTime() - dateA.getTime();
      case "name":
        return a.name.localeCompare(b.name);
      default:
        return 0;
    }
  });
});

function formatDate(date: Date) {
  return format(date, "MMM d, yyyy");
}

function createNewResume() {
  createResumeModal.value?.show();
}

function showImportModal() {
  router.push({
    name: "UploadResume",
    query: { redirect: "resumes" },
  });
}

async function openResume(id: string) {
  if (id === "new") {
    await router.push({
      name: "NewResume",
    });
  } else if (id != null) {
    router.push({
      name: "EditResume",
      params: { id },
    });
  }
}

function downloadResume(id: string) {
  selectedResumeId.value = id;
  downloadModal.value?.show();
}

async function deleteResume(id: string) {
  // Implement resume deletion logic
  console.log("Delete resume:", id);
  try {
    const response = await DocumentApiService.deleteResume({ id });
    if (response.status === 200) {
      toast.success("Resume deleted successfully");
      await fetchResumes();
    } else {
      toast.error("Failed to delete resume");
    }
  } catch (error) {
    console.error("Error deleting resume:", error);
    toast.error("Failed to delete resume. Please try again.");
  }
}

async function duplicateResume(id: string) {
  try {
    const response = await DocumentApiService.duplicateResume({ id });
    if (response.status === 200) {
      toast.success("Resume duplicated successfully");
      await fetchResumes();
    } else {
      toast.error("Failed to duplicate resume");
    }
  } catch (error) {
    console.error("Error duplicating resume:", error);
    toast.error("Failed to duplicate resume. Please try again.");
  }
}
</script>

<style scoped>
.resumes-view {
  padding: 2rem;
  max-width: 1400px;
  margin: 0 auto;
}

.import-banner {
  background: linear-gradient(to right, #0c6efd, #0d6efd);
  border-radius: 8px;
  padding: 0.75rem 1rem;
  margin-bottom: 1.5rem;
  color: white;
}

.banner-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.banner-content div {
  display: flex;
  align-items: center;
  gap: 0.75rem;
}

.banner-content h3 {
  font-size: 1rem;
  margin: 0;
}

.banner-content p {
  margin: 0;
  opacity: 0.9;
  font-size: 0.875rem;
}

.btn-import {
  background: rgba(255, 255, 255, 0.2);
  border: none;
  color: white;
  padding: 0.5rem 1rem;
  border-radius: 6px;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  transition: all 0.2s ease;
  font-size: 0.875rem;
}

.btn-import:hover {
  background: rgba(255, 255, 255, 0.3);
  transform: translateY(-1px);
}

.content-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 2rem;
}

.view-controls {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.view-controls h2 {
  margin: 0;
  font-size: 1.5rem;
  font-weight: 600;
}

.view-toggles {
  display: flex;
  gap: 0.5rem;
  background: #f3f4f6;
  padding: 0.25rem;
  border-radius: 8px;
}

.view-toggle {
  padding: 0.5rem;
  border: none;
  background: transparent;
  border-radius: 6px;
  color: #6b7280;
  transition: all 0.2s ease;
}

.view-toggle:hover {
  color: #111827;
}

.view-toggle.active {
  background: white;
  color: #111827;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
}

.sort-select {
  padding: 0.5rem 1rem;
  border: 1px solid #e5e7eb;
  border-radius: 8px;
  background: white;
  color: #374151;
  font-size: 0.875rem;
}

.resumes-container {
  display: grid;
  gap: 0.75rem;
  min-height: 300px;
}

.resumes-container.grid {
  grid-template-columns: repeat(auto-fill, minmax(160px, 1fr));
}

.resumes-container.list {
  grid-template-columns: 1fr;
}

.resume-card {
  background: white;
  border: 1px solid #e5e7eb;
  border-radius: 6px;
  overflow: hidden;
  transition: all 0.2s ease;
  cursor: pointer;
}

.resume-card:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1);
  border-color: #0c6efd;
}

.new-resume {
  padding: 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.new-resume i {
  font-size: 1.25rem;
  margin-bottom: 0.5rem;
}

.new-resume h3 {
  font-size: 0.875rem;
  margin-bottom: 0.125rem;
  text-align: center;
}

.new-resume p {
  font-size: 0.75rem;
  text-align: center;
}

.preview-image {
  width: 100%;
  position: relative;
  padding-top: 141.42%;
  background: #f3f4f6;
  overflow: hidden;
}

.preview-image img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.card-info {
  padding: 0.5rem;
}

.card-info h3 {
  font-size: 0.875rem;
  margin-bottom: 0.125rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.last-modified {
  font-size: 0.6875rem;
  margin-bottom: 0.5rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.card-actions {
  display: flex;
  gap: 0.25rem;
}

.btn-action {
  padding: 0.25rem;
  font-size: 0.75rem;
  border: none;
  background: transparent;
  color: #6b7280;
  border-radius: 4px;
  transition: all 0.2s ease;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
}

.btn-action:hover {
  background: #f3f4f6;
  color: #0c6efd;
}

.btn-action.delete:hover {
  background: #fee2e2;
  color: #dc2626;
}

.btn-action i {
  font-size: 1rem;
}

/* List view adjustments */
.resumes-container.list .card-actions {
  margin-left: auto;
}

.resumes-container.list .btn-action {
  opacity: 0;
}

.resumes-container.list .resume-card:hover .btn-action {
  opacity: 1;
}

/* List view specific styles */
.resumes-container.list .resume-card {
  display: flex;
  align-items: center;
  padding: 0.5rem 0.75rem;
  gap: 0;
}

.resumes-container.list .preview-image {
  display: none;
}

.resumes-container.list .card-info {
  padding: 0;
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 1rem;
}

.resumes-container.list .new-resume {
  padding: 0.5rem 0.75rem;
  flex-direction: row;
  justify-content: center;
  text-align: center;
}

.resumes-container.list .new-resume i {
  margin: 0;
  margin-right: 0.5rem;
}

.resumes-container.list .new-resume h3,
.resumes-container.list .new-resume p {
  margin: 0;
}

.resumes-container.list .card-info h3 {
  margin: 0;
}

.resumes-container.list .last-modified {
  margin: 0;
  flex-shrink: 0;
}

.template-blur {
  filter: blur(1px);
  opacity: 0.9;
  transform: scale(1.02);
  object-fit: contain !important;
  padding: 4px;
}

/* Skeleton loading animation */
@keyframes shimmer {
  0% {
    background-position: -200% 0;
  }
  100% {
    background-position: 200% 0;
  }
}

.skeleton-card {
  cursor: default;
}

.skeleton-card:hover {
  transform: none;
  box-shadow: none;
  border-color: #e5e7eb;
}

.skeleton-image {
  width: 100%;
  position: relative;
  padding-top: 141.42%; /* A4 aspect ratio */
  background: linear-gradient(90deg, #f0f0f0 25%, #e0e0e0 50%, #f0f0f0 75%);
  background-size: 200% 100%;
  animation: shimmer 1.5s infinite;
}

.skeleton-title {
  height: 14px;
  width: 80%;
  margin-bottom: 8px;
  border-radius: 4px;
  background: linear-gradient(90deg, #f0f0f0 25%, #e0e0e0 50%, #f0f0f0 75%);
  background-size: 200% 100%;
  animation: shimmer 1.5s infinite;
}

.skeleton-date {
  height: 10px;
  width: 60%;
  margin-bottom: 10px;
  border-radius: 4px;
  background: linear-gradient(90deg, #f0f0f0 25%, #e0e0e0 50%, #f0f0f0 75%);
  background-size: 200% 100%;
  animation: shimmer 1.5s infinite;
}

.skeleton-actions {
  display: flex;
  gap: 4px;
}

.skeleton-action {
  height: 20px;
  width: 20px;
  border-radius: 4px;
  background: linear-gradient(90deg, #f0f0f0 25%, #e0e0e0 50%, #f0f0f0 75%);
  background-size: 200% 100%;
  animation: shimmer 1.5s infinite;
}

/* List view adjustments for skeleton cards */
.resumes-container.list .skeleton-image {
  display: none;
}

.resumes-container.list .skeleton-card {
  display: flex;
  align-items: center;
  padding: 0.5rem 0.75rem;
}

.resumes-container.list .skeleton-title {
  width: 200px;
  margin-bottom: 0;
  margin-right: 20px;
}

.resumes-container.list .skeleton-date {
  width: 100px;
  margin-bottom: 0;
  margin-right: auto;
}

.resumes-container.list .skeleton-actions {
  margin-left: auto;
}

.empty-state {
  grid-column: 1 / -1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 2rem;
  background: white;
  border: 1px solid #e5e7eb;
  border-radius: 8px;
  color: #6b7280;
  min-height: 200px;
  margin-top: 1rem;
}

.empty-state i {
  font-size: 2rem;
  margin-bottom: 1rem;
}

.empty-state p {
  margin: 0;
  font-size: 0.875rem;
}

.empty-state h3 {
  margin: 0 0 0.25rem 0;
  font-size: 1rem;
  color: #374151;
}

@media (max-width: 768px) {
  .resumes-view {
    padding: 1rem;
  }

  .banner-content {
    flex-direction: row;
    gap: 1rem;
  }

  .banner-content div {
    flex-direction: column;
    align-items: flex-start;
    gap: 0.25rem;
  }

  .content-header {
    flex-direction: column;
    gap: 1rem;
    align-items: flex-start;
  }

  .resumes-container.list .resume-card {
    padding: 0.75rem;
  }

  .resumes-container.list .card-info {
    flex-direction: column;
    align-items: flex-start;
    gap: 0.25rem;
  }

  .resumes-container.list .last-modified {
    font-size: 0.6875rem;
  }
}
</style>
