<template>
  <div class="resume-dropdown">
    <div class="custom-select">
      <select class="form-select" :value="modelValue" @change="handleChange">
        <option value="" disabled selected>Select a resume to inform your cover letter</option>
        <option v-for="resume in resumes" :key="resume.id" :value="resume.id">
          {{ resume.name }}
        </option>
      </select>
      <div class="select-icon">
        <i class="bi bi-chevron-down"></i>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import type { ResumeListItem } from "../../../../server/src/models/dtos/ResumesList";

defineProps<{
  modelValue: string | null;
  resumes: ResumeListItem[];
}>();

const emit = defineEmits<{
  (e: "update:modelValue", value: string): void;
}>();

function handleChange(event: Event) {
  const target = event.target as HTMLSelectElement;
  emit("update:modelValue", target.value);
}
</script>

<script lang="ts">
export default {
  name: "ResumeDropdown",
};
</script>

<style scoped>
.resume-dropdown {
  width: 100%;
}

.custom-select {
  position: relative;
  width: 100%;
}

.select-icon {
  position: absolute;
  right: 12px;
  top: 50%;
  transform: translateY(-50%);
  pointer-events: none;
  color: #6c757d;
}

.form-select {
  width: 100%;
  padding: 0.75rem 2.25rem 0.75rem 1rem;
  border: 1px solid #e5e7eb;
  border-radius: 10px;
  background-color: white;
  font-size: 0.95rem;
  color: #374151;
  cursor: pointer;
  transition: all 0.2s ease;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  background-image: none;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.05);
  text-overflow: ellipsis;
}

.form-select:hover {
  border-color: #0d6efd;
  box-shadow: 0 2px 5px rgba(13, 110, 253, 0.15);
}

.form-select:focus {
  border-color: #0d6efd;
  outline: none;
  box-shadow: 0 0 0 3px rgba(13, 110, 253, 0.15);
}

.form-select option {
  padding: 10px;
  white-space: normal;
  word-wrap: break-word;
}

@media (max-width: 768px) {
  .form-select {
    padding: 0.6rem 2rem 0.6rem 0.75rem;
    font-size: 0.9rem;
  }

  .select-icon {
    right: 10px;
  }
}
</style>
