<template>
  <div class="master-detail-layout">
    <!-- List Panel (Left) -->
    <div class="list-panel">
      <div class="section-header">
        <div>
          <h2>Work Experience</h2>
          <p>Add your professional experience</p>
        </div>
      </div>
      <VueDraggableNext
        :model-value="workExperience"
        @update:model-value="updateWorkExperience"
        group="work-experience"
        handle=".drag-handle"
        tag="div"
        class="item-list"
      >
        <div
          v-for="(work, index) in workExperience"
          :key="index"
          class="list-item"
          :class="{ active: selectedIndex === index }"
          @click="selectedIndex = index"
        >
          <div class="drag-handle">
            <i class="bi bi-grip-vertical"></i>
          </div>
          <div class="list-item-content">
            <div class="list-item-title">{{ work.role || "New Position" }}</div>
            <div class="list-item-subtitle">{{ work.company || "Company Name" }}</div>
          </div>
          <button class="btn-remove" @click.stop="removeWorkExperience(index)">
            <i class="bi bi-trash"></i>
          </button>
        </div>
      </VueDraggableNext>
      <button class="btn-add-position" @click="addWorkExperience">
        <i class="bi bi-plus-lg"></i>
        Add Position
      </button>
    </div>

    <!-- Detail Panel (Right) -->
    <div class="detail-panel" v-if="selectedWork">
      <div class="form-grid">
        <div class="form-group col-span-2">
          <label>Job Title / Role</label>
          <input
            :value="selectedWork.role"
            type="text"
            placeholder="e.g., Senior Software Engineer"
            class="form-control"
            @input="updateField('role', ($event.target as HTMLInputElement).value)"
          />
        </div>
        <div class="form-group">
          <label>Company Name</label>
          <input
            :value="selectedWork.company"
            type="text"
            placeholder="e.g., Google"
            class="form-control"
            @input="updateField('company', ($event.target as HTMLInputElement).value)"
          />
        </div>
        <div class="form-group">
          <label>Company Location</label>
          <input
            :value="selectedWork.location"
            type="text"
            placeholder="e.g., Mountain View, CA"
            class="form-control"
            @input="updateField('location', ($event.target as HTMLInputElement).value)"
          />
        </div>
        <div class="form-group col-span-2">
          <label>Employment Period</label>
          <input
            :value="selectedWork.dates"
            type="text"
            placeholder="e.g., Jan 2020 - Present"
            class="form-control"
            @input="updateField('dates', ($event.target as HTMLInputElement).value)"
          />
        </div>
        <div class="form-group col-span-2">
          <label>Key Achievements & Responsibilities</label>
          <div class="achievements-list">
            <VueDraggableNext
              v-model="selectedWork.achievements"
              group="achievements"
              handle=".drag-handle"
              tag="div"
              @change="emitUpdate"
            >
              <div
                v-for="(achievement, aIndex) in selectedWork.achievements"
                :key="achievement.uuid"
                class="achievement-item"
              >
                <div class="drag-handle">
                  <i class="bi bi-grip-vertical"></i>
                </div>
                <span class="bullet-point">•</span>
                <textarea
                  v-if="selectedWork && selectedWork.achievements"
                  :value="achievement.achievement"
                  v-auto-resize
                  class="form-control"
                  placeholder="e.g., Led a team of 5 developers to deliver a key feature that increased user engagement by 25%"
                  rows="1"
                  @input="updateAchievement(aIndex, ($event.target as HTMLTextAreaElement).value)"
                ></textarea>
                <button class="btn-remove-small" @click="removeAchievement(aIndex)">
                  <i class="bi bi-trash"></i>
                </button>
              </div>
            </VueDraggableNext>
            <button class="btn-add-achievement" @click="addAchievement">
              <i class="bi bi-plus-lg"></i>
              Add Achievement
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref, computed, watch } from "vue";
import { VueDraggableNext } from "vue-draggable-next";
import type { WorkExperience } from "../../../../server/src/models/Resume";

const props = defineProps<{
  modelValue: WorkExperience[];
}>();

const emit = defineEmits<{
  (e: "update:modelValue", value: WorkExperience[]): void;
}>();

const workExperience = ref<WorkExperience[]>([...props.modelValue]);
const selectedIndex = ref(0);

const selectedWork = computed(() => {
  if (!workExperience.value.length) return null;
  return workExperience.value[selectedIndex.value];
});

watch(
  () => props.modelValue,
  (newValue) => {
    // Only update if different to prevent recursion
    if (JSON.stringify(newValue) !== JSON.stringify(workExperience.value)) {
      workExperience.value = [...newValue];
    }
  },
  { deep: true }
);

function updateWorkExperience(value: WorkExperience[]) {
  workExperience.value = value;
  emit("update:modelValue", value);
}

function updateField(field: keyof WorkExperience, value: string) {
  if (!selectedWork.value) return;

  const newWorkExperience = [...workExperience.value];
  newWorkExperience[selectedIndex.value] = {
    ...newWorkExperience[selectedIndex.value],
    [field]: value,
  };

  workExperience.value = newWorkExperience;
  emit("update:modelValue", newWorkExperience);
}

function updateAchievement(index: number, value: string) {
  if (!selectedWork.value || !selectedWork.value.achievements) return;

  const newWorkExperience = [...workExperience.value];
  const achievements = [...(newWorkExperience[selectedIndex.value].achievements || [])];

  achievements[index] = {
    ...achievements[index],
    achievement: value,
  };

  newWorkExperience[selectedIndex.value] = {
    ...newWorkExperience[selectedIndex.value],
    achievements,
  };

  workExperience.value = newWorkExperience;
  emit("update:modelValue", newWorkExperience);
}

function addWorkExperience() {
  const newWorkExperience = [...workExperience.value];
  newWorkExperience.unshift({
    company: "",
    location: "",
    role: "",
    dates: "",
    achievements: [
      {
        achievement: "",
        uuid: crypto.randomUUID(),
      },
    ],
    uuid: crypto.randomUUID(),
  });
  updateWorkExperience(newWorkExperience);
  selectedIndex.value = 0;
}

function removeWorkExperience(index: number) {
  const newWorkExperience = [...workExperience.value];
  newWorkExperience.splice(index, 1);
  if (selectedIndex.value >= newWorkExperience.length) {
    selectedIndex.value = Math.max(0, newWorkExperience.length - 1);
  }
  updateWorkExperience(newWorkExperience);
}

function addAchievement() {
  if (selectedWork.value) {
    const newWorkExperience = [...workExperience.value];
    const work = newWorkExperience[selectedIndex.value];
    if (!work.achievements) {
      work.achievements = [];
    }
    work.achievements.push({
      achievement: "",
      uuid: crypto.randomUUID(),
    });
    updateWorkExperience(newWorkExperience);
  }
}

function removeAchievement(index: number) {
  if (selectedWork.value?.achievements) {
    const newWorkExperience = [...workExperience.value];
    const work = newWorkExperience[selectedIndex.value];
    if (!work.achievements) return;
    work.achievements.splice(index, 1);
    updateWorkExperience(newWorkExperience);
  }
}

function emitUpdate() {
  emit("update:modelValue", [...workExperience.value]);
}

// Add v-auto-resize directive
const vAutoResize = {
  mounted: (el: HTMLTextAreaElement) => {
    const resize = () => {
      el.style.height = "auto";
      el.style.height = el.scrollHeight + "px";
    };
    resize();
    el.addEventListener("input", resize);
    el.addEventListener("change", resize);
  },
  updated: (el: HTMLTextAreaElement) => {
    el.style.height = "auto";
    el.style.height = el.scrollHeight + "px";
  },
};
</script>

<style scoped>
.master-detail-layout {
  display: grid;
  grid-template-columns: 300px 1fr;
  gap: 2rem;
  height: 100%;
  min-height: 400px;
  max-height: 100%;
  overflow: hidden;
}

.list-panel {
  border-right: 1px solid #e5e7eb;
  padding: 0 1rem 1rem 0;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  max-height: 100%;
}

.section-header {
  padding: 0;
  margin-bottom: 1rem;
  border-bottom: 1px solid #e5e7eb;
  padding-bottom: 1rem;
}

.section-header h2 {
  font-size: 1.25rem;
  margin-bottom: 0.25rem;
}

.section-header p {
  font-size: 0.875rem;
  color: #6b7280;
  margin: 0;
}

.detail-panel {
  padding: 0;
  border-radius: 12px;
  overflow-y: auto;
  max-height: 100%;
}

.form-grid {
  padding: 1rem;
  background: white;
  border-radius: 12px;
  margin: 0;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 1rem;
}

.form-group {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.form-group.col-span-2 {
  grid-column: span 2;
  width: 100%;
}

.item-list {
  flex: 1;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  min-height: 0;
  padding-top: 0.5rem;
}

.list-item {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  padding: 0.75rem;
  border: 1px solid #e5e7eb;
  border-radius: 8px;
  cursor: pointer;
  background: white;
  transition: all 0.2s ease;
}

.list-item:hover {
  border-color: #0c6efd;
  transform: translateY(-1px);
}

.list-item.active {
  border-color: #0c6efd;
  background: #f0f7ff;
}

.list-item-content {
  flex: 1;
  min-width: 0;
}

.list-item-title {
  font-weight: 500;
  color: #111827;
  margin-bottom: 0.25rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.list-item-subtitle {
  font-size: 0.875rem;
  color: #6b7280;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.drag-handle {
  cursor: grab;
  padding: 0.5rem;
  color: #6b7280;
  display: flex;
  align-items: center;
}

.drag-handle:active {
  cursor: grabbing;
}

.btn-remove {
  color: #ef4444;
  border: none;
  background: none;
  padding: 0;
  border-radius: 6px;
  opacity: 0;
  transition: all 0.2s ease;
}

.list-item:hover .btn-remove {
  opacity: 1;
}

.btn-add-position {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  background: #0c6efd;
  color: white;
  border: none;
  padding: 0.75rem;
  border-radius: 8px;
  font-size: 0.875rem;
  font-weight: 500;
  width: 100%;
  transition: all 0.2s ease;
  margin-top: auto;
}

.btn-add-position:hover {
  background: #0b5ed7;
  transform: translateY(-1px);
}

.achievements-list {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: 0.5rem;
  background: #f9fafb;
  border-radius: 8px;
  height: 100%;
}

.achievement-item {
  width: 100%;
  display: flex;
  gap: 1rem;
  margin-bottom: 0.5rem;
  align-items: flex-start;
  padding: 0.5rem;
  border-radius: 6px;
  background: white;
  transition: all 0.2s ease;
}

.achievement-item:hover {
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
}

.achievement-item .bullet-point {
  color: #6b7280;
  font-size: 1.5rem;
  line-height: 1;
}

.achievement-item .form-control {
  flex: 1;
  border: none;
  background: transparent;
  padding: 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  resize: none;
  min-height: 30px;
  overflow: hidden;
}

.achievement-item .form-control:focus {
  outline: none;
  background: white;
  box-shadow: none;
}

.btn-remove-small {
  opacity: 0;
  color: #6b7280;
  padding: 0;
  border: none;
  background: none;
  transition: all 0.2s ease;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1rem;
}

.achievement-item:hover .btn-remove-small {
  opacity: 1;
}

.btn-remove-small:hover {
  color: #ef4444;
}

.btn-add-achievement {
  margin-top: 0.5rem;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  color: #0c6efd;
  background: white;
  border: 1px dashed #e5e7eb;
  padding: 0.75rem;
  border-radius: 6px;
  width: 100%;
  justify-content: center;
  font-size: 0.875rem;
  transition: all 0.2s ease;
}

.btn-add-achievement:hover {
  border-color: #0c6efd;
  background: rgba(12, 110, 253, 0.1);
}

.form-control {
  padding: 0.75rem;
  border: 1px solid #e5e7eb;
  border-radius: 8px;
  font-size: 0.875rem;
  transition: all 0.2s ease;
  background: #f9fafb;
}

.form-control:focus {
  outline: none;
  border-color: #0c6efd;
  background: white;
  box-shadow: 0 0 0 3px rgba(12, 110, 253, 0.1);
}

label {
  font-size: 0.875rem;
  font-weight: 500;
  color: #374151;
  text-align: left;
  margin-bottom: 0.25rem;
}
</style>
