<template>
  <div class="section-tabs">
    <div class="left-tabs">
      <button
        v-for="section in sections.filter((s) => s.id !== 'preview')"
        :key="section.id"
        class="section-tab"
        :class="{
          active: modelValue === section.id,
          completed: isSectionComplete(section.id),
          'optimize-tab': section.id === 'optimize',
        }"
        @click="$emit('update:modelValue', section.id)"
      >
        <i :class="section.icon"></i>
        <span>{{ section.title }}</span>
        <i v-if="isSectionComplete(section.id)" class="bi bi-check-circle-fill check-icon"></i>
      </button>
    </div>
    <div class="right-tabs">
      <button
        v-for="section in sections.filter((s) => s.id === 'preview')"
        :key="section.id"
        class="preview-button"
        :class="{
          active: modelValue === section.id,
        }"
        @click="$emit('update:modelValue', section.id)"
      >
        <i :class="section.icon"></i>
        <span>{{ section.title }}</span>
      </button>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import type { Resume } from "../../../../server/src/models/Resume";

export default defineComponent({
  name: "ResumeNavigation",
  props: {
    modelValue: {
      type: String,
      required: true,
    },
    resume: {
      type: Object as () => Resume,
      required: true,
    },
  },
  emits: ["update:modelValue"],
  setup(props, { emit }) {
    const sections = [
      { id: "contact", title: "Contact", icon: "bi bi-person-vcard" },
      { id: "summary", title: "Summary", icon: "bi bi-file-text" },
      { id: "work", title: "Experience", icon: "bi bi-briefcase" },
      { id: "education", title: "Education", icon: "bi bi-mortarboard" },
      { id: "additional", title: "Extra", icon: "bi bi-plus-circle" },
      { id: "optimize", title: "ATS Optimize", icon: "bi bi-graph-up" },
      { id: "preview", title: "Preview Resume", icon: "bi bi-eye" },
    ];

    function isSectionComplete(sectionId: string): boolean {
      switch (sectionId) {
        case "contact":
          return !!(
            props.resume.contactInfo?.firstName &&
            props.resume.contactInfo?.surname &&
            props.resume.contactInfo?.email
          );
        case "summary":
          return !!props.resume.summary?.description;
        case "work":
          return !!(
            (props.resume.workExperience || []).length > 0 &&
            (props.resume.workExperience || []).every((w) => w.company && w.role)
          );
        case "education":
          return !!(
            (props.resume.education || []).length > 0 &&
            (props.resume.education || []).every((e) => e.instituteName && e.course)
          );
        default:
          return false;
      }
    }

    return {
      sections,
      isSectionComplete,
    };
  },
});
</script>

<style scoped>
.section-tabs {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 0.5rem;
  padding-top: 1rem;
  padding: 0.1rem;
  padding-top: 1rem;
  background: #f9fafb;
  border-radius: 8px;
  margin-bottom: 0.5rem;
  flex-wrap: nowrap;
  overflow-x: auto;
}

.left-tabs {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  flex-wrap: nowrap;
}

.right-tabs {
  margin-left: auto;
  margin-right: 1rem;
}

.section-tab {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  padding: 0.5rem 1rem;
  border: none;
  background: transparent;
  color: #6b7280;
  font-size: 0.875rem;
  font-weight: 500;
  border-radius: 6px;
  transition: all 0.2s ease;
  white-space: nowrap;
  flex-shrink: 0;
}

.section-tab:hover {
  background: #f3f4f6;
  color: #111827;
}

.section-tab.active {
  background: #0c6efd;
  color: white;
}

.section-tab.completed {
  color: #059669;
}

.section-tab.completed.active {
  color: white;
}

.section-tab.optimize-tab {
  background: #fef3c7;
  color: #8e920e;
  font-weight: 600;
}

.section-tab.optimize-tab:hover {
  background: #fde68a;
  color: #78760f;
}

.section-tab.optimize-tab.active {
  background: #fde68a;
  color: #78760f;
}

.preview-button {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  padding: 0.5rem 1rem;
  background: #0c6efd;
  color: white;
  font-size: 0.875rem;
  font-weight: 600;
  border-radius: 6px;
  border: none;
  transition: all 0.2s ease;
  white-space: nowrap;
  box-shadow: 0 2px 4px rgba(12, 110, 253, 0.2);
}

.preview-button:hover {
  background: #0b5ed7;
  transform: translateY(-1px);
  box-shadow: 0 3px 6px rgba(12, 110, 253, 0.3);
}

.preview-button.active {
  background: #0a58ca;
  box-shadow: 0 2px 4px rgba(12, 110, 253, 0.4);
}
</style>
