export enum EditType {
  Add = 'Add',
  Edit = 'Edit',
  Remove = 'Remove'
}

export enum AI_RESUME_COMMANDS {
  // Content modification commands
  ADD_SKILL = 'ADD_SKILL',
  EDIT_SKILL = 'EDIT_SKILL',
  REMOVE_SKILL = 'REMOVE_SKILL',

  // New simplified skill group commands
  ADD_SKILL_GROUP = 'ADD_SKILL_GROUP',
  EDIT_SKILL_GROUP = 'EDIT_SKILL_GROUP',
  DELETE_SKILL_GROUP = 'DELETE_SKILL_GROUP',

  ADD_WORK_ACHIEVEMENT = 'ADD_WORK_ACHIEVEMENT',
  EDIT_WORK_ACHIEVEMENT = 'EDIT_WORK_ACHIEVEMENT',
  REMOVE_WORK_ACHIEVEMENT = 'REMOVE_WORK_ACHIEVEMENT',

  ADD_EDUCATION = 'ADD_EDUCATION',
  EDIT_EDUCATION = 'EDIT_EDUCATION',
  REMOVE_EDUCATION = 'REMOVE_EDUCATION',

  ADD_PROJECT = 'ADD_PROJECT',
  EDIT_PROJECT = 'EDIT_PROJECT',
  REMOVE_PROJECT = 'REMOVE_PROJECT',

  ADD_CERTIFICATION = 'ADD_CERTIFICATION',
  EDIT_CERTIFICATION = 'EDIT_CERTIFICATION',
  REMOVE_CERTIFICATION = 'REMOVE_CERTIFICATION',

  ADD_SUMMARY = 'ADD_SUMMARY',
  EDIT_SUMMARY = 'EDIT_SUMMARY',

  // Structural commands
  REORDER_WORK_ACHIEVEMENTS = 'REORDER_WORK_ACHIEVEMENTS',
  REORDER_SKILLS = 'REORDER_SKILLS',
  REORDER_SECTIONS = 'REORDER_SECTIONS',
  REORDER_WORK_EXPERIENCE = 'REORDER_WORK_EXPERIENCE',
  REORDER_EDUCATION = 'REORDER_EDUCATION',

  // Formatting commands
  CHANGE_SECTION_TITLE = 'CHANGE_SECTION_TITLE',
  CHANGE_FORMATTING = 'CHANGE_FORMATTING'
}

// Metadata for content modification commands
export interface AddSkillMetadata {
  skillData: any // The skill to add
}

export interface EditSkillMetadata {
  skillData: any // The updated skill
  index?: number // Optional index if editing a specific skill in an array
}

export interface RemoveSkillMetadata {
  index?: number // Optional index if removing a specific skill from an array
}

// New simplified skill group metadata interfaces
export interface AddSkillGroupMetadata {
  skillGroup: {
    skillGroup: string
    skillDetails: string[]
  }
}

export interface EditSkillGroupMetadata {
  skillGroupName: string
  skillGroup: {
    skillGroup: string
    skillDetails: string[]
  }
}

export interface DeleteSkillGroupMetadata {
  skillGroup: string
}

export interface AddWorkAchievementMetadata {
  achievement: string // The achievement to add
}

export interface EditWorkAchievementMetadata {
  achievement: string // The updated achievement
  achievementLocation: string // Which achievement to edit
}

export interface RemoveWorkAchievementMetadata {
  achievementLocation: string
}

export interface AddProjectAchievementMetadata {
  achievement: string // The achievement to add
}

export interface EditProjectAchievementMetadata {
  achievement: string // The updated achievement
  achievementLocation: string // Which achievement to edit
}

export interface RemoveProjectAchievementMetadata {
  achievementLocation: string
}

export interface EditSummaryMetadata {
  summary: {
    title?: string
    description?: string
  }
}

export interface AddSummaryMetadata {
  summary: {
    title?: string
    description: string
  }
}

// Metadata for structural commands
export interface ReorderWorkAchievementsMetadata {
  workExperienceIndex: number // Which work experience to reorder
  reorderedIndexes: number[] // New order of achievements
}

export interface ReorderSkillsMetadata {
  reorderedIndexes: number[] // New order of skills
}

export interface ReorderSectionsMetadata {
  sectionOrder: string[] // New order of top-level sections
}

export interface ReorderWorkExperienceMetadata {
  reorderedIndexes: number[] // New order of work experiences
}

export interface ReorderEducationMetadata {
  reorderedIndexes: number[] // New order of education entries
}

// Metadata for formatting commands
export interface ChangeSectionTitleMetadata {
  section: string // Which section to change the title of
  newTitle: string // The new title
}

export interface ChangeFormattingMetadata {
  formattingChanges: Record<string, any>
}

// Union type for all possible metadata types
export type ResumeCommandMetadata =
  | AddSkillMetadata
  | EditSkillMetadata
  | RemoveSkillMetadata
  | AddWorkAchievementMetadata
  | EditWorkAchievementMetadata
  | RemoveWorkAchievementMetadata
  | AddProjectAchievementMetadata
  | EditProjectAchievementMetadata
  | RemoveProjectAchievementMetadata
  | EditSummaryMetadata
  | AddSummaryMetadata
  | ReorderWorkAchievementsMetadata
  | ReorderSkillsMetadata
  | ReorderSectionsMetadata
  | ReorderWorkExperienceMetadata
  | ReorderEducationMetadata
  | ChangeSectionTitleMetadata
  | ChangeFormattingMetadata
  | AddSkillGroupMetadata
  | EditSkillGroupMetadata
  | DeleteSkillGroupMetadata

export interface ResumeSuggestion {
  description: string
  type: EditType
  location: string
  change: string
  command: AI_RESUME_COMMANDS
  metadata?: ResumeCommandMetadata
}

export interface ResumeSuggestionsOutput {
  summary: string
  suggestions: ResumeSuggestion[]
}

