import { Resume } from '@models/Resume';
import { instance } from './apiProvider/ApiProvider';
import type { AxiosResponse } from 'axios';

export class ATSScoreService {

  public static async quickScoreResume (
    resumeId: string,
    applicationId: string
  ): Promise<AxiosResponse<any>> {
    try {
      const axios = await instance();
      return await axios.post('/quickScoreResume', { resumeId, applicationId });
        } catch (error: any) {
      console.error('Error scoring resume:', error.message);
      throw new Error('Failed to score resume');
    }
  }

  public static async scoreResume (
        resume: Resume,
        jobTitle: string,
        requiredExperience: string,
        requiredSkills: string[],
        keywords: Array<{ keyword: string, weight: number }>
      ): Promise<AxiosResponse<any>> {
    try {
      const axios = await instance();
      return await axios.post('/atsScoreResume', { 
        resume, 
        jobTitle, 
        requiredExperience, 
        requiredSkills, 
        keywords 
      });
    } catch (error: any) {
      console.error('Error scoring resume:', error.message);
      throw new Error('Failed to score resume');
    }
  }

  public static async getATSParametersForApplication (
    applicationId: string
  ): Promise<AxiosResponse<any>> {
    try {
      const axios = await instance();
      return await axios.post('/getATSParametersForApplication', { applicationId });
    } catch (error: any) {
      console.error('Error getting ATS parameters for application:', error.message);
      throw new Error('Failed to get ATS parameters for application');
    }
  }
}
