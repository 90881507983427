// PDF Highlighting Utility
// Handles all PDF text highlighting functionality

// Define types for text mapping
export interface TextMapping {
  start: number;
  end: number;
  node: Element;
}

export interface ParagraphPosition {
  startPosition: number;
  endPosition: number;
}

// Track highlight overlays for cleanup
const highlightOverlays: HTMLElement[] = [];

/**
 * Combine text from all text layers and map each node to its position
 */
export function combineTextAndMap(textLayers: NodeListOf<Element>) {
  let combinedText = "";
  const textMappings: TextMapping[] = [];
  
  // Track node positions by their bounding rectangles to identify adjacent nodes
  const nodePositions: {node: Element; rect: DOMRect}[] = [];

  // First collect all nodes and their positions
  textLayers.forEach((layer) => {
    Array.from(layer.childNodes).forEach((node) => {
      if (node.nodeType === Node.ELEMENT_NODE) {
        const elementNode = node as Element;
        try {
          const rect = elementNode.getBoundingClientRect();
          // Only include nodes that are visible and have text
          if (rect.width > 0 && rect.height > 0 && getNodeText(elementNode).trim().length > 0) {
            nodePositions.push({
              node: elementNode,
              rect
            });
          }
        } catch (error) {
          console.warn("Could not get bounding rect for node:", error);
        }
      }
    });
  });
  
  // Identify potential columns in the document
  const potentialColumns: {left: number, right: number}[] = [];
  const columnMargin = 20; // Margin to consider nodes in the same column
  
  // Group nodes by their horizontal position to detect columns
  nodePositions.forEach(({rect}) => {
    const midX = rect.left + (rect.width / 2);
    
    // Check if this node belongs to an existing column
    let foundColumn = false;
    for (const column of potentialColumns) {
      if (midX >= column.left - columnMargin && midX <= column.right + columnMargin) {
        // Update column boundaries
        column.left = Math.min(column.left, rect.left);
        column.right = Math.max(column.right, rect.right);
        foundColumn = true;
        break;
      }
    }
    
    // If no matching column, create a new one
    if (!foundColumn) {
      potentialColumns.push({
        left: rect.left,
        right: rect.right
      });
    }
  });
  
  // Sort columns from left to right
  potentialColumns.sort((a, b) => a.left - b.left);
  
  // Group nodes by row and column for proper reading order
  const rowThreshold = 5; // Nodes within this vertical distance are considered in the same row
  const rows: {y: number, nodes: {node: Element, rect: DOMRect, column: number}[]}[] = [];
  
  // Assign each node to a column and row
  nodePositions.forEach(({node, rect}) => {
    // Determine which column this node belongs to
    let columnIndex = 0;
    const midX = rect.left + (rect.width / 2);
    
    for (let i = 0; i < potentialColumns.length; i++) {
      const column = potentialColumns[i];
      if (midX >= column.left - columnMargin && midX <= column.right + columnMargin) {
        columnIndex = i;
        break;
      }
    }
    
    // Find or create a row for this node
    let rowFound = false;
    for (const row of rows) {
      if (Math.abs(rect.top - row.y) < rowThreshold) {
        row.nodes.push({node, rect, column: columnIndex});
        rowFound = true;
        break;
      }
    }
    
    if (!rowFound) {
      rows.push({
        y: rect.top,
        nodes: [{node, rect, column: columnIndex}]
      });
    }
  });
  
  // Sort rows by vertical position
  rows.sort((a, b) => a.y - b.y);
  
  // For each row, sort nodes by column
  rows.forEach(row => {
    row.nodes.sort((a, b) => a.column - b.column);
  });
  
  // Now process nodes in reading order (row by row, column by column)
  rows.forEach(row => {
    row.nodes.forEach(({node}) => {
      const nodeText = getNodeText(node).trim();
      if (nodeText.length === 0) return; // Skip empty nodes
      
      const start = combinedText.length;
      
      // Only add a space if we're not at the beginning
      if (combinedText.length > 0) {
        combinedText += " ";
      }
      
      combinedText += nodeText;
      textMappings.push({
        start,
        end: start + nodeText.length - 1 + (combinedText.length > 0 ? 1 : 0),
        node
      });
    });
  });

  // Normalize spaces in the combined text to ensure we don't have double spaces
  combinedText = combinedText.replace(/\s+/g, " ");

  console.log(`Combined text sample (${potentialColumns.length} columns detected):`, combinedText.substring(0, 100));

  return { combinedText, textMappings };
}

/**
 * Get text from a node recursively
 */
export function getNodeText(node: Node): string {
  return node.nodeType === Node.TEXT_NODE
    ? (node as Text).nodeValue || ""
    : node.nodeType === Node.ELEMENT_NODE
    ? Array.from(node.childNodes)
        .map((child) => getNodeText(child))
        .join(" ")
    : "";
}

/**
 * Highlight nodes that contain the text we're looking for
 */
export function highlightRows(
  overlappingNodes: TextMapping[],
  isDeleteHighlight = false
) {

  // First completely clear all existing highlights
  clearHighlights();

  // Track if the highlighting was successful
  let foundValidHighlights = false;

  try {
    const nodesToHighlight = overlappingNodes;
    console.log(`After context-aware filtering, ${nodesToHighlight.length} nodes remain to highlight`);

    // Get a fresh reference to the container for this highlighting operation
    const pdfViewerContainer = document.querySelector(".vue-pdf-embed__page");
    if (!pdfViewerContainer) {
      console.error("Could not find PDF viewer container");
      return;
    }

    // Store the container's position once for all calculations
    const containerRect = pdfViewerContainer.getBoundingClientRect();
    console.log("PDF container position:", {
      left: containerRect.left,
      top: containerRect.top,
      width: containerRect.width,
      height: containerRect.height,
    });

    // Create a single highlight container with a unique ID
    const containerId = `highlight-container-${Date.now()}`;
    const highlightContainer = document.createElement("div");
    highlightContainer.className = "pdf-highlight-container";
    highlightContainer.setAttribute("id", containerId);
    highlightContainer.style.position = "absolute";
    highlightContainer.style.top = "0";
    highlightContainer.style.left = "0";
    highlightContainer.style.width = "100%";
    highlightContainer.style.height = "100%";
    highlightContainer.style.pointerEvents = "none";
    highlightContainer.style.zIndex = "1000";

    // Add the container to the PDF page and track it
    pdfViewerContainer.appendChild(highlightContainer);
    highlightOverlays.push(highlightContainer);

    // Create a highlight for each valid text node
    nodesToHighlight.forEach((mapping: TextMapping, index) => {
      try {
        // Get the node's position relative to the PDF container
        const nodeRect = mapping.node.getBoundingClientRect();

        // Calculate position relative to the container
        const relativeLeft = nodeRect.left - containerRect.left;
        const relativeTop = nodeRect.top - containerRect.top;
        const width = nodeRect.width;
        const height = nodeRect.height;

        // Debug node position
        const nodeText = (mapping.node.textContent || "").trim();
        const displayText = nodeText.length > 30 ? nodeText.substring(0, 30) + "..." : nodeText;
        console.log("Node position:", {
          nodeText: displayText,
          absolute: `left: ${nodeRect.left}px, top: ${nodeRect.top}px`,
          relative: `left: ${relativeLeft}px, top: ${relativeTop}px`,
          dimensions: `width: ${width}px, height: ${height}px`
        });

        // Create a highlight overlay div
        const overlay = document.createElement("div");
        overlay.className = "pdf-highlight-overlay";
        overlay.style.position = "absolute";
        overlay.style.left = `${relativeLeft}px`;
        overlay.style.top = `${relativeTop}px`;
        overlay.style.width = `${width}px`;
        overlay.style.height = `${height}px`;
        
        if (isDeleteHighlight) {
          overlay.style.backgroundColor = "rgba(255, 150, 150, 0.3)";
          overlay.style.borderRadius = "2px";
          overlay.style.boxShadow = "0 0 0 2px rgba(255, 0, 0, 0.4)";
        } else {
          overlay.style.backgroundColor = "rgba(255, 230, 0, 0.3)";
          overlay.style.borderRadius = "2px";
          overlay.style.boxShadow = "0 0 0 2px rgba(255, 230, 0, 0.5)";
        }
        
        overlay.style.pointerEvents = "none"; // Allow clicking through

        // Add the overlay to the highlight container
        highlightContainer.appendChild(overlay);

        // Track that we found at least one valid highlight
        foundValidHighlights = true;
      } catch (err) {
        console.error("Error creating highlight for node:", err);
      }
    });

    // If we found nodes to highlight and at least one valid overlay was created,
    // scroll to the first valid one
    if (foundValidHighlights && nodesToHighlight.length > 0) {
      // Find the first non-empty node with substantial content
      const firstValidNode = nodesToHighlight.find(
        mapping => (mapping.node.textContent || "").trim().length > 10
      ) || nodesToHighlight[0];
      
      // Scroll the first valid node into view
      firstValidNode.node.scrollIntoView({ behavior: "smooth", block: "center" });
    }
  } catch (error) {
    console.error("Error in highlightRows:", error);
  }
}


/**
 * Clear all highlights from the document
 */
export function clearHighlights() {
  console.log("Clearing all highlights");

  try {
    // Track how many overlays we're removing for debugging
    let removedCount = 0;

    // First, remove highlight classes from all nodes
    document.querySelectorAll(".resume-highlight").forEach((el) => {
      el.classList.remove("resume-highlight");
      el.removeAttribute("style");
    });

    // Find and remove highlight container first
    document.querySelectorAll(".pdf-highlight-container").forEach((container) => {
      if (container.parentNode) {
        container.parentNode.removeChild(container);
        removedCount++;
      }
    });

    // Also find and remove any stray highlight overlays that might exist outside containers
    document.querySelectorAll(".pdf-highlight-overlay").forEach((overlay) => {
      if (overlay.parentNode) {
        overlay.parentNode.removeChild(overlay);
        removedCount++;
      }
    });

    console.log(`Removed ${removedCount} highlight elements in total`);

    // Clear the tracked array
    highlightOverlays.length = 0;
  } catch (error) {
    console.error("Error clearing highlights:", error);
  }
}

/**
 * Find the position of text in a larger text (case insensitive)
 */
export function findTextPosition(text: string, searchText: string): ParagraphPosition | null {
  // Normalize spaces in both the text and search text
  const normalizedText = text.replace(/\s+/g, " ");
  const normalizedSearchText = searchText.replace(/\s+/g, " ");

  // Convert to lowercase for case-insensitive search
  const lowerText = normalizedText.toLowerCase();
  const lowerSearchText = normalizedSearchText.toLowerCase();

  console.log("Looking for:", lowerSearchText);
  console.log("In text sample:", lowerText.substring(0, 100) + "...");

  // Find the position of the search text
  const startPosition = lowerText.indexOf(lowerSearchText);

  if (startPosition === -1) {
    console.log("Exact match not found, trying fuzzy match");
    
    // Try to find the longest substring that matches
    let bestMatchStart = -1;
    let bestMatchLength = 0;
    
    // Generate search terms from the search text - split into words
    const searchTerms = lowerSearchText.split(" ").filter(term => term.length > 3);
    
    // Look for each search term in the text
    for (const term of searchTerms) {
      const termPos = lowerText.indexOf(term);
      if (termPos !== -1) {
        // Found a term, now try to expand around it
        let expandedStart = termPos;
        let expandedEnd = termPos + term.length;
        
        // Try to expand backward
        while (expandedStart > 0) {
          // Check if the previous word is part of our search text
          const prevWordEnd = lowerText.lastIndexOf(" ", expandedStart - 1);
          if (prevWordEnd === -1) break;
          const prevWord = lowerText.substring(prevWordEnd + 1, expandedStart).trim();
          
          // Check if adding this word improves the match
          const currentMatch = lowerText.substring(expandedStart, expandedEnd);
          const potentialMatch = lowerText.substring(prevWordEnd + 1, expandedEnd);
          
          // Calculate match quality before and after adding the word
          const currentMatchQuality = calculateMatchQuality(currentMatch, lowerSearchText);
          const newMatchQuality = calculateMatchQuality(potentialMatch, lowerSearchText);
          
          if (newMatchQuality >= currentMatchQuality && prevWord.length > 0) {
            expandedStart = prevWordEnd + 1;
            console.log(`Expanded backward: improved match from ${(currentMatchQuality * 100).toFixed(1)}% to ${(newMatchQuality * 100).toFixed(1)}%`);
          } else {
            break;
          }
        }
        
        // Try to expand forward
        while (expandedEnd < lowerText.length) {
          // Check if the next word is part of our search text
          const nextWordStart = lowerText.indexOf(" ", expandedEnd);
          if (nextWordStart === -1) break;
          const nextWordEnd = lowerText.indexOf(" ", nextWordStart + 1);
          const nextWordEndPos = nextWordEnd === -1 ? lowerText.length : nextWordEnd;
          
          // Check if adding this word improves the match
          const currentMatch = lowerText.substring(expandedStart, expandedEnd);
          const potentialMatch = lowerText.substring(expandedStart, nextWordEndPos);
          
          // Calculate match quality before and after adding the word
          const currentMatchQuality = calculateMatchQuality(currentMatch, lowerSearchText);
          const newMatchQuality = calculateMatchQuality(potentialMatch, lowerSearchText);
          
          if (newMatchQuality >= currentMatchQuality) {
            expandedEnd = nextWordEndPos;
            console.log(`Expanded forward: improved match from ${(currentMatchQuality * 100).toFixed(1)}% to ${(newMatchQuality * 100).toFixed(1)}%`);
          } else {
            break;
          }
        }
        
        // Check if this is our best match so far
        const matchLength = expandedEnd - expandedStart;
        if (matchLength > bestMatchLength) {
          bestMatchStart = expandedStart;
          bestMatchLength = matchLength;
        }
      }
    }
    
    // If we found a good match (at least 20% of the search text)
    if (bestMatchStart !== -1 && bestMatchLength >= lowerSearchText.length * 0.2) {
      console.log(`Found partial match at position ${bestMatchStart} with ${bestMatchLength} characters matching`);
      return {
        startPosition: bestMatchStart,
        endPosition: bestMatchStart + bestMatchLength,
      };
    }
    
    // Legacy fuzzy matching as fallback
    for (let i = 0; i < lowerText.length - 5; i++) {
      // Check if at least 80% of characters match
      let matchCount = 0;
      for (let j = 0; j < lowerSearchText.length && i + j < lowerText.length; j++) {
        if (lowerText[i + j] === lowerSearchText[j]) {
          matchCount++;
        }
      }

      const matchPercentage = matchCount / lowerSearchText.length;
      if (matchPercentage >= 0.8) {
        console.log(
          `Found fuzzy match at position ${i} with ${(matchPercentage * 100).toFixed(1)}% match`
        );
        return {
          startPosition: i,
          endPosition: i + lowerSearchText.length,
        };
      }
    }

    return null;
  }

  console.log(`Found exact match at position ${startPosition}`);
  return {
    startPosition,
    endPosition: startPosition + lowerSearchText.length,
  };
}

/**
 * Calculate the quality of a match between text and search text
 * Returns a value between 0 and 1, where 1 is a perfect match
 */
function calculateMatchQuality(text: string, searchText: string): number {
  // Both texts should already be lowercase
  // First, check if one contains the other
  if (text.includes(searchText)) return 1;
  if (searchText.includes(text)) return text.length / searchText.length;
  
  // Check for common substrings and word overlap
  const textWords = text.split(" ").filter(w => w.length > 0);
  const searchWords = searchText.split(" ").filter(w => w.length > 0);
  
  // Count matching words
  let matchingWords = 0;
  for (const word of textWords) {
    if (word.length > 2 && searchWords.some(sw => sw.includes(word) || word.includes(sw))) {
      matchingWords++;
    }
  }
  
  // Character-by-character matching for detailed similarity
  let matchingChars = 0;
  const minLength = Math.min(text.length, searchText.length);
  for (let i = 0; i < minLength; i++) {
    if (text[i] === searchText[i]) {
      matchingChars++;
    }
  }
  
  // Compute overall quality using both word and character matching
  const wordMatchQuality = textWords.length > 0 ? matchingWords / textWords.length : 0;
  const charMatchQuality = minLength > 0 ? matchingChars / minLength : 0;
  
  // Weight character matching more heavily for short texts, word matching for longer texts
  const isShortText = text.length < 20 || searchText.length < 20;
  return isShortText 
    ? 0.2 * wordMatchQuality + 0.8 * charMatchQuality
    : 0.6 * wordMatchQuality + 0.4 * charMatchQuality;
}

/**
 * Reset PDF viewer state by forcing a small scroll
 */
export function resetPdfViewerState() {
  const pdfContent = document.querySelector(".pdf-content");
  if (pdfContent) {
    const currentScroll = pdfContent.scrollTop;
    // Scroll up slightly and then back to reset any cached positions
    pdfContent.scrollTo({ top: Math.max(0, currentScroll - 1) });
    setTimeout(() => {
      pdfContent.scrollTo({ top: currentScroll });
    }, 10);
  }
}

/**
 * Highlight text in the PDF
 * @param textToHighlight The text to highlight in the PDF
 * @param showToasts Whether to show toast notifications
 * @param isDeleteHighlight Whether to use the deletion highlight style
 */
export async function highlightTextInPdf(
  textToHighlight: string, 
  showToasts = true, 
  isDeleteHighlight = false
) {
  try {
    console.log("DEBUG: Starting PDF highlight process for:", textToHighlight);
    
    // Clear existing highlights first
    clearHighlights();
    
    // Reset PDF viewer state
    resetPdfViewerState();

    // Get the text layers from the PDF viewer
    const textLayers = document.querySelectorAll(".textLayer");
    if (!textLayers.length) {
      console.error("No text layers found in PDF viewer");
      return;
    }

    // Combine text from all text layers and map each node to its position
    const { combinedText, textMappings } = combineTextAndMap(textLayers);
    
    // Find the position of our text in the combined text
    const position = findTextPosition(combinedText, textToHighlight);
    if (!position) {
      console.error("Text not found in PDF:", textToHighlight);
      if (showToasts) {
        const { toast } = await import("vue3-toastify");
        toast.error("Text not found in the document");
      }
      return;
    }

    const tolerance = 10;
    const overlappingNodes = textMappings.filter(m => 
    (m.start + tolerance <= position.endPosition || m.start - tolerance <= position.endPosition) && 
    (m.end + tolerance >= position.startPosition || m.end - tolerance >= position.startPosition)
    );
    
    console.log("DEBUG: Overlapping nodes:", overlappingNodes);
    
    const stripText = (text: string) => {
      return text.replaceAll(';', '').replaceAll(':', '').replaceAll('.', '').replaceAll('  ', ' ').replaceAll(/\r?\n/g, ' ').trim();
    }
    let textMatches: TextMapping[] = [];
    overlappingNodes.forEach((node, i) => {
      const strippedNodeText = stripText(node.node.textContent || "");
      const strippedTextToHighlight = stripText(textToHighlight);
      if(strippedTextToHighlight.includes(strippedNodeText || "")) {
        textMatches.push(node);
      }
      else 
      {
        console.log("DEBUG: Node does not match:", node.node.textContent);
      }
    });

    textToHighlight = textToHighlight.replace(/\r?\n/g, ' ');
    textToHighlight = textToHighlight.replace('  ', ' ');
    textMatches.sort((a, b) => a.start - b.start);

    const textMatchesString = textMatches.map(node => node.node.textContent).join("").replace(/\r?\n/g, ' ').replace('  ', ' ');
    // Nodes are an exact match
    if (textToHighlight === textMatchesString) {
      console.log('Exact match found, cutting off extra nodes');
    }
    // Nodes, excluding the last node, are an exact match
    const nodesMinusLast = textMatches.slice(0, -1);
    const textMinusLast = nodesMinusLast.map(node => node.node.textContent).join("").replace(/\r?\n/g, ' ').replace('  ', ' ');
    if (textToHighlight === textMinusLast) {
      console.log('Partial match found, cutting off extra nodes');
      textMatches = nodesMinusLast;
    }
    // Nodes, excluding the first node, are an exact match
    const nodesMinusFirst = textMatches.slice(1);
    const textMinusFirst = nodesMinusFirst.map(node => node.node.textContent).join("").replace(/\r?\n/g, ' ').replace('  ', ' ');
    if (textToHighlight === textMinusFirst) {
      console.log('Partial match found, cutting off extra nodes');
      textMatches = nodesMinusFirst;
    }

    // Highlight the nodes that contain our text
    highlightRows(textMatches, isDeleteHighlight);
    
    return true;
  } catch (error) {
    console.error("Error highlighting text in PDF:", error);
    if (showToasts) {
      const { toast } = await import("vue3-toastify");
      toast.error("Error highlighting text");
    }
    return false;
  }
} 