<template>
  <Modal ref="modalRef" modalLg hideFooter hideTitle hideCloseButton>
    <template #body>
      <div class="create-resume-modal-content h-100">
        <!-- Progress Steps -->
        <div class="progress-steps">
          <div
            v-for="step in maxSteps"
            :key="step"
            class="progress-step"
            :class="{
              active: currentStep === step,
              completed: currentStep > step,
            }"
          >
            <div class="step-number">
              <i v-if="currentStep > step" class="bi bi-check-lg"></i>
              <span v-else>{{ step }}</span>
            </div>
            <div class="step-label">{{ getStepLabel(step) }}</div>
          </div>
        </div>

        <!-- Step 1: Import Source -->
        <div v-if="currentStep === 1" class="step-container">
          <div class="source-options">
            <div
              class="source-option"
              :class="{ selected: importSource === 'existing' }"
              @click="selectImportSource('existing')"
            >
              <div class="source-option-icon">
                <i class="bi bi-file-earmark-text"></i>
              </div>
              <div class="source-option-content">
                <h4>Import from file</h4>
                <p>Upload an existing resume in PDF or Word format</p>
              </div>
              <i class="bi bi-chevron-right source-option-arrow"></i>
            </div>
            <div
              class="source-option"
              :class="{ selected: importSource === 'scratch' }"
              @click="selectImportSource('scratch')"
            >
              <div class="source-option-icon">
                <i class="bi bi-file-earmark-plus"></i>
              </div>
              <div class="source-option-content">
                <h4>Start from scratch</h4>
                <p>Create a new resume with our guided builder</p>
              </div>
              <i class="bi bi-chevron-right source-option-arrow"></i>
            </div>
            <div
              class="source-option disabled"
              :class="{ selected: importSource === 'linkedin' }"
            >
              <div class="source-option-icon">
                <i class="bi bi-linkedin"></i>
              </div>
              <div class="source-option-content">
                <h4>Import from LinkedIn</h4>
                <p>Import your profile data directly from LinkedIn</p>
              </div>
              <i class="bi bi-chevron-right source-option-arrow"></i>
              <span class="coming-soon-badge">Coming Soon</span>
            </div>
          </div>
        </div>

        <!-- File Upload Step -->
        <div
          v-if="currentStep === 2 && importSource === 'existing'"
          class="step-container file-upload-step"
        >

          <div
            class="upload-zone"
            :class="{ success: uploadSuccess }"
            @click="!uploadSuccess && triggerFileInput()"
            @drop.prevent="!uploadSuccess && handleFileDrop($event)"
            @dragover.prevent
            :style="{ cursor: uploadSuccess ? 'default' : 'pointer' }"
          >
            <input
              type="file"
              ref="fileInput"
              class="d-none"
              accept=".pdf,.doc,.docx"
              @change="handleFileSelect"
              :disabled="uploadSuccess"
            />
            <div v-if="isUploading" class="upload-content text-center">
              <div class="upload-animation">
                <div class="spinner-border text-primary" role="status">
                  <span class="visually-hidden">Loading...</span>
                </div>
              </div>
              <h6>{{ currentLoadingMessage }}</h6>
            </div>
            <div v-else-if="uploadSuccess" class="upload-content text-center">
              <div class="upload-animation">
                <i class="bi bi-check-circle-fill text-success"></i>
              </div>
              <h6>Resume uploaded successfully!</h6>
              <p class="text-muted mb-0">Ready to proceed to the next step</p>
            </div>
            <div v-else class="upload-content text-center">
              <div class="upload-animation">
                <i class="bi bi-cloud-upload"></i>
              </div>
              <h6>Drag and drop your resume here</h6>
              <p class="text-muted mb-2">or</p>
              <button type="button" class="btn btn-outline-primary">
                <i class="bi bi-file-earmark-arrow-up me-2"></i>
                Choose File
              </button>
              <p class="mt-3 small text-muted">Supported formats: PDF, Word (.doc, .docx)</p>
            </div>
          </div>
          <div v-if="selectedFile" class="selected-file mt-3">
            <div class="d-flex align-items-center">
              <i class="bi bi-file-earmark-text me-2"></i>
              <span class="flex-grow-1">{{ selectedFile.name }}</span>
              <button
                class="btn btn-link text-danger p-0"
                @click="removeFile"
                :disabled="isUploading"
              >
                <i class="bi bi-x-lg"></i>
              </button>
            </div>
          </div>
        </div>

        <!-- Template Selection Step -->
        <div
          v-if="
            (currentStep === 2 && importSource !== 'existing') ||
            (currentStep === 3 && importSource === 'existing')
          "
          class="step-container template-selection-step"
        >

          <div class="form-group floating-label mb-4">
            <input
              type="text"
              class="form-control"
              id="resumeName"
              v-model="newResumeName"
              placeholder=" "
            />
            <label for="resumeName">Resume Name</label>
          </div>

          <div class="templates-grid">
            <div
              v-for="template in ResumeTemplates"
              :key="template.template"
              class="template-card"
              :class="{ selected: selectedTemplate === template.template }"
              @click="selectedTemplate = template.template"
            >
              <div class="template-preview">
                <img :src="template.previewImage" :alt="template.name" />
              </div>
              <div class="template-selected-indicator">
                <i class="bi bi-check-circle-fill"></i>
              </div>
            </div>
          </div>
          <span class="text-muted fst-italic">Don't worry - you can change the template style later</span>
        </div>

        <!-- Footer buttons inside the body -->
        <div class="modal-footer-container">
          <button
            v-if="currentStep > 1"
            type="button"
            class="btn btn-outline-secondary btn-lg"
            @click="prevStep"
            :disabled="isUploading"
          >
            <i class="bi bi-arrow-left me-2"></i>
            Back
          </button>
          <button
            v-else
            type="button"
            class="btn btn-outline-secondary btn-lg"
            @click="hide"
            :disabled="isUploading"
          >
            Cancel
          </button>
          <div class="spacer"></div>
          <button
            type="button"
            class="btn btn-primary btn-lg"
            @click="nextStep"
            :disabled="!canProceed || isUploading"
          >
            <span v-if="isUploading" class="d-flex align-items-center gap-2">
              <div class="spinner-border spinner-border-sm" role="status">
                <span class="visually-hidden">Loading...</span>
              </div>
              Uploading...
            </span>
            <span v-else class="d-flex align-items-center gap-2">
              {{ isLastStep ? "Create Resume" : "Continue" }}
              <i v-if="!isLastStep" class="bi bi-arrow-right"></i>
            </span>
          </button>
        </div>
      </div>
    </template>
  </Modal>
</template>

<script setup lang="ts">
import { ref, onMounted, computed } from "vue";
import { useRouter } from "vue-router";
import { ResumeTemplates } from "../../data/Templates";
import { TemplateOptions } from "../../../../server/src/models/dtos/ResponseDtos";
import { DocumentApiService } from "../../services/DocumentApiService";
import { ApplicationApiService } from "../../services/ApplicationApiService";
import type { UserApplicationListItem } from "../../../../server/src/models/dtos/UserApplicationList";
import type { ResumeListItem } from "../../../../server/src/models/dtos/ResumesList";
import { toast } from "vue3-toastify";
import type DocumentDto from "../../../../server/src/models/dtos/DocumentDto";
import type { Resume } from "../../../../server/src/models/Resume";
import ResumeDropdown from "./ResumeDropdown.vue";
import Modal from "../../components/Modal.vue";

const router = useRouter();
const modalRef = ref<InstanceType<typeof Modal> | null>(null);

// State variables for the modal
const currentStep = ref(1);
const newResumeName = ref("");
const importSource = ref<"scratch" | "linkedin" | "existing">("existing");
const applications = ref<UserApplicationListItem[]>([]);
const selectedApplicationId = ref<string | null>(null);
const jobTitle = ref("");
const jobDescription = ref("");
const selectedTemplate = ref<TemplateOptions>(TemplateOptions.RESUME_CLASSIC);
const loadingApplications = ref(false);
const fileInput = ref<HTMLInputElement | null>(null);
const selectedFile = ref<File | null>(null);
const uploadedDocumentId = ref<string | null>(null);
const isUploading = ref(false);
const currentLoadingMessage = ref("");
const uploadSuccess = ref(false);
const resumes = ref<ResumeListItem[]>([]);
const selectedResumeId = ref<string | null>(null);
const loadingResumes = ref(false);

// Add this constant for loading messages
const UPLOAD_MESSAGES = [
  "Analyzing document structure...",
  "Extracting work experience...",
  "Parsing educational background...",
  "Processing skills and certifications...",
  "Identifying contact information...",
  "Extracting professional summary...",
  "Processing achievements...",
  "Analyzing document formatting...",
  "Organizing extracted data...",
  "Finalizing resume import...",
];

// Computed properties for step management
const maxSteps = computed(() => (importSource.value === "existing" ? 3 : 2));

const isLastStep = computed(() => {
  return importSource.value === "existing" ? currentStep.value === 3 : currentStep.value === 2;
});

const canProceed = computed(() => {
  if (currentStep.value === 1) {
    return importSource.value !== null;
  }
  if (currentStep.value === 2 && importSource.value === "existing") {
    return selectedFile.value !== null && uploadedDocumentId.value !== null;
  }
  return true;
});

// Initialize modal on mount
onMounted(() => {
  fetchResumes();
});

// Methods
function show() {
  resetForm();
  modalRef.value?.show();
}

function hide() {
  modalRef.value?.hide();
}

function resetForm() {
  currentStep.value = 1;
  newResumeName.value = "";
  importSource.value = "existing";
  selectedApplicationId.value = null;
  jobTitle.value = "";
  jobDescription.value = "";
  selectedTemplate.value = TemplateOptions.RESUME_CLASSIC;
  selectedFile.value = null;
  uploadedDocumentId.value = null;
  if (fileInput.value) {
    fileInput.value.value = "";
  }
}

async function fetchApplications() {
  loadingApplications.value = true;
  try {
    const response = await ApplicationApiService.getUserApplicationList();
    applications.value = response.data.applications;
  } catch (error) {
    console.error("Error fetching applications:", error);
    toast.error("Failed to load applications");
  } finally {
    loadingApplications.value = false;
  }
}

function nextStep() {
  if (currentStep.value < maxSteps.value) {
    currentStep.value++;
  } else {
    handleCreateResume();
  }
}

function prevStep() {
  if (currentStep.value > 1) {
    currentStep.value--;
  }
}

// Add helper function for default name generation
function getDefaultResumeName(): string {
  const today = new Date();
  const formattedDate = today.toLocaleDateString("en-US", {
    month: "2-digit",
    day: "2-digit",
    year: "2-digit",
  });
  return `Resume ${formattedDate}`;
}

async function handleCreateResume() {
  const resumeName = newResumeName.value.trim() || getDefaultResumeName();

  try {
    let resumeId;

    if (importSource.value === "existing" && uploadedDocumentId.value) {
      // For imported resumes, just update the name and template
      const getResumeResponse = await DocumentApiService.getUserResume(uploadedDocumentId.value);
      const resume = getResumeResponse.data as Resume;

      const updatedResume: Resume = {
        ...resume,
        template: selectedTemplate.value,
        name: resumeName,
      };

      await DocumentApiService.updateUserResume(updatedResume);
      resumeId = uploadedDocumentId.value;
    } else {
      // Create new resume from scratch
      const response = await DocumentApiService.createResume({
        name: resumeName,
      });
      resumeId = response.data;

      if (resumeId) {
        // Get the resume we just created
        const getResumeResponse = await DocumentApiService.getUserResume(resumeId);
        const resume = getResumeResponse.data as Resume;

        // Update the resume with the template and other fields
        const updatedResume: Resume = {
          ...resume,
          template: selectedTemplate.value,
          name: resumeName,
          contactInfo: {
            firstName: "",
            surname: "",
            email: "",
            phone: "",
            location: "",
            linkedin: "",
            websiteOrPortfolio: "",
          },
          summary: {
            title: "",
            description: "",
          },
          workExperience: [],
          education: [],
          activities: [],
          certifications: [],
          additionalSkills: [],
          projects: [],
          languages: [],
          interests: [],
        };

        await DocumentApiService.updateUserResume(updatedResume);
      }
    }

    hide();
    resetForm();

    await router.push({
      name: "EditResume",
      params: { id: resumeId },
    });

    toast.success("Resume created successfully!", {
      position: toast.POSITION.TOP_RIGHT,
      autoClose: 3000,
    });
  } catch (error) {
    console.error("Error creating resume:", error);
    toast.error("Failed to create resume. Please try again.", {
      position: toast.POSITION.TOP_RIGHT,
      autoClose: 3000,
    });
  }
}

// Methods for file handling
function triggerFileInput() {
  fileInput.value?.click();
}

async function handleFileSelect(event: Event) {
  const input = event.target as HTMLInputElement;
  if (input.files?.length) {
    selectedFile.value = input.files[0];
    await uploadDocument();
  }
}

async function handleFileDrop(event: DragEvent) {
  const files = event.dataTransfer?.files;
  if (files?.length) {
    selectedFile.value = files[0];
    await uploadDocument();
  }
}

// Add this method to handle loading message rotation
function startLoadingMessages() {
  let index = 0;
  currentLoadingMessage.value = UPLOAD_MESSAGES[0];

  const interval = setInterval(() => {
    index = (index + 1) % UPLOAD_MESSAGES.length;
    currentLoadingMessage.value = UPLOAD_MESSAGES[index];
  }, 1000);

  return interval;
}

// Update the upload document method
async function uploadDocument() {
  if (!selectedFile.value) return;

  isUploading.value = true;
  uploadSuccess.value = false;
  const messageInterval = startLoadingMessages();

  try {
    const formData = new FormData();
    formData.append("file", selectedFile.value);

    const uploadResponse = await DocumentApiService.uploadResume(formData);
    const resume = uploadResponse.data;
    // Ensure id is a string before assigning
    if (resume && typeof resume.id === "string") {
      uploadedDocumentId.value = resume.id;
      uploadSuccess.value = true;

      toast.success("Document uploaded successfully!", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 3000,
      });
    } else {
      throw new Error("Invalid resume ID received");
    }
  } catch (error) {
    console.error("Error uploading document:", error);
    toast.error("Failed to upload document. Please try again.", {
      position: toast.POSITION.TOP_RIGHT,
      autoClose: 3000,
    });
    removeFile();
  } finally {
    clearInterval(messageInterval);
    isUploading.value = false;
    currentLoadingMessage.value = "";
  }
}

function removeFile() {
  selectedFile.value = null;
  uploadedDocumentId.value = null;
  uploadSuccess.value = false;
  if (fileInput.value) {
    fileInput.value.value = "";
  }
}

function selectImportSource(source: "scratch" | "linkedin" | "existing") {
  importSource.value = source;
  // Reset file if changing from existing
  if (source !== "existing") {
    selectedFile.value = null;
  }
}

function getStepLabel(step: number) {
  if (importSource.value === "existing") {
    switch (step) {
      case 1:
        return "Resume Details";
      case 2:
        return "Upload File";
      case 3:
        return "Template";
      default:
        return "";
    }
  } else {
    switch (step) {
      case 1:
        return "Resume Details";
      case 2:
        return "Template";
      default:
        return "";
    }
  }
}

// Add function to fetch resumes
async function fetchResumes() {
  loadingResumes.value = true;
  try {
    const response = await DocumentApiService.getUserResumes();
    resumes.value = response.data.map((resume: any) => ({
      ...resume,
      id: resume.id || crypto.randomUUID(),
      name: resume.name || "Untitled Resume",
      lastModified: resume.lastModified ? new Date(resume.lastModified) : new Date(),
      previewUrl: resume.previewUrl || null,
    }));
  } catch (error) {
    console.error("Error fetching resumes:", error);
    toast.error("Failed to load resumes");
  } finally {
    loadingResumes.value = false;
  }
}

// Add function to handle resume selection
async function handleResumeSelect(resumeId: string) {
  const resume = resumes.value.find((r: ResumeListItem) => r.id === resumeId);
  if (resume) {
    uploadedDocumentId.value = resumeId as string;
    uploadSuccess.value = true;
    toast.success("Resume selected successfully!");
  }
}

// Expose methods to parent
defineExpose({
  show,
  hide,
});
</script>

<style scoped>
/* Override Modal Component Styles */
:deep(.modal-content) {
  padding: 0 !important;
  border-radius: 16px !important;
  overflow: hidden;
  box-shadow: 0 10px 25px -5px rgba(0, 0, 0, 0.1), 0 8px 10px -6px rgba(0, 0, 0, 0.1);
}

:deep(.modal-body) {
  padding: 0 !important;
}

.create-resume-modal-content {
  padding: 0;
  display: flex;
  flex-direction: column;
  height: 100%;
  background-color: #ffffff;
  position: relative;
}

/* Progress Steps */
.progress-steps {
  display: flex;
  justify-content: space-between;
  position: relative;
  padding: 1.5rem 5rem 1.25rem;
  background: #ffffff;
  border-bottom: 1px solid rgba(229, 231, 235, 0.5);
}

.progress-steps::before {
  content: "";
  position: absolute;
  top:40px;
  left: 15%;
  right: 15%;
  height: 2px;
  background: #e5e7eb;
  z-index: 1;
}

.progress-step {
  position: relative;
  z-index: 2;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 120px;
}

.step-number {
  width: 36px;
  height: 36px;
  background: white;
  border: 1.5px solid #e5e7eb;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 600;
  color: #6b7280;
  font-size: 14px;
  margin-bottom: 10px;
}

.step-label {
  font-size: 0.875rem;
  color: #6b7280;
  text-align: center;
  font-weight: 500;
}

.progress-step.active .step-number {
  background: #4f46e5;
  border-color: #4f46e5;
  color: white;
}

.progress-step.active .step-label {
  color: #4f46e5;
  font-weight: 600;
}

.progress-step.completed .step-number {
  background: #10b981;
  border-color: #10b981;
  color: white;
}

.progress-step.completed .step-label {
  color: #10b981;
}

/* Step Container */
.step-container {
  flex: 1;
  padding: 1.5rem 2.75rem;
  background: #ffffff;
  border-radius: 0;
  box-shadow: none;
  margin-bottom: 0;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  animation: fadeIn 0.3s ease-out;
}

@keyframes fadeIn {
  from { opacity: 0; transform: translateY(10px); }
  to { opacity: 1; transform: translateY(0); }
}

.step-title {
  font-size: 1.5rem;
  font-weight: 700;
  color: #111827;
  margin-bottom: 0.5rem;
  text-align: center;
  letter-spacing: -0.025em;
}

.step-description {
  font-size: 1rem;
  color: #6b7280;
  margin-bottom: 2rem;
  text-align: center;
  max-width: 600px;
  margin-left: auto;
  margin-right: auto;
}

/* Source Options */
.source-options {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  max-width: 700px;
  margin: 0 auto;
  width: 100%;
}

.source-option {
  padding: 1.5rem;
  border: 1.5px solid #e5e7eb;
  border-radius: 12px;
  display: flex;
  align-items: center;
  gap: 1.5rem;
  cursor: pointer;
  transition: all 0.2s cubic-bezier(0.4, 0, 0.2, 1);
  background: white;
  position: relative;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.05);
}

.source-option:hover {
  border-color: #4f46e5;
  background: #f9fafb;
  transform: translateY(-2px);
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
}

.source-option.selected {
  border-color: #4f46e5;
  background: rgba(79, 70, 229, 0.03);
  box-shadow: 0 4px 6px -1px rgba(79, 70, 229, 0.1), 0 2px 4px -1px rgba(79, 70, 229, 0.06);
}

.source-option-icon {
  width: 48px;
  height: 48px;
  background: #f3f4f6;
  border-radius: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.2s ease;
  box-shadow: inset 0 2px 4px rgba(0, 0, 0, 0.05);
}

.source-option.selected .source-option-icon {
  background: linear-gradient(135deg, #4f46e5, #6366f1);
  box-shadow: 0 4px 6px -1px rgba(79, 70, 229, 0.2);
}

.source-option-icon i {
  font-size: 1.5rem;
  color: #4b5563;
  transition: all 0.2s ease;
}

.source-option.selected .source-option-icon i {
  color: white;
}

.source-option-content {
  flex: 1;
}

.source-option-content h4 {
  font-size: 1.125rem;
  font-weight: 600;
  color: #111827;
  margin-bottom: 0.375rem;
  transition: all 0.2s ease;
}

.source-option.selected .source-option-content h4 {
  color: #4f46e5;
}

.source-option-content p {
  font-size: 0.875rem;
  color: #6b7280;
  margin: 0;
}

.source-option-arrow {
  position: absolute;
  right: 1.5rem;
  font-size: 1.25rem;
  color: #9ca3af;
  transition: all 0.2s ease;
  opacity: 0.7;
}

.source-option.selected .source-option-arrow {
  color: #4f46e5;
  transform: translateX(4px);
  opacity: 1;
}

.source-option.disabled {
  opacity: 0.6;
  cursor: not-allowed;
  border-color: #e5e7eb;
  background: #f9fafb;
}

.source-option.disabled:hover {
  transform: none;
  border-color: #e5e7eb;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.05);
}

.source-option.disabled .source-option-arrow {
  opacity: 0.3;
}

.coming-soon-badge {
  position: absolute;
  top: 12px;
  right: 12px;
  display: inline-block;
  background-color: #e5e7eb;
  color: #6b7280;
  font-size: 0.7rem;
  font-weight: 500;
  padding: 0.2rem 0.5rem;
  border-radius: 4px;
  z-index: 2;
}

/* Upload Zone */
.upload-zone {
  border: 2px dashed #e5e7eb;
  border-radius: 16px;
  padding: 3.5rem 2rem;
  transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
  background: #f9fafb;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  width: 100%;
  max-width: 550px;
  margin: 0 auto;
  box-shadow: inset 0 2px 4px rgba(0, 0, 0, 0.02);
}

.upload-zone:hover {
  border-color: #4f46e5;
  background: rgba(79, 70, 229, 0.03);
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.05), 0 2px 4px -1px rgba(0, 0, 0, 0.03);
}

.upload-zone.success {
  border-color: #10b981;
  background: rgba(16, 185, 129, 0.03);
  border-style: solid;
}

.upload-animation {
  font-size: 3rem;
  margin-bottom: 1.75rem;
  color: #4f46e5;
  transition: all 0.3s ease;
}

.upload-animation .spinner-border {
  width: 3rem;
  height: 3rem;
}

.upload-content h6 {
  font-size: 1.25rem;
  font-weight: 600;
  color: #111827;
  margin-bottom: 0.75rem;
}

.upload-content p.text-muted {
  font-size: 0.875rem;
  color: #6b7280;
  margin-bottom: 1.25rem;
}

.btn-outline-primary {
  color: #4f46e5;
  border-color: #4f46e5;
  background-color: transparent;
  transition: all 0.2s cubic-bezier(0.4, 0, 0.2, 1);
  padding: 0.625rem 1.5rem;
  font-size: 0.875rem;
  border-radius: 8px;
  font-weight: 500;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
}

.btn-outline-primary:hover {
  background: linear-gradient(135deg, #4f46e5, #6366f1);
  color: white;
  border-color: #4f46e5;
  box-shadow: 0 4px 6px -1px rgba(79, 70, 229, 0.2), 0 2px 4px -1px rgba(79, 70, 229, 0.1);
}

.upload-animation i {
  color: #4f46e5;
}

.upload-zone.success .upload-animation i {
  color: #10b981;
}

.small.text-muted {
  font-size: 0.75rem;
  color: #6b7280;
  margin-top: 1.25rem;
}

/* Template Selection */
.templates-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(220px, 1fr));
  gap: 1.5rem;
  padding-right: 0.75rem;
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.template-card {
  border: none;
  overflow: visible;
  cursor: pointer;
  transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
  background: transparent;
  position: relative;
  box-shadow: none;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.template-card:hover {
  transform: translateY(-4px);
  transform: scale(1.2);
}

.template-card.selected .template-preview img {
  outline: 2px solid #4f46e5;
  outline-offset: 2px;
  filter: drop-shadow(0 4px 8px rgba(79, 70, 229, 0.2));
}

.template-preview {
  padding: 0;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  background: transparent;
  border-bottom: none;
  margin-bottom: 0.75rem;
}

.template-preview img {
  max-width: 100%;
  max-height: 240px;
  height: auto;
  object-fit: contain;
  transition: all 0.3s ease;
  filter: drop-shadow(0 2px 4px rgba(0, 0, 0, 0.1));
  border-radius: 4px;
}

.template-card:hover .template-preview img {
  transform: scale(1.03);
  filter: drop-shadow(0 4px 8px rgba(0, 0, 0, 0.15));
}

.template-info {
  padding: 0.5rem;
  text-align: center;
  width: 100%;
}

.template-info h4 {
  font-size: 1rem;
  font-weight: 600;
  margin-bottom: 0.25rem;
  color: #111827;
}

.template-info p {
  font-size: 0.875rem;
  color: #6b7280;
  margin-bottom: 0;
}

.template-selected-indicator {
  position: absolute;
  top: 10px;
  right: 10px;
  color: #4f46e5;
  font-size: 1.25rem;
  opacity: 0;
  transition: opacity 0.2s ease;
}

.template-card.selected .template-selected-indicator {
  opacity: 1;
}

/* Footer */
.modal-footer-container {
  display: flex;
  width: 100%;
  padding: 1rem 2.5rem;
  margin-top: 0;
  border-top: 1px solid rgba(229, 231, 235, 0.5);
  background-color: white;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  justify-content: space-between;
  box-shadow: 0 -1px 2px rgba(0, 0, 0, 0.03);
}

.btn-primary {
  background: linear-gradient(135deg, #4f46e5, #6366f1);
  border-color: #4f46e5;
  padding: 0.75rem 1.75rem;
  font-size: 0.9375rem;
  border-radius: 8px;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  font-weight: 500;
  transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1), 0 1px 2px rgba(0, 0, 0, 0.06);
}

.btn-primary:hover {
  background: linear-gradient(135deg, #4338ca, #4f46e5);
  border-color: #4338ca;
  box-shadow: 0 4px 6px -1px rgba(79, 70, 229, 0.2), 0 2px 4px -1px rgba(79, 70, 229, 0.1);
  transform: translateY(-1px);
}

.btn-outline-secondary {
  color: #4b5563;
  border-color: #e5e7eb;
  padding: 0.75rem 1.75rem;
  font-size: 0.9375rem;
  border-radius: 8px;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  font-weight: 500;
  transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
  background-color: white;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
}

.btn-outline-secondary:hover {
  background-color: #f9fafb;
  color: #111827;
  border-color: #d1d5db;
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
  transform: translateY(-1px);
}

.spacer {
  flex-grow: 1;
}

/* Floating Label */
.floating-label {
  position: relative;
}

.floating-label input {
  height: 52px;
  padding: 1rem 0.875rem;
  font-size: 0.9375rem;
  border: 1.5px solid #e5e7eb;
  border-radius: 10px;
  transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
  background-color: #f9fafb;
  box-shadow: inset 0 2px 4px rgba(0, 0, 0, 0.02);
}

.floating-label input:focus {
  border-color: #4f46e5;
  box-shadow: 0 0 0 4px rgba(79, 70, 229, 0.1);
  background-color: white;
}

.floating-label label {
  position: absolute;
  left: 0.875rem;
  top: 50%;
  transform: translateY(-50%);
  background: transparent;
  padding: 0 0.25rem;
  color: #6b7280;
  transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
  pointer-events: none;
  font-weight: 500;
}

.floating-label input:focus ~ label,
.floating-label input:not(:placeholder-shown) ~ label {
  top: 0;
  transform: translateY(-50%) scale(0.85);
  color: #4f46e5;
  background-color: white;
  font-weight: 600;
}

.btn-lg {
  padding: 0.75rem 1.75rem;
  font-size: 0.9375rem;
}

/* Scrollbar styling */
.templates-grid::-webkit-scrollbar {
  width: 6px;
}

.templates-grid::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 3px;
}

.templates-grid::-webkit-scrollbar-thumb {
  background: #c1c1c1;
  border-radius: 3px;
}

.templates-grid::-webkit-scrollbar-thumb:hover {
  background: #a8a8a8;
}

/* Selected file styling */
.selected-file {
  background-color: #f9fafb;
  border-radius: 8px;
  padding: 0.75rem 1rem;
  border: 1px solid #e5e7eb;
  transition: all 0.2s ease;
}

.selected-file:hover {
  background-color: #f3f4f6;
}

.selected-file i {
  color: #4f46e5;
  font-size: 1.125rem;
}

.selected-file .btn-link {
  transition: all 0.2s ease;
}

.selected-file .btn-link:hover {
  transform: scale(1.1);
}
</style>
