import { TemplateOptions } from "@models/dtos/ResponseDtos";

export const CoverLetterTemplates = [
  {
    name: "Classic",
    previewImage: require("../../public/media/templateThumbnails/CoverClassic.webp"),
    template: TemplateOptions.COVER_LETTER_CLASSIC,
  },
  {
    name: "Graduate",
    previewImage: require("../../public/media/templateThumbnails/CoverGraduate.webp"),
    template: TemplateOptions.COVER_LETTER_GRADUATE,
  },
  {
    name: "Modern",
    previewImage: require("../../public/media/templateThumbnails/CoverModern.webp"),
    template: TemplateOptions.COVER_LETTER_MODERN,
  },
];

export interface ResumeTemplate {
  template: TemplateOptions;
  name: string;
  description: string;
  previewImage: string;
}

export const ResumeTemplates: ResumeTemplate[] = [
  {
    template: TemplateOptions.RESUME_CLASSIC,
    name: "Classic",
    description: "A clean and professional template",
    previewImage: require("../../public/media/templateThumbnails/ResumeClassic.webp")
  },
  {
    template: TemplateOptions.RESUME_GRADUATE,
    name: "Graduate",
    description: "A template for recent graduates",
    previewImage: require("../../public/media/templateThumbnails/ResumeGraduate.webp")
  },
  {
    template: TemplateOptions.RESUME_MINIMALIST,
    name: "Minimalist",
    description: "A minimalist template with a clean look",
    previewImage: require("../../public/media/templateThumbnails/ResumeMinimalist.png")
  }
];
