<template>
  <div class="dashboard-container w-75 mx-auto">
    <h1 class="h3 mb-4">Free Career-Boosting Online Courses (2025)</h1>
    <p class="text-muted mb-4">
      Explore our curated collection of free online courses, updated for 2025, to enhance your
      resume and advance your career. Choose from various categories including programming, data
      science, and business skills.
    </p>

    <!-- Search and Filters -->
    <div class="d-flex gap-2 mb-4">
      <div class="search-box flex-grow-1">
        <i class="bi bi-search"></i>
        <input
          type="text"
          class="form-control"
          placeholder="Search courses..."
          v-model="searchQuery"
        />
      </div>
      <div class="dropdown">
        <button
          class="btn btn-outline-secondary dropdown-toggle"
          type="button"
          data-bs-toggle="dropdown"
        >
          <i class="bi bi-funnel"></i> {{ activeCategory || "All Categories" }}
        </button>
        <ul class="dropdown-menu">
          <li>
            <a class="dropdown-item" href="#" @click="setCategory('')">All Categories</a>
          </li>
          <li v-for="category in uniqueCategories" :key="category">
            <a class="dropdown-item" href="#" @click="setCategory(category)">
              {{ category }}
            </a>
          </li>
        </ul>
      </div>
    </div>

    <!-- Courses Table -->
    <div class="card border-0 shadow-sm courses-table">
      <div class="table-responsive">
        <table class="table table-hover mb-0">
          <thead>
            <tr>
              <th class="sortable-header" @click="sortBy('Technology')">
                Course Title
                <i :class="getSortIcon('Technology')" class="sort-icon"></i>
              </th>
              <th class="sortable-header" @click="sortBy('Provider')">
                Provider
                <i :class="getSortIcon('Provider')" class="sort-icon"></i>
              </th>
              <th class="sortable-header" @click="sortBy('Category')">
                Category
                <i :class="getSortIcon('Category')" class="sort-icon"></i>
              </th>
              <th>Link</th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="course in filteredCourses"
              :key="course.ID"
              @click="showCourseDetails(course)"
              style="cursor: pointer"
            >
              <td>{{ course.Technology }}</td>
              <td>{{ course.Provider }}</td>
              <td>
                <span
                  class="category-badge text-nowrap"
                  :style="{ backgroundColor: getCategoryColor(course.Category) }"
                >
                  {{ course.Category }}
                </span>
              </td>
              <td>
                <a :href="course.Link" target="_blank" class="btn btn-link">
                  <i class="bi bi-box-arrow-up-right"></i> View Course
                </a>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>

  <!-- Course Details Modal -->
  <Modal ref="courseDetailsModal" hide-header modal-md>
    <template #body>
      <div v-if="selectedCourse" class="course-details">
        <div class="mb-4">
          <h4 class="course-title mb-3">{{ selectedCourse.Technology }}</h4>
          <div class="provider mb-2">
            <i class="bi bi-building me-2"></i>
            {{ selectedCourse.Provider }}
          </div>
          <span
            class="category-badge"
            :style="{ backgroundColor: getCategoryColor(selectedCourse.Category) }"
          >
            {{ selectedCourse.Category }}
          </span>
        </div>

        <div class="description mb-4">
          <h6 class="text-muted mb-2">Description</h6>
          <p>{{ selectedCourse.Description }}</p>
        </div>
      </div>
    </template>
    <template #footer>
      <a :href="selectedCourse?.Link" target="_blank" class="btn btn-primary">
        <i class="bi bi-box-arrow-up-right me-2"></i>
        View Course on {{ selectedCourse?.Provider }}
      </a>
    </template>
  </Modal>

  <!-- JobBuddy CTA Modal -->
  <Modal ref="ctaModal" modal-md hide-footer>
    <template #body>
      <div class="cta-section pt-0 p-4 position-relative">
        <div class="position-relative z-1">
          <h5 class="cta-heading mb-3">Supercharge Your Job Hunt</h5>
          <div class="d-flex flex-wrap gap-3 mb-3 justify-content-center">
            <div class="feature-badge-item">
              <i class="bi bi-check-circle-fill"></i> Cover Letter Generator
            </div>
            <div class="feature-badge-item">
              <i class="bi bi-lightning-fill"></i> ATS Resume Optimization
            </div>
            <div class="feature-badge-item">
              <i class="bi bi-graph-up"></i>Beautiful Application Tracking
            </div>
          </div>
          <p class="mb-4">
            Join thousands of job seekers who've landed their dream jobs faster with JobBuddy
          </p>
          <button @click="signup()" class="btn btn-primary btn-lg pulse-animation">
            <i class="bi bi-rocket-takeoff me-2"></i>Get Started Free
          </button>
          <div class="mt-3 testimonial-mini">
            <i class="bi bi-stars text-warning"></i> "JobBuddy helped me land 3 interviews in my
            first week!" - Sarah T.
          </div>
        </div>
      </div>
    </template>
  </Modal>
</template>

<script setup lang="ts">
import { ref, computed, onMounted, onUnmounted } from "vue";
import { useRoute, useRouter } from "vue-router";
import { useHead } from "@unhead/vue";
import Modal from "@/components/Modal.vue";
import coursesData from "@/assets/courses/courses.json";
import { loginWithRedirect } from "../auth/auth0";

const route = useRoute();
const router = useRouter();
const courses = ref(coursesData);
const searchQuery = ref("");
const sortField = ref("Technology");
const sortDirection = ref("asc");
const activeCategory = ref("");
const courseDetailsModal = ref();
const ctaModal = ref();
const selectedCourse = ref(null);
const ctaShown = ref(false);

// SEO metadata
useHead({
  title: "Free Online Courses to Boost Your Resume (2025) | JobBuddy",
  meta: [
    {
      name: "description",
      content:
        "Discover free online courses updated for 2025 to enhance your resume. Browse through programming, data science, and business courses from top providers to advance your career.",
    },
    {
      name: "keywords",
      content:
        "free courses, online learning, career development, resume enhancement, programming courses, 2025 courses",
    },
    {
      property: "og:title",
      content: "Free Online Courses to Boost Your Resume (2025)",
    },
    {
      property: "og:description",
      content:
        "Explore our curated collection of free online courses, updated for 2025, to enhance your resume and advance your career.",
    },
  ],
});

// Generate category colors
const categoryColors = new Map();
const pastelColors = [
  "#FFB3BA",
  "#BAFFC9",
  "#BAE1FF",
  "#FFFFBA",
  "#FFB3FF",
  "#B3FFB3",
  "#B3B3FF",
  "#FFE4B3",
  "#B3FFE4",
  "#FFB3E4",
];

const uniqueCategories = computed(() => {
  const categories = new Set(courses.value.map((course) => course.Category));
  return Array.from(categories).sort();
});

const getCategoryColor = (category: string) => {
  if (!categoryColors.has(category)) {
    const colorIndex = categoryColors.size % pastelColors.length;
    categoryColors.set(category, pastelColors[colorIndex]);
  }
  return categoryColors.get(category);
};

// Handle route query parameters
onMounted(() => {
  const categoryQuery = route.query.category as string;
  if (categoryQuery) {
    activeCategory.value = decodeURIComponent(categoryQuery);
  }

  // Add scroll listener for CTA modal
  window.addEventListener("scroll", handleScroll);
});

onUnmounted(() => {
  // Remove scroll listener
  window.removeEventListener("scroll", handleScroll);
});

const handleScroll = () => {
  // Show CTA modal when user scrolls down 300px and hasn't been shown yet
  if (window.scrollY > 300 && !ctaShown.value) {
    ctaModal.value.show();
    ctaShown.value = true;
  }
};

const signup = () => {
  loginWithRedirect({
    screen: "signup",
    screen_hint: "signup",
    appState: { target: "/dashboard" },
  });
};

const setCategory = (category: string) => {
  activeCategory.value = category;
  if (category) {
    router.push({ query: { ...route.query, category: encodeURIComponent(category) } });
  } else {
    const { category, ...queryWithoutCategory } = route.query;
    router.push({ query: queryWithoutCategory });
  }
};

const filteredCourses = computed(() => {
  return courses.value
    .filter((course) => {
      const matchesSearch =
        searchQuery.value === "" ||
        course.Technology.toLowerCase().includes(searchQuery.value.toLowerCase()) ||
        course.Provider.toLowerCase().includes(searchQuery.value.toLowerCase()) ||
        course.Category.toLowerCase().includes(searchQuery.value.toLowerCase()) ||
        course.Description.toLowerCase().includes(searchQuery.value.toLowerCase());

      const matchesCategory = !activeCategory.value || course.Category === activeCategory.value;

      return matchesSearch && matchesCategory;
    })
    .sort((a, b) => {
      const aValue = a[sortField.value];
      const bValue = b[sortField.value];

      if (typeof aValue === "string" && typeof bValue === "string") {
        return sortDirection.value === "asc"
          ? aValue.localeCompare(bValue)
          : bValue.localeCompare(aValue);
      }

      return sortDirection.value === "asc" ? (aValue > bValue ? 1 : -1) : bValue > aValue ? 1 : -1;
    });
});

const sortBy = (field: string) => {
  if (sortField.value === field) {
    sortDirection.value = sortDirection.value === "asc" ? "desc" : "asc";
  } else {
    sortField.value = field;
    sortDirection.value = "asc";
  }
};

const getSortIcon = (field: string) => {
  if (sortField.value !== field) return "bi bi-chevron-expand";
  return sortDirection.value === "asc" ? "bi bi-chevron-up" : "bi bi-chevron-down";
};

const showCourseDetails = (course) => {
  selectedCourse.value = course;
  courseDetailsModal.value.show();
};
</script>

<style scoped>
.dashboard-container {
  padding: 3rem;
  height: 100vh;
  display: flex;
  flex-direction: column;
}

.search-box {
  position: relative;
}

.search-box i {
  position: absolute;
  left: 1rem;
  top: 50%;
  transform: translateY(-50%);
  color: #666;
}

.search-box input {
  padding-left: 2.5rem;
  border-color: #e5e7eb;
}

.courses-table {
  flex: 1;
  display: flex;
  flex-direction: column;
  min-height: 0;
}

.table-responsive {
  flex: 1;
  overflow: auto;
}

.table {
  margin-bottom: 0;
}

thead {
  position: sticky;
  top: 0;
  z-index: 1;
  background: white;
}

.sortable-header {
  cursor: pointer;
  user-select: none;
  background: white;
}

.sortable-header:hover {
  background-color: #f8f9fa;
}

.sort-icon {
  font-size: 0.75rem;
  vertical-align: middle;
}

.category-badge {
  padding: 0.25rem 0.75rem;
  border-radius: 20px;
  font-size: 0.875rem;
  font-weight: 500;
  color: rgba(0, 0, 0, 0.7);
}

.btn-link {
  text-decoration: none;
  padding: 0.25rem 0.5rem;
  color: #1976d2;
}

.btn-link:hover {
  background-color: #f8f9fa;
}

.dropdown-menu {
  max-height: 300px;
  overflow-y: auto;
}

.course-details {
  padding: 0.5rem;
}

.course-title {
  color: #2c3e50;
  font-weight: 600;
  font-size: 1.5rem;
  line-height: 1.3;
}

.provider {
  color: #666;
  font-size: 1rem;
}

.description {
  color: #4a5568;
  line-height: 1.6;
}

.feature-item {
  display: inline-flex;
  align-items: center;
  gap: 0.35rem;
  font-size: 0.85rem;
  color: var(--bs-gray-700);
  background: white;
  padding: 0.25rem 0.5rem;
  border-radius: 0.25rem;
  border: 1px solid var(--bs-gray-200);
}

.feature-item i {
  font-size: 0.9rem;
}

.feature-title {
  font-weight: 500;
}

.feature-badge {
  font-size: 0.65rem;
  background: var(--bs-primary);
  color: white;
  padding: 0.1rem 0.35rem;
  border-radius: 1rem;
  margin-left: 0.25rem;
  opacity: 0.9;
}

.cta-section {
  text-align: center;
  overflow: hidden;
}

.z-1 {
  z-index: 1;
}

.cta-heading {
  font-weight: 700;
  font-size: 1.5rem;
  background: linear-gradient(90deg, #2b5aff, #7d2bff);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  margin-bottom: 1rem;
}

.feature-badge-item {
  background: white;
  padding: 0.5rem 1rem;
  border-radius: 2rem;
  font-size: 0.85rem;
  font-weight: 500;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.05);
  display: inline-flex;
  align-items: center;
  gap: 0.5rem;
}

.feature-badge-item i {
  color: #2b5aff;
}

.pulse-animation {
  position: relative;
  background: linear-gradient(90deg, #2b5aff, #7d2bff);
  border: none;
  animation: pulse 2s infinite;
  transition: all 0.3s ease;
}

.pulse-animation:hover {
  transform: translateY(-2px);
  box-shadow: 0 6px 15px rgba(43, 90, 255, 0.2);
  background: linear-gradient(90deg, #3a65ff, #8a3dff);
}

@keyframes pulse {
  0% {
    box-shadow: 0 0 0 0 rgba(43, 90, 255, 0.4);
  }
  70% {
    box-shadow: 0 0 0 10px rgba(43, 90, 255, 0);
  }
  100% {
    box-shadow: 0 0 0 0 rgba(43, 90, 255, 0);
  }
}

.testimonial-mini {
  font-size: 0.85rem;
  font-style: italic;
  color: #666;
}

.btn-primary.btn-lg {
  padding: 0.5rem 1.25rem;
  font-weight: 600;
  font-size: 0.95rem;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
  transition: transform 0.15s, box-shadow 0.15s;
}

.h3 {
  color: var(--bs-heading-color, inherit);
  font-weight: 600;
}

.text-muted {
  font-size: 0.95rem;
  line-height: 1.5;
}

tr:hover {
  background-color: #f8f9fa;
  transition: background-color 0.2s ease;
}
</style>
