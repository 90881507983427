<template>
  <Modal ref="modalRef" :hideFooter="true" hide-close-button hide-header>
    <template #body>
      <div class="upgrade-modal-content">
        <div v-if="!isLoading && !isComplete" class="message-container">
          <i class="bi bi-file-earmark-text-fill text-primary upgrade-icon"></i>
          <h5 class="upgrade-title">This is a legacy resume format</h5>
          <p class="upgrade-description">
            Please click upgrade to automatically upgrade your existing resume to the new format, and ensure your resume works with our new features.
          </p>
        </div>
        
        <div v-if="isComplete" class="message-container">
          <i class="bi bi-check-circle-fill text-success upgrade-icon"></i>
          <h5 class="upgrade-title">Resume Upgraded Successfully</h5>
          <p class="upgrade-description">
            Your resume has been upgraded to the new format. Would you like to view it now?
          </p>
        </div>
        
        <div v-if="isLoading" class="loading-container">
          <div class="spinner-border text-primary" role="status">
            <span class="visually-hidden">Loading...</span>
          </div>
          <p class="loading-text mt-3">Upgrading your resume... This may take up to 20 seconds.</p>
        </div>
        
        <div v-else-if="isComplete" class="button-container">
          <button class="btn btn-outline-secondary" @click="closeModal">Close</button>
          <button class="btn btn-primary" @click="viewNewResume">View Resume</button>
        </div>
        
        <div v-else class="button-container">
          <button class="btn btn-outline-secondary" @click="closeModal">Cancel</button>
          <button class="btn btn-primary" @click="upgradeResume">Upgrade</button>
        </div>
      </div>
    </template>
  </Modal>
</template>

<script setup lang="ts">
import { ref, defineEmits } from "vue";
import Modal from "./Modal.vue";
import { DocumentApiService } from "../services/DocumentApiService";
import { toast } from "vue3-toastify";

const props = defineProps<{
  resumeId: string;
}>();

const emit = defineEmits<{
  (e: "upgrade-complete", resume: any): void;
  (e: "view-resume", resumeId: string): void;
}>();

const modalRef = ref<InstanceType<typeof Modal> | null>(null);
const isLoading = ref(false);
const isComplete = ref(false);
const newResumeId = ref<string | null>(null);

const show = () => {
  modalRef.value?.show();
};

const closeModal = () => {
  if (isLoading.value) return; // Prevent closing while loading
  modalRef.value?.hide();
  
  // Reset state when closing
  if (isComplete.value) {
    setTimeout(() => {
      isComplete.value = false;
      newResumeId.value = null;
    }, 300);
  }
};

const viewNewResume = () => {
  if (newResumeId.value !== null && newResumeId.value !== undefined) {
    emit("view-resume", newResumeId.value);
    closeModal();
  }
};

const upgradeResume = async () => {
  try {
    isLoading.value = true;
    const response = await DocumentApiService.upgradeResume({resumeId: props.resumeId});
    
    // Store the new resume ID
    newResumeId.value = response.data.id || null;
    
    // Update state to show success view
    isComplete.value = true;
    
    // Emit the event with the upgraded resume
    emit("upgrade-complete", response.data);
    
    toast.success("Resume upgraded successfully", {
      position: toast.POSITION.TOP_RIGHT,
      autoClose: 3000,
    });
  } catch (error) {
    console.error("Error upgrading resume:", error);
    toast.error("Failed to upgrade resume", {
      position: toast.POSITION.TOP_RIGHT,
      autoClose: 3000,
    });
  } finally {
    isLoading.value = false;
  }
};

defineExpose({
  show,
  closeModal,
});
</script>

<style scoped>
.upgrade-modal-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1rem;
}

.message-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  margin-bottom: 1.5rem;
}

.upgrade-icon {
  font-size: 3rem;
  margin-bottom: 1rem;
}

.upgrade-title {
  font-weight: 600;
  margin-bottom: 0.5rem;
}

.upgrade-description {
  color: #6c757d;
  max-width: 300px;
}

.button-container {
  display: flex;
  gap: 1rem;
}

.loading-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.loading-text {
  color: #6c757d;
  font-size: 0.9rem;
}
</style>
