<template>
  <div class="setup-profile">
    <Modal title="There's been an error!" ref="errorModal">
      <template #body>There was an error processing this request. Please try again later!</template>
    </Modal>
    <Modal title="This license appears to be incorrect" ref="licenseErrorModal">
      <template #body
        >Check your license is typed in exactly as you received it in your invite email. It should
        have the exact format <b>XXXX-XXXX-XXXX-XXXX</b> with no trailing spaces, additional
        characters etc. This license is case sensitive. It can also only be used once. If you're
        still having problems, please email us at hello@jobbuddytech.com</template
      >
    </Modal>
    <Modal title="Terms & Conditions" ref="termsModal">
      <template #body>
        <div class="terms-content-modal">
          <h3>About your data...</h3>

          <p>
            JobBuddy may store some of your data in order to improve your experience using the
            service such as saving your data for use between sessions or in order to provide a
            better service. We won't share your data with anybody, except where you have
            consented for us to do so, or doing so is required in order to provide our services.
            By creating an account with JobBuddy, you agree that your data will be shared with
            OpenAI, the proprietors of the machine learning models JobBuddy uses in order to
            provide its services. You are also therefore agreeing to abide by the OpenAI terms,
            which can be found here: https://openai.com/terms. You may be provided the option to
            provide additional explicit consent to allow us to process your data to improve
            JobBuddy's own machine learning capabilities by allowing your data to be used in
            training a model. This is optional and not required in order to use the service.
          </p>

          <h3>Terms and Conditions</h3>

          <p>
            The materials on JobBuddy's website are provided on an 'as is' basis. JobBuddy makes
            no warranties, expressed or implied, and hereby disclaims and negates all other
            warranties including, without limitation, implied warranties or conditions of
            merchantability, fitness for a particular purpose, or non-infringement of intellectual
            property or other violation of rights.
          </p>

          <p>
            In no event shall JobBuddy or its suppliers be liable for any damages (including,
            without limitation, damages for loss of data or profit, or due to business
            interruption,) arising out of the use or inability to use the materials on
            JobBuddy's Internet site, even if JobBuddy or a JobBuddy authorized representative
            has been notified orally or in writing of the possibility of such damage. Because
            some jurisdictions do not allow limitations on implied warranties, or limitations of
            liability for consequential or incidental damages, these limitations may not apply to
            you.
          </p>

          <h3>Accuracy of materials</h3>

          <p>
            The materials appearing on JobBuddy's website could include technical, typographical,
            or photographic errors. JobBuddy does not warrant that any of the materials on its
            website are accurate, complete or current. JobBuddy may make changes to the materials
            contained on its website at any time without notice. However JobBuddy does not make
            any commitment to update the materials.
          </p>

          <h3>Links</h3>

          <p>
            JobBuddy has not reviewed all of the sites linked to its website and is not
            responsible for the contents of any such linked site. The inclusion of any link does
            not imply endorsement by JobBuddy of the site. Use of any such linked website is at
            the user's own risk.
          </p>

          <h3>Modifications</h3>

          <p>
            JobBuddy may revise these terms of service for its website at any time without
            notice. By using this website you are agreeing to be bound by the then current
            version of these terms of service.
          </p>

          <h3>Governing Law</h3>

          <p>
            These terms and conditions are governed by and construed in accordance with the laws
            of England and you irrevocably submit to the exclusive jurisdiction of the courts in
            that State or location.
          </p>

          <h3>Privacy Policy</h3>

          <p>Your privacy is important to us. It is JobBuddy's policy to respect your privacy.</p>

          <p>
            We don't ask for your personal information unless we truly need it. When we do, we'll
            only collect what we need by fair and lawful means and for a lawful purpose. We'll
            also let you know why we're collecting it and how it will be used.
          </p>

          <p>
            We don't share your personal information with third-parties, except where required by
            law or to protect our own rights. When we do share data with third parties, we will
            ensure that they are GDPR compliant.
          </p>

          <p>
            We only retain collected information for as long as necessary to provide you with
            your requested service and to comply with legal requirements. What data we store,
            we'll protect within commercially acceptable means to prevent loss and theft, as well
            as unauthorized access, disclosure, copying, use or modification.
          </p>

          <p>
            We are committed to conducting our business in accordance with these principles in
            order to ensure that the confidentiality of personal information is protected and
            maintained.
          </p>
        </div>
      </template>
    </Modal>
    <div class="container d-flex align-items-center justify-content-center h-100">
      <div class="form-container">

        <div class="form-sections">
          <!-- All sections in a single card -->
          <div class="card border-0 shadow-sm mb-4">
            <div class="card-body p-4">
              <!-- Personal Details Section -->
              <div class="section mb-4">
                <h2 class="section-title h5 mb-3">
                  <i class="bi bi-person-circle me-2"></i>Personal Details
                </h2>
                <div class="row g-3">
                  <div class="col-md-6">
                    <div class="custom-input">
                      <label for="nameInput" class="form-label">Full Name</label>
                      <input
                        v-model="createUser.name"
                        type="text"
                        class="form-control"
                        :class="{ 'error-input': isSubmitted && !createUser.name }"
                        id="nameInput"
                        placeholder="Enter your full name"
                      />
                    </div>
                  </div>

                  <div class="col-md-6">
                    <div class="custom-input">
                      <label for="emailInput" class="form-label">Email</label>
                      <input
                        v-model="createUser.email"
                        type="email"
                        class="form-control"
                        id="emailInput"
                        placeholder="name@example.com"
                        disabled
                      />
                    </div>
                  </div>
                </div>
              </div>

              <!-- Professional Details Section -->
              <div class="section mb-4">
                <h2 class="section-title h5 mb-3">
                  <i class="bi bi-briefcase me-2"></i>Professional Details
                </h2>
                <div class="row g-3">
                  <div class="col-md-6">
                    <div class="custom-input">
                      <label for="occupationInput" class="form-label">Current Occupation</label>
                      <input
                        v-model="createUser.occupation"
                        type="text"
                        class="form-control"
                        :class="{ 'error-input': isSubmitted && !createUser.occupation }"
                        id="occupationInput"
                        placeholder="Enter your current occupation"
                      />
                    </div>
                  </div>

                  <div class="col-md-6">
                    <div class="custom-input">
                      <label for="employmentStatus" class="form-label">Employment Status</label>
                      <select
                        v-model="createUser.employmentStatus"
                        class="form-select"
                        :class="{ 'error-input': isSubmitted && !createUser.employmentStatus }"
                        id="employmentStatus"
                      >
                        <option value="" disabled selected>Select status</option>
                        <option>Unemployed - Casually browsing</option>
                        <option>Unemployed - Long term unemployed</option>
                        <option>Employed - Looking at new companies</option>
                        <option>Employed - Looking to change career/role</option>
                        <option>Graduate</option>
                      </select>
                    </div>
                  </div>
                </div>
              </div>

              <!-- Additional Information Section -->
              <div class="section mb-4">
                <h2 class="section-title h5 mb-3">
                  <i class="bi bi-info-circle me-2"></i>Additional Information
                </h2>
                <div class="row g-3">
                  <div class="col-md-4">
                    <CountrySelector v-model="createUser.country" />
                  </div>

                  <div class="col-md-4">
                    <div class="custom-input">
                      <label for="genderSelect" class="form-label">Gender</label>
                      <select
                        v-model="createUser.gender"
                        class="form-select"
                        :class="{ 'error-input': isSubmitted && !createUser.gender }"
                        id="genderSelect"
                      >
                        <option value="" disabled selected>Select gender</option>
                        <option>Male</option>
                        <option>Female</option>
                        <option>Other</option>
                      </select>
                    </div>
                  </div>

                  <div class="col-md-4">
                    <div class="custom-input">
                      <label for="sourceSelect" class="form-label">How did you hear about us?</label>
                      <select
                        v-model="createUser.whereDidYouHearAboutUs"
                        class="form-select"
                        :class="{ 'error-input': isSubmitted && !createUser.whereDidYouHearAboutUs }"
                        id="sourceSelect"
                      >
                        <option value="" disabled selected>Select source</option>
                        <option>Google</option>
                        <option>Reddit</option>
                        <option>TikTok</option>
                        <option>Twitter</option>
                        <option>A Friend</option>
                        <option>ChatGPT</option>
                        <option>Instagram</option>
                        <option>Other</option>
                      </select>
                    </div>
                  </div>
                </div>
              </div>

              <!-- Terms Section -->
              <div class="section terms-section mb-4">
                <h2 class="section-title h5 mb-3">
                  <i class="bi bi-shield-check me-2"></i>Terms & Conditions
                </h2>
                <div class="terms-header d-flex align-items-center mb-2">
                  <p class="mb-0 small">Please review and accept our <a href="#" @click.prevent="openTermsModal" class="terms-link">terms and conditions</a></p>
                </div>
                
                <div
                  class="terms-checkbox mt-2"
                  :class="{ 'error-terms': isSubmitted && !createUser.hasAgreedToTerms }"
                >
                  <label class="custom-checkbox">
                    <input type="checkbox" v-model="createUser.hasAgreedToTerms" />
                    <span class="label-text">I agree to the terms and conditions</span>
                  </label>
                  <div v-if="isSubmitted && !createUser.hasAgreedToTerms" class="error-terms small mt-1">
                    You must agree to the terms and conditions to continue
                  </div>
                </div>
              </div>
            </div>
          </div>

          <!-- Submit Button -->
          <button class="submit-button" @click="createNewUser">
            <span v-if="!loading">Complete Signup</span>
            <span v-else>Loading...</span>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import Modal from "@/components/Modal.vue";
import CountrySelector from "@/components/CountrySelector.vue";
import { onMounted, ref } from "vue";
import router from "@/router";
import store from "@/store";
import { useAuth0 } from "@auth0/auth0-vue";
import { countries } from "../components/CountriesList";
const { user } = useAuth0();

const errorModal = ref(null);
const licenseErrorModal = ref(null);
const termsModal = ref(null);
const showTerms = ref(false);

const loading = ref(false);
const license = ref("");
const createUser = ref({
  name: "" as string | null,
  email: "" as string | null,
  occupation: "" as string | null,
  gender: "" as string | null,
  employmentStatus: "" as string | null,
  hasAgreedToTerms: false,
  country: "" as string | null,
  whereDidYouHearAboutUs: "" as string | null,
});

const isSubmitted = ref(false);

onMounted(() => {
  createUser.value.email = user.value?.email?.length ? user.value.email : null;
  createUser.value.gender = user.value?.gender ? user.value.gender : null;
  createUser.value.name =
    user.value?.name && user.value.name !== user.value.email ? user.value.name : null;
});

function openTermsModal() {
  if (termsModal.value) {
    (termsModal.value as any).show();
  }
}

async function createNewUser() {
  isSubmitted.value = true;

  await store.dispatch("user/createUser", createUser.value);
  router.push("/upload-resume");
}
</script>

<style scoped>
.setup-profile {
  background-color: #f8fafc;
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.form-container {
  max-width: 1000px;
  min-width: 650px;
  margin: 0 auto;
}

.form-header h1 {
  font-size: 1.5rem;
  font-weight: 600;
  color: #334155;
}

.form-sections {
  display: flex;
  flex-direction: column;
}

.section-title {
  color: #334155;
  font-weight: 600;
  font-size: 1rem;
  margin-bottom: 1rem;
}

.custom-input {
  margin-bottom: 0.5rem;
}

.form-label {
  font-size: 0.85rem;
  font-weight: 500;
  color: #475569;
  margin-bottom: 0.25rem;
}

.form-control,
.form-select {
  border: 1px solid #e2e8f0;
  border-radius: 8px;
  padding: 0.75rem;
  height: 3rem;
  font-size: 0.9rem;
  background-color: #f8fafc;
}

.form-control:focus,
.form-select:focus {
  border-color: #3b82f6;
  box-shadow: 0 0 0 3px rgba(59, 130, 246, 0.1);
  background-color: #fff;
  outline: none;
}

.error-input {
  border-color: #ef4444 !important;
}

.submit-button {
  background-color: #3b82f6;
  color: white;
  border: none;
  border-radius: 8px;
  padding: 0.75rem 1.5rem;
  font-size: 0.9rem;
  font-weight: 500;
  cursor: pointer;
  transition: background-color 0.2s;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 3rem;
}

.submit-button:hover {
  background-color: #2563eb;
}

.submit-button:disabled {
  background-color: #93c5fd;
  cursor: not-allowed;
}

.terms-content-modal {
  max-height: 400px;
  overflow-y: auto;
  padding-right: 1rem;
}

.terms-content-modal h3 {
  font-size: 1rem;
  margin-top: 1rem;
  margin-bottom: 0.5rem;
  color: #334155;
}

.terms-content-modal p, 
.terms-content-modal li {
  font-size: 0.85rem;
  line-height: 1.4;
  margin-bottom: 0.5rem;
  color: #475569;
}

.terms-link {
  color: #3b82f6;
  text-decoration: none;
  font-weight: 500;
}

.terms-link:hover {
  text-decoration: underline;
}

.custom-checkbox {
  display: flex;
  align-items: center;
  cursor: pointer;
  user-select: none;
}

.custom-checkbox input {
  margin-right: 0.5rem;
}

.label-text {
  font-size: 0.85rem;
  color: #475569;
}

.error-terms {
  color: #ef4444;
}
</style>
