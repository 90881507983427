// Suggestion Service
// Handles all suggestion-related functionality

import { ResumeSuggestion, EditType, AI_RESUME_COMMANDS } from "@models/dtos/ResumeSuggestionsOutput";




export interface ResumeData {
  workExperience?: Array<any>;
  education?: Array<any>;
  projects?: Array<any>;
  [key: string]: any;
}

/**
 * Calculate scores based on suggestions
 */
export function calculateScores(suggestions: ResumeSuggestion[]) {
  // If there are no suggestions, set a perfect score
  if (suggestions.length === 0) {
    return {
      resumeScore: 100,
      keywordsScore: 100,
      experienceScore: 100,
      skillsScore: 100
    };
  }

  // Count suggestions by type and category
  const addCount = suggestions.filter((s) => s.type === "Add").length;
  const editCount = suggestions.filter((s) => s.type === "Edit").length;
  const removeCount = suggestions.filter((s) => s.type === "Remove").length;

  // Count suggestions by location to determine category scores
  const keywordSuggestions = suggestions.filter(
    (s) =>
      s.location.includes("keyword") ||
      s.location.includes("skill") ||
      s.description.toLowerCase().includes("keyword")
  ).length;

  const experienceSuggestions = suggestions.filter(
    (s) =>
      s.location.includes("experience") ||
      s.location.includes("work") ||
      s.description.toLowerCase().includes("experience")
  ).length;

  const skillSuggestions = suggestions.filter(
    (s) => s.location.includes("skill") || s.description.toLowerCase().includes("skill")
  ).length;

  const total = suggestions.length;

  // Calculate overall score (higher is better)
  const baseScore = 100;
  const penalty = Math.min(
    70,
    (addCount + editCount * 1.5 + removeCount * 2) * (100 / Math.max(total * 3, 1))
  );

  const resumeScore = Math.max(30, Math.round(baseScore - penalty));

  // Calculate category scores
  const maxCategoryPenalty = 80;

  // Keywords score - more affected by keyword suggestions
  const keywordPenalty = Math.min(
    maxCategoryPenalty,
    (keywordSuggestions / Math.max(total, 1)) * 100
  );
  const keywordsScore = Math.max(20, Math.round(baseScore - keywordPenalty));

  // Experience score - more affected by experience suggestions
  const experiencePenalty = Math.min(
    maxCategoryPenalty,
    (experienceSuggestions / Math.max(total, 1)) * 100
  );
  const experienceScore = Math.max(20, Math.round(baseScore - experiencePenalty));

  // Skills score - more affected by skill suggestions
  const skillPenalty = Math.min(maxCategoryPenalty, (skillSuggestions / Math.max(total, 1)) * 100);
  const skillsScore = Math.max(20, Math.round(baseScore - skillPenalty));

  return { resumeScore, keywordsScore, experienceScore, skillsScore };
}

/**
 * Helper function to get a value from a nested location path
 */
export function getValueFromLocation(obj: any, location: string): string | undefined {
  try {
    // First try UUID-based location (section:uuid format)
    const [section, uuid] = location.split(":");
    if (uuid) {
      // Handle different sections based on UUID
      if (section === "summary" && obj.summary?.uuid === uuid) {
        return formatContentForDisplay(obj.summary.description);
      }

      // For array-based sections, find the item by UUID
      const sectionData = obj[section] || obj[section.toLowerCase()];
      if (Array.isArray(sectionData)) {
        const item = sectionData.find((i: any) => i.uuid === uuid);
        if (item) {
          if (section === "projects" || section === "workExperience" || section === "education") {
            return formatContentForDisplay(item.description);
          } else if (section === "skills" || section === "additionalSkills") {
            return formatContentForDisplay(item.skillDetails);
          }
        }
      }
      return undefined;
    }

    // Fallback to legacy path-based location
    const parts = location.split(".");
    let current = obj;

    for (let i = 0; i < parts.length; i++) {
      const part = parts[i];

      // Handle array indices in the path (e.g., "workExperience[0]")
      const arrayMatch = part.match(/(\w+)\[(\d+)\]/);
      if (arrayMatch) {
        const arrayName = arrayMatch[1];
        const index = parseInt(arrayMatch[2]);

        if (
          !current[arrayName] ||
          !Array.isArray(current[arrayName]) ||
          index >= current[arrayName].length
        ) {
          return undefined;
        }

        current = current[arrayName][index];
      } else {
        if (current[part] === undefined) {
          return undefined;
        }
        current = current[part];
      }
    }

    return formatContentForDisplay(current);
  } catch (error) {
    console.error("Error getting value from location:", error);
    return undefined;
  }
}

/**
 * Helper function to format content for display
 */
export function formatContentForDisplay(content: any): string {
  if (content === null || content === undefined) {
    return "";
  }

  // If it's an array, format each item
  if (Array.isArray(content)) {
    // For small arrays of primitive values, join with commas
    if (content.length < 5 && content.every((item) => typeof item !== "object")) {
      return content.join(", ");
    }

    // For larger arrays or arrays of objects, format each item on a new line
    return content
      .map((item) => {
        if (typeof item === "object" && item !== null) {
          // For objects in arrays, format them nicely
          return JSON.stringify(item, null, 2);
        }
        return item;
      })
      .join("\n");
  }

  // If it's an object, pretty print it
  if (typeof content === "object" && content !== null) {
    // Try to extract the most relevant information from the object
    if (content.company && content.position) {
      // For work experience items
      return `${content.position} at ${content.company}${
        content.dates ? ` (${content.dates})` : ""
      }`;
    } else if (content.institution && content.degree) {
      // For education items
      return `${content.degree} at ${content.institution}${
        content.dates ? ` (${content.dates})` : ""
      }`;
    } else if (content.name && content.achievements) {
      // For project items
      return `${content.name}${content.dates ? ` (${content.dates})` : ""}`;
    } else if (content.title && content.description) {
      // For summary items
      return content.description;
    } else {
      // For other objects, stringify them but limit the length
      const json = JSON.stringify(content, null, 2);
      if (json.length > 300) {
        return json.substring(0, 300) + "...";
      }
      return json;
    }
  }

  // For primitive values, just convert to string
  return content.toString();
}

/**
 * Transform raw API suggestions to UI-friendly format
 */
export function transformSuggestions(apiSuggestions: any[], resumeData: ResumeData): ResumeSuggestion[] {
  return apiSuggestions.map((suggestion) => {
    let enhancedDescription = suggestion.description;
    let originalContent = undefined;

    // Add context to the description based on the location
    if (resumeData && suggestion.location) {
      const locationParts = suggestion.location.split(".");

      // Try to extract the original content for Edit and Remove suggestions
      if (suggestion.type === EditType.Edit || suggestion.type === EditType.Remove) {
        // Get the original content from the resume data based on the location
        originalContent = getValueFromLocation(resumeData, suggestion.location);
      }

      // Check if it's an array item (like workExperience[0])
      const arrayMatch = locationParts[0].match(/(\w+)\[(\d+)\]/);
      if (arrayMatch) {
        const sectionName = arrayMatch[1];
        const index = parseInt(arrayMatch[2]);

        // Add context based on the section type
        if (
          sectionName === "workExperience" &&
          resumeData.workExperience &&
          resumeData.workExperience[index]
        ) {
          const experience = resumeData.workExperience[index];
          enhancedDescription = `[${experience.company || "Company"} - ${
            experience.position || "Position"
          }] ${suggestion.description}`;
        } else if (
          sectionName === "education" &&
          resumeData.education &&
          resumeData.education[index]
        ) {
          const education = resumeData.education[index];
          enhancedDescription = `[${education.institution || "Institution"} - ${
            education.degree || "Degree"
          }] ${suggestion.description}`;
        } else if (
          sectionName === "projects" &&
          resumeData.projects &&
          resumeData.projects[index]
        ) {
          const project = resumeData.projects[index];
          enhancedDescription = `[${project.name || "Project"}] ${suggestion.description}`;
        }
      } else if (locationParts.length > 1 && locationParts[1].match(/\[\d+\]/)) {
        // Handle nested arrays like workExperience.0.achievements[1]
        const sectionName = locationParts[0];
        const parentIndex = parseInt(locationParts[1].match(/\d+/)?.[0] || "0");
        const childArrayName = locationParts[2].split("[")[0];
        const childIndex = parseInt(locationParts[2].match(/\[(\d+)\]/)?.[1] || "0");

        if (
          sectionName === "workExperience" &&
          resumeData.workExperience &&
          resumeData.workExperience[parentIndex]
        ) {
          const experience = resumeData.workExperience[parentIndex];
          enhancedDescription = `[${experience.company || "Company"} - ${childArrayName}] ${
            suggestion.description
          }`;
        }
      }
    }

    return {
      id: crypto.randomUUID(),
      description: enhancedDescription,
      change: suggestion.change,
      type: suggestion.type as EditType,
      location: suggestion.location,
      originalContent:
        originalContent || (suggestion.type === EditType.Remove ? suggestion.change : undefined),
      action: suggestion.action,
      metadata: suggestion.metadata,
      command: suggestion.command as AI_RESUME_COMMANDS
    };
  });
} 