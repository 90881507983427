import { Module } from "vuex";
import store, { State as RootState } from "../index"; // import root state type
import { DocumentApiService } from "@/services/DocumentApiService";
import DocumentDto from "@models/dtos/DocumentDto";

export interface DocumentsState {
  documents: DocumentDto[];
}

const documentModule: Module<DocumentsState, RootState> = {
  namespaced: true,
  state: {
    documents: [],
  },

  getters: {
    getDocuments(state): DocumentDto[] {
      return state.documents;
    },
  },

  actions: {
    async loadDocuments() {
      const response = await DocumentApiService.getUserDocumentsList();
      if (response.status == 200) {
        const documentsList = response.data;
        store.commit("documents/setDocuments", documentsList);
      }
      if (response.status == 204) {
        store.commit("documents/setDocuments", []);
      }
    },
    async deleteDocument(state, documentId) {
      const response = await DocumentApiService.delete(documentId);
      if (response.status == 200) {
        store.commit("documents/addDocument", response.data);
      }
    },
    async uploadResume(state, formData: FormData) {
      const response = await DocumentApiService.uploadResume(formData);
      if (response.status == 200) {
        await store.dispatch("documents/loadDocuments");
      }
    },
  },

  mutations: {
    setDocuments(state, documents: DocumentDto[]) {
      state.documents = documents;
    },
    addDocument(state, document: DocumentDto) {
      state.documents.push(document);
    },
    deleteDocument(state, documentId) {
      state.documents = state.documents.filter((doc) => doc.id != documentId);
    },
  },
};

export default documentModule;
