import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-e2326686"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "section-tabs" }
const _hoisted_2 = { class: "left-tabs" }
const _hoisted_3 = ["onClick"]
const _hoisted_4 = {
  key: 0,
  class: "bi bi-check-circle-fill check-icon"
}
const _hoisted_5 = { class: "right-tabs" }
const _hoisted_6 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.sections.filter((s) => s.id !== 'preview'), (section) => {
        return (_openBlock(), _createElementBlock("button", {
          key: section.id,
          class: _normalizeClass(["section-tab", {
          active: _ctx.modelValue === section.id,
          completed: _ctx.isSectionComplete(section.id),
          'optimize-tab': section.id === 'optimize',
        }]),
          onClick: ($event: any) => (_ctx.$emit('update:modelValue', section.id))
        }, [
          _createElementVNode("i", {
            class: _normalizeClass(section.icon)
          }, null, 2),
          _createElementVNode("span", null, _toDisplayString(section.title), 1),
          (_ctx.isSectionComplete(section.id))
            ? (_openBlock(), _createElementBlock("i", _hoisted_4))
            : _createCommentVNode("", true)
        ], 10, _hoisted_3))
      }), 128))
    ]),
    _createElementVNode("div", _hoisted_5, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.sections.filter((s) => s.id === 'preview'), (section) => {
        return (_openBlock(), _createElementBlock("button", {
          key: section.id,
          class: _normalizeClass(["preview-button", {
          active: _ctx.modelValue === section.id,
        }]),
          onClick: ($event: any) => (_ctx.$emit('update:modelValue', section.id))
        }, [
          _createElementVNode("i", {
            class: _normalizeClass(section.icon)
          }, null, 2),
          _createElementVNode("span", null, _toDisplayString(section.title), 1)
        ], 10, _hoisted_6))
      }), 128))
    ])
  ]))
}