<template>
  <div class="country-selector custom-input">
    <label for="countrySearch" class="form-label">Search Country</label>
    <input
      type="text"
      class="form-control"
      id="countrySearch"
      :placeholder="selectedCountry ? selectedCountry.name : 'Search for a country'"
      v-model="searchQuery"
      @focus="showDropdown = true"
      @blur="handleBlur"
    />
    
    <div v-if="showDropdown" class="country-dropdown">
      <div 
        v-for="country in filteredCountries" 
        :key="country.code"
        class="country-option"
        @mousedown="selectCountry(country)"
      >
        <span class="country-flag">{{ getCountryFlag(country.code) }}</span>
        <span class="country-name">{{ country.name }}</span>
      </div>
      <div v-if="filteredCountries.length === 0" class="no-results">
        No countries found
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref, computed, watch } from 'vue';
import { countries, type Country } from './CountriesList';
import countryFlagEmoji from 'country-flag-emoji';

const props = defineProps<{
  modelValue: string | null;
}>();

const emit = defineEmits<{
  (e: 'update:modelValue', value: string | null): void;
}>();

const searchQuery = ref('');
const showDropdown = ref(false);
const selectedCountry = ref<Country | null>(
  props.modelValue ? countries.find(c => c.name === props.modelValue) || null : null
);

// Update searchQuery when selectedCountry changes
watch(selectedCountry, (newValue) => {
  if (newValue) {
    searchQuery.value = newValue.name;
  }
});

// Initialize searchQuery with selectedCountry if it exists
if (selectedCountry.value) {
  searchQuery.value = selectedCountry.value.name;
}

const filteredCountries = computed(() => {
  if (!searchQuery.value) return countries;
  const query = searchQuery.value.toLowerCase();
  return countries.filter(country => 
    country.name.toLowerCase().includes(query) || 
    country.code.toLowerCase().includes(query)
  );
});

function getCountryFlag(code: string) {
  const flag = countryFlagEmoji.get(code);
  return flag ? flag.emoji : '';
}

function selectCountry(country: Country) {
  selectedCountry.value = country;
  searchQuery.value = country.name;
  emit('update:modelValue', country.name);
  showDropdown.value = false;
}

function handleBlur() {
  setTimeout(() => {
    showDropdown.value = false;
    // Reset searchQuery to selected country if user didn't select anything
    if (selectedCountry.value) {
      searchQuery.value = selectedCountry.value.name;
    } else if (searchQuery.value) {
      // Try to find a country that matches the search query
      const matchedCountry = countries.find(
        c => c.name.toLowerCase() === searchQuery.value.toLowerCase()
      );
      if (matchedCountry) {
        selectCountry(matchedCountry);
      } else {
        searchQuery.value = '';
      }
    }
  }, 200);
}
</script>

<style scoped>
.country-selector {
  position: relative;
}

.form-label {
  font-size: 0.85rem;
  font-weight: 500;
  color: #475569;
  margin-bottom: 0.25rem;
}

.form-control {
  border: 1px solid #e2e8f0;
  border-radius: 8px;
  padding: 0.75rem;
  height: 3rem;
  font-size: 0.9rem;
  background-color: #f8fafc;
}

.form-control:focus {
  border-color: #3b82f6;
  box-shadow: 0 0 0 3px rgba(59, 130, 246, 0.1);
  background-color: #fff;
  outline: none;
}

.country-dropdown {
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  max-height: 200px;
  overflow-y: auto;
  background-color: white;
  border: 1px solid #e2e8f0;
  border-radius: 8px;
  z-index: 1000;
  margin-top: 0.25rem;
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1);
}

.country-option {
  padding: 0.5rem 0.75rem;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.country-option:hover {
  background-color: #f1f5f9;
}

.country-flag {
  font-size: 1.2rem;
}

.country-name {
  font-size: 0.9rem;
  color: #334155;
}

.no-results {
  padding: 0.5rem 0.75rem;
  color: #64748b;
  font-size: 0.9rem;
  text-align: center;
}
</style>
