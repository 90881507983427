import { useSkillSuggestions } from './useSkillSuggestions';
import { ResumeSuggestion } from "@models/dtos/ResumeSuggestionsOutput";
import { AI_RESUME_COMMANDS } from '@models/dtos/ResumeSuggestionsOutput';

type EmitFunction = {
  (e: "accept", suggestion: ResumeSuggestion): void;
  (e: "reject", suggestion: ResumeSuggestion): void;
  (e: "refresh", suggestion: ResumeSuggestion): void;
  (e: "highlight-text", payload: { suggestion: ResumeSuggestion; highlight: boolean }): void;
}

export function useSuggestionHandlers(emit: EmitFunction) {
  const { isSkillSuggestion } = useSkillSuggestions();

  // Handle suggestion acceptance
  function handleAccept(suggestion: ResumeSuggestion) {
    // If this is a skill suggestion and has metadata, ensure it's properly formatted
    if (
      isSkillSuggestion(suggestion) &&
      (suggestion.command === AI_RESUME_COMMANDS.ADD_SKILL_GROUP || suggestion.command === AI_RESUME_COMMANDS.EDIT_SKILL_GROUP) &&
      suggestion.metadata
    ) {
      // The skills have already been updated in the metadata through the updateSkills function
      emit("accept", suggestion);
    } else {
      // Handle other suggestion types normally
      emit("accept", suggestion);
    }

    // Remove any highlighting when accepting a suggestion
    if (
      suggestion.command === AI_RESUME_COMMANDS.EDIT_WORK_ACHIEVEMENT ||
      suggestion.command === AI_RESUME_COMMANDS.REMOVE_WORK_ACHIEVEMENT ||
      suggestion.command === AI_RESUME_COMMANDS.EDIT_SUMMARY ||
      suggestion.command === AI_RESUME_COMMANDS.EDIT_PROJECT ||
      suggestion.command === AI_RESUME_COMMANDS.ADD_PROJECT ||
      suggestion.command === AI_RESUME_COMMANDS.REMOVE_PROJECT
    ) {
      emit("highlight-text", {
        suggestion,
        highlight: false,
      });
    }
  }

  // Handle suggestion rejection
  function handleReject(suggestion: ResumeSuggestion) {
    emit("reject", suggestion);
  }

  // Handle suggestion refresh
  function handleRefresh(suggestion: ResumeSuggestion) {
    emit("refresh", suggestion);
  }

  return {
    handleAccept,
    handleReject,
    handleRefresh
  };
} 