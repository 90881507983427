<template>
  <div class="master-detail-layout">
    <!-- List Panel (Left) -->
    <div class="list-panel">
      <div class="section-header">
        <div>
          <h2>Education</h2>
          <p>Add your educational background</p>
        </div>
      </div>
      <VueDraggableNext
        v-model="education"
        group="education"
        handle=".drag-handle"
        tag="div"
        class="item-list"
        @change="emitUpdate"
      >
        <div
          v-for="(edu, index) in education"
          :key="index"
          class="list-item"
          :class="{ active: selectedIndex === index }"
          @click="selectedIndex = index"
        >
          <div class="drag-handle">
            <i class="bi bi-grip-vertical"></i>
          </div>
          <div class="list-item-content">
            <div class="list-item-title">{{ edu.course || "New Course" }}</div>
            <div class="list-item-subtitle">{{ edu.instituteName || "Institution Name" }}</div>
          </div>
          <button class="btn-remove" @click.stop="removeEducation(index)">
            <i class="bi bi-trash"></i>
          </button>
        </div>
      </VueDraggableNext>
      <button class="btn-add-position" @click="addEducation">
        <i class="bi bi-plus-lg"></i>
        Add Education
      </button>
    </div>

    <!-- Detail Panel (Right) -->
    <div class="detail-panel" v-if="selectedEducation">
      <div class="form-grid">
        <div class="form-group col-span-2">
          <label>Course / Degree Title</label>
          <input
            v-model="selectedEducation.course"
            type="text"
            placeholder="e.g., Bachelor of Science in Computer Science"
            class="form-control"
            @input="emitUpdate"
          />
        </div>
        <div class="form-group">
          <label>Institution</label>
          <input
            v-model="selectedEducation.instituteName"
            type="text"
            placeholder="e.g., University of Cambridge"
            class="form-control"
            @input="emitUpdate"
          />
        </div>
        <div class="form-group">
          <label>Location</label>
          <input
            v-model="selectedEducation.location"
            type="text"
            placeholder="e.g., Cambridge, UK"
            class="form-control"
            @input="emitUpdate"
          />
        </div>
        <div class="form-group">
          <label>Dates</label>
          <input
            v-model="selectedEducation.dates"
            type="text"
            placeholder="e.g., Sep 2019 - Jun 2023"
            class="form-control"
            @input="emitUpdate"
          />
        </div>
        <div class="form-group">
          <label>Grade/Classification</label>
          <input
            v-model="selectedEducation.gradeOrClassification"
            type="text"
            placeholder="e.g., First Class Honours"
            class="form-control"
            @input="emitUpdate"
          />
        </div>
        <div class="form-group col-span-2">
          <label>Specialism (Optional)</label>
          <input
            v-model="selectedEducation.specialism"
            type="text"
            placeholder="e.g., Artificial Intelligence"
            class="form-control"
            @input="emitUpdate"
          />
        </div>
        <div class="form-group col-span-2">
          <label>Modules Section Title</label>
          <input
            v-model="selectedEducation.modulesTitle"
            type="text"
            placeholder="e.g., Key Modules, Relevant Coursework"
            class="form-control"
            @input="emitUpdate"
          />
        </div>
        <div class="form-group col-span-2">
          <label>Modules</label>
          <div class="modules-list">
            <div
              v-for="(module, mIndex) in selectedEducation.modules"
              :key="module.uuid"
              class="module-item"
            >
              <div class="drag-handle">
                <i class="bi bi-grip-vertical"></i>
              </div>
              <input
                v-model="module.module"
                type="text"
                class="form-control"
                placeholder="Module name"
                @input="emitUpdate"
              />
              <input
                v-model="module.grade"
                type="text"
                class="form-control grade-input"
                placeholder="Grade"
                @input="emitUpdate"
              />
              <button class="btn-remove-small" @click="removeModule(mIndex)">
                <i class="bi bi-trash"></i>
              </button>
            </div>
            <button class="btn-add-module" @click="addModule">
              <i class="bi bi-plus-lg"></i>
              Add Module
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref, computed, watch } from "vue";
import { VueDraggableNext } from "vue-draggable-next";
import type { Education } from "../../../../server/src/models/Resume";

const props = defineProps<{
  modelValue: Education[];
}>();

const emit = defineEmits<{
  (e: "update:modelValue", value: Education[]): void;
}>();

const education = ref<Education[]>([...props.modelValue]);
const selectedIndex = ref(0);

watch(
  () => props.modelValue,
  (newValue) => {
    education.value = [...newValue];
  },
  { deep: true }
);

const selectedEducation = computed(() => {
  if (!education.value.length) return null;
  return education.value[selectedIndex.value];
});

function addEducation() {
  education.value.unshift({
    instituteName: "",
    location: "",
    course: "",
    dates: "",
    specialism: "",
    gradeOrClassification: "",
    modulesTitle: "",
    modules: [
      {
        module: "",
        grade: "",
        uuid: crypto.randomUUID(),
      },
    ],
    uuid: crypto.randomUUID(),
  });
  selectedIndex.value = 0;
  emitUpdate();
}

function removeEducation(index: number) {
  education.value.splice(index, 1);
  if (selectedIndex.value >= education.value.length) {
    selectedIndex.value = Math.max(0, education.value.length - 1);
  }
  emitUpdate();
}

function addModule() {
  if (selectedEducation.value) {
    if (!selectedEducation.value.modules) {
      selectedEducation.value.modules = [];
    }
    selectedEducation.value.modules.push({
      module: "",
      grade: "",
      uuid: crypto.randomUUID(),
    });
    emitUpdate();
  }
}

function removeModule(index: number) {
  if (selectedEducation.value?.modules) {
    selectedEducation.value.modules.splice(index, 1);
    emitUpdate();
  }
}

function emitUpdate() {
  emit("update:modelValue", [...education.value]);
}
</script>

<style scoped>
.master-detail-layout {
  display: grid;
  grid-template-columns: 300px 1fr;
  gap: 2rem;
  height: 100%;
  min-height: 400px;
  max-height: 100%;
  overflow: hidden;
}

.list-panel {
  border-right: 1px solid #e5e7eb;
  padding: 0 1rem 1rem 0;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  max-height: 100%;
}

.section-header {
  padding: 0;
  margin-bottom: 1rem;
  border-bottom: 1px solid #e5e7eb;
  padding-bottom: 1rem;
}

.section-header h2 {
  font-size: 1.25rem;
  margin-bottom: 0.25rem;
}

.section-header p {
  font-size: 0.875rem;
  color: #6b7280;
  margin: 0;
}

.detail-panel {
  padding: 0;
  border-radius: 12px;
  overflow-y: auto;
  max-height: 100%;
}

.form-grid {
  padding: 1rem;
  background: white;
  border-radius: 12px;
  margin: 0;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 1rem;
}

.form-group {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.form-group.col-span-2 {
  grid-column: span 2;
  width: 100%;
}

.item-list {
  flex: 1;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  min-height: 0;
  padding-top: 0.5rem;
}

.list-item {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  padding: 0.75rem;
  border: 1px solid #e5e7eb;
  border-radius: 8px;
  cursor: pointer;
  background: white;
  transition: all 0.2s ease;
}

.list-item:hover {
  border-color: #0c6efd;
  transform: translateY(-1px);
}

.list-item.active {
  border-color: #0c6efd;
  background: #f0f7ff;
}

.list-item-content {
  flex: 1;
  min-width: 0;
}

.list-item-title {
  font-weight: 500;
  color: #111827;
  margin-bottom: 0.25rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.list-item-subtitle {
  font-size: 0.875rem;
  color: #6b7280;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.drag-handle {
  cursor: grab;
  padding: 0.5rem;
  color: #6b7280;
  display: flex;
  align-items: center;
}

.drag-handle:active {
  cursor: grabbing;
}

.btn-remove {
  color: #ef4444;
  border: none;
  background: none;
  padding: 0;
  border-radius: 6px;
  opacity: 0;
  transition: all 0.2s ease;
}

.list-item:hover .btn-remove {
  opacity: 1;
}

.btn-add-position {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  background: #0c6efd;
  color: white;
  border: none;
  padding: 0.75rem;
  border-radius: 8px;
  font-size: 0.875rem;
  font-weight: 500;
  width: 100%;
  transition: all 0.2s ease;
  margin-top: auto;
}

.btn-add-position:hover {
  background: #0b5ed7;
  transform: translateY(-1px);
}

.modules-list {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: 0.5rem;
  background: #f9fafb;
  border-radius: 8px;
  height: 100%;
}

.module-item {
  width: 100%;
  display: flex;
  gap: 1rem;
  margin-bottom: 0.5rem;
  align-items: center;
  padding: 0.5rem;
  border-radius: 6px;
  background: white;
  transition: all 0.2s ease;
}

.module-item:hover {
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
}

.module-item .form-control {
  flex: 1;
  border: none;
  background: transparent;
  padding: 0.5rem;
  font-size: 0.875rem;
}

.module-item .grade-input {
  max-width: 100px;
}

.module-item .form-control:focus {
  outline: none;
  background: white;
  box-shadow: none;
}

.btn-remove-small {
  opacity: 0;
  color: #6b7280;
  padding: 0;
  border: none;
  background: none;
  transition: all 0.2s ease;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1rem;
}

.module-item:hover .btn-remove-small {
  opacity: 1;
}

.btn-remove-small:hover {
  color: #ef4444;
}

.btn-add-module {
  margin-top: 0.5rem;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  color: #0c6efd;
  background: white;
  border: 1px dashed #e5e7eb;
  padding: 0.75rem;
  border-radius: 6px;
  width: 100%;
  justify-content: center;
  font-size: 0.875rem;
  transition: all 0.2s ease;
}

.btn-add-module:hover {
  border-color: #0c6efd;
  background: rgba(12, 110, 253, 0.1);
}

.form-control {
  padding: 0.75rem;
  border: 1px solid #e5e7eb;
  border-radius: 8px;
  font-size: 0.875rem;
  transition: all 0.2s ease;
  background: #f9fafb;
}

.form-control:focus {
  outline: none;
  border-color: #0c6efd;
  background: white;
  box-shadow: 0 0 0 3px rgba(12, 110, 253, 0.1);
}

label {
  font-size: 0.875rem;
  font-weight: 500;
  color: #374151;
  text-align: left;
  margin-bottom: 0.25rem;
}
</style>
