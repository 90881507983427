import type AIProfile from '../AIProfile'
import type CoverLetterSettings from '../CoverLetterSettings'
import { type ResponseType } from '../Response'
import { type Tone } from '../SystemEnums'
export class ResponseDto {
  id: string | null
  content: string
  applicationId: string
  userId: string
  isFavourite: boolean
  type: ResponseType
  documentId: string | null
  temperature: number
  tone: Tone
  template: TemplateOptions | null
  fileId: string | null
  schema: Record<string, unknown> | null
  customInstructions: string | null
  keywords: string[] | null

  constructor (id: string | null, content: string, applicationId: string, userId: string, isFavourite: boolean, type: ResponseType, documentId: string | null, temperature: number, tone: Tone, template: TemplateOptions | null, fileId: string, schema: Record<string, unknown> | null, customInstructions: string | null, keywords: string[] | null) {
    this.id = id
    this.content = content
    this.applicationId = applicationId
    this.userId = userId
    this.isFavourite = isFavourite
    this.type = type
    this.documentId = documentId
    this.temperature = temperature
    this.tone = tone
    this.template = template
    this.fileId = fileId
    this.schema = schema
    this.customInstructions = customInstructions
    this.keywords = keywords
  }
}

export enum TemplateOptions {
  RESUME_CLASSIC,
  RESUME_GRADUATE,
  RESUME_MODERN,
  RESUME_MINIMALIST,
  COVER_LETTER_CLASSIC,
  COVER_LETTER_MODERN,
  COVER_LETTER_GRADUATE,
  COVER_LETTER_CLASSIC_2,
  COVER_LETTER_MODERN_2,
  COVER_LETTER_GRADUATE_2,
  COVER_LETTER_CLASSIC_3,
  COVER_LETTER_MODERN_3,
  COVER_LETTER_GRADUATE_3,
  COVER_LETTER_CLASSIC_4,
  COVER_LETTER_MODERN_4,
  COVER_LETTER_GRADUATE_4,
  INTERVIEW_PREP,
  RESUME_FEEDBACK
}

export class GenerateNewResponse {
  applicationId: string
  documentId: string
  type: ResponseType
  temperature: number
  tone: Tone
  template: TemplateOptions | null
  coverLetterSettings?: CoverLetterSettings
  aiProfile?: AIProfile
  customInstructions?: string
  keywords?: string[]

  constructor (applicationId: string, documentId: string, type: ResponseType, temperature: number, tone: Tone, template: TemplateOptions | null, coverLetterSettings?: CoverLetterSettings, aiProfile?: AIProfile, customInstructions?: string, keywords?: string[]) {
    this.applicationId = applicationId
    this.documentId = documentId
    this.type = type
    this.temperature = temperature
    this.tone = tone
    this.template = template
    this.coverLetterSettings = coverLetterSettings
    this.aiProfile = aiProfile
    this.customInstructions = customInstructions
    this.keywords = keywords
  }
}
