<template>
  <div class="sidebar bg-white border-end">
    <div class="d-flex align-items-center p-3 mb-2">
      <img
        src="../../public/media/jobbuddymascot.webp"
        alt="JobBuddy Logo"
        class="me-2 jobbuddy-logo"
        style="width: 32px; height: 32px"
      />
      <span class="fs-5 fw-bold">Job<span class="text-primary">Buddy</span></span>
    </div>

    <div class="nav-section mb-auto">
      <router-link to="/dashboard" class="nav-item" active-class="active">
        <i class="bi bi-speedometer2"></i>
        <span>Applications</span>
      </router-link>

      <router-link to="/resumes" class="nav-item" active-class="active">
        <i class="bi bi-person-vcard"></i>
        <span>Resumes</span>
      </router-link>
      <router-link
        to="/cover-letters"
        class="nav-item"
        :class="{
          active:
            $route.name === 'CoverLetterGenerator' || $route.path.startsWith('/cover-letters'),
        }"
        active-class="active"
      >
        <i class="bi bi-file-text"></i>
        <span>Cover Letters</span>
      </router-link>
      <router-link
        to="/interview-practice"
        class="nav-item"
        :class="{ active: $route.name === 'InterviewPractice' }"
        active-class="active"
      >
        <i class="bi bi-mic"></i>
        <span>Interview Practice</span>
      </router-link>
      <router-link to="/stats" class="nav-item" active-class="active">
        <i class="bi bi-graph-up"></i>
        <span>Stats</span>
      </router-link>
      <router-link to="/taskboard" class="nav-item" active-class="active">
        <i class="bi bi-kanban"></i>
        <span>Taskboard</span>
      </router-link>
      <router-link to="/feedback" class="nav-item" active-class="active">
        <i class="bi bi-heart"></i>
        <span>Feedback</span>
      </router-link>
    </div>
    <!-- Plan status box -->
    <div class="plan-box">
      <div class="d-flex align-items-center gap-2 mb-2">
        <i class="bi bi-star text-muted"></i>
        <span class="text-muted"
          >{{
            jbUser?.subscriptionPlan != null ? humanisePlan(jbUser.subscriptionPlan) : "Free"
          }}
          Plan</span
        >
      </div>
      <div class="d-flex justify-content-between align-items-center mb-2">
        <span>Applications</span>
        <span>{{ applicationQuota.usage }} / {{ applicationQuota.quota }}</span>
      </div>
      <div class="progress mb-3" style="height: 4px">
        <div
          class="progress-bar bg-primary"
          :style="{ width: `${(applicationQuota.usage / applicationQuota.quota) * 100}%` }"
        ></div>
      </div>
      <button
        @click="showSubscribeModal = true"
        class="btn btn-outline-secondary w-100"
        :class="{ 'upgrade-needed': applicationQuota.usage >= applicationQuota.quota }"
      >
        Upgrade plan
      </button>

      <!-- Subscribe Modal -->
      <Teleport to="body">
        <div
          v-if="showSubscribeModal"
          class="modal fade show"
          style="display: block; background: rgba(0, 0, 0, 0.5)"
          @click.self="showSubscribeModal = false"
        >
          <Subscribe :is-modal="true" @close="showSubscribeModal = false" />
        </div>
      </Teleport>
    </div>

    <!-- User profile box -->
    <div class="user-box">
      <div class="d-flex align-items-center gap-2">
        <div class="user-avatar" v-if="!isLoading">
          <template v-if="user">
            <img
              :src="user.picture"
              alt="User avatar"
              @error="handleImageError"
              style="width: 100%; height: 100%; object-fit: cover"
            />
          </template>
          <div v-else class="avatar-placeholder">{{ userInitials }}</div>
        </div>
        <div class="user-info d-flex align-items-center">
          <div class="user-email text-truncate">{{ user.name }}</div>
          <div class="dropdown">
            <i class="bi bi-gear ms-4 settings-icon" data-bs-toggle="dropdown"></i>
            <ul class="dropdown-menu dropdown-menu-end">
              <li>
                <router-link :to="{ name: 'Profile' }" class="dropdown-item">
                  <i class="bi bi-person me-2"></i>Account
                </router-link>
              </li>
              <li><hr class="dropdown-divider" /></li>
              <li>
                <a @click="logout" href="#" class="dropdown-item">
                  <i class="bi bi-box-arrow-right me-2"></i>Logout
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.sidebar {
  width: 240px;
  height: 100vh;
  display: flex;
  flex-direction: column;
  position: fixed;
  left: 0;
  top: 0;
}

.nav-section {
  padding: 0.5rem;
}

.nav-item {
  display: flex;
  align-items: center;
  padding: 0.75rem 1rem;
  color: #4b5563;
  text-decoration: none;
  border-radius: 8px;
  margin: 2px 0;
  gap: 12px;
  transition: all 0.2s ease;
  position: relative;
}

.nav-item:hover {
  background-color: #f3f4f6;
}

.nav-item.active {
  background-color: #f3f4f6;
  color: #2563eb;
  font-weight: 500;
}

.nav-item i {
  font-size: 1.2rem;
  width: 24px;
}

.nav-item span {
  font-size: 0.95rem;
}

.plan-box {
  margin: 1rem;
  padding: 1rem;
  background-color: #f8f9fa;
  border-radius: 8px;
}

.progress {
  background-color: #f1f1f1;
  border-radius: 2px;
}

.user-box {
  margin: 1rem;
  padding: 0.75rem;
  border-radius: 8px;
  background-color: #f8f9fa;
  cursor: pointer;
}

.user-box:hover {
  background-color: #f3f4f6;
}

.user-avatar {
  width: 32px;
  height: 32px;
  border-radius: 6px;
  overflow: hidden;
  background-color: #4759ff;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  font-weight: 500;
}

.user-avatar img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.user-info {
  flex: 1;
  min-width: 0; /* Enables text truncation */
}

.user-email {
  font-size: 0.875rem;
  color: #4b5563;
}

.settings-icon {
  cursor: pointer;
  font-size: 1.2rem;
  color: #4b5563;
  transition: color 0.2s ease;
}

.settings-icon:hover {
  color: #2563eb;
}

.dropdown-item {
  padding: 0.5rem 1rem;
  cursor: pointer;
}

.dropdown-item:hover {
  background-color: #f3f4f6;
}

.dropdown-item i {
  width: 20px;
}

.coming-soon-pill {
  position: absolute;
  right: 8px;
  background-color: #e5e7eb;
  color: #6b7280;
  padding: 2px 8px;
  border-radius: 12px;
  font-size: 0.7rem;
  font-weight: 500;
}

.jobbuddy-logo {
  margin-bottom: 5px;
  margin-left: 2px;
}

@keyframes pulse-glow {
  0% {
    box-shadow: 0 0 15px rgba(37, 99, 235, 0.3);
  }
  50% {
    box-shadow: 0 0 20px rgba(37, 99, 235, 0.6);
  }
  100% {
    box-shadow: 0 0 15px rgba(37, 99, 235, 0.3);
  }
}

.upgrade-needed {
  animation: pulse-glow 2s infinite;
  border-color: #2563eb !important;
  color: #2563eb !important;
}
</style>

<script setup lang="ts">
import { computed, onMounted, onUnmounted, ref, watch } from "vue";
import { useAuth0 } from "@auth0/auth0-vue";
import { useRouter } from "vue-router";
import store from "../store";
import { SubscriptionsApiService } from "@/services/SubscriptionsApiService";
import { SubscriptionControlledActions } from "@models/SystemEnums";
import Subscribe from "@/views/Subscribe.vue";

const router = useRouter();
const { user, logout: auth0Logout, isAuthenticated } = useAuth0();
const jbUser = ref({});
const userAvatar = computed(() => user.value?.picture);
const userInitials = computed(() => {
  if (!user.value.name) return "👤";
  return user.value.name
    .split(" ")
    .map((name) => name[0])
    .join("")
    .toUpperCase();
});

const applicationQuota = ref({ quota: 0, usage: 0 });
const isLoading = ref(true);
const showSubscribeModal = ref(false);

const logout = () => {
  auth0Logout({ returnTo: window.location.origin });
};

async function loadUserData() {
  try {
    isLoading.value = true;
    jbUser.value = await store.getters["user/getLoggedInUser"];
    await updateQuota();
  } catch (error) {
    console.error("Failed to load user data:", error);
  } finally {
    isLoading.value = false;
  }
}

// Watch for auth state changes
watch(
  isAuthenticated,
  async (isAuth) => {
    if (isAuth) {
      await loadUserData();
    }
  },
  { immediate: true }
);

onMounted(() => {
  // Only add event listener, data loading is handled by the watcher
  window.addEventListener("application-created", updateQuota);
});

// Add cleanup
onUnmounted(() => {
  window.removeEventListener("application-created", updateQuota);
});

// Separate function to update quota
async function updateQuota() {
  try {
    const response = await SubscriptionsApiService.getUserQuota(
      SubscriptionControlledActions.CREATE_APPLICATION
    );
    applicationQuota.value = response.data;
  } catch (error) {
    console.error("Failed to update quota:", error);
    applicationQuota.value = { quota: 0, usage: 0 };
  }
}

async function upgradePlan() {
  router.push({ path: "/subscribe", replace: true });
}

function humanisePlan(plan: string) {
  return plan.replace(/_/g, " ").replace(/\b\w/g, (char) => char.toUpperCase());
}

function handleImageError(event: Event) {
  const target = event.target as HTMLImageElement;
  const parent = target.parentElement;
  if (parent) {
    // Remove the img element
    parent.removeChild(target);
    // Create a div for initials
    const initialsDiv = document.createElement("div");
    initialsDiv.className = "avatar-placeholder";
    initialsDiv.textContent = userInitials.value;
    parent.appendChild(initialsDiv);
  }
}
</script>
