<template>
  <Modal ref="modal" hideFooter>
    <template #body>
      <div class="download-container">
        <div class="download-header">
          <h4 class="download-title">Choose Export Format</h4>
          <p class="download-subtitle">
            Download your {{ documentType === "resume" ? "resume" : "cover letter" }} in your
            preferred format
          </p>
        </div>

        <div v-if="loading" class="loading-container">
          <div class="spinner-container">
            <div class="spinner"></div>
          </div>
          <p class="loading-text">Preparing your download...</p>
        </div>

        <div v-else class="download-options">
          <div class="download-option" @click="downloadAsPdf">
            <div class="option-icon pdf-icon">
              <i class="bi bi-file-pdf"></i>
            </div>
            <div class="option-content">
              <h5>PDF Format</h5>
              <p>Best for submitting applications</p>
            </div>
          </div>

          <div
            class="download-option"
            :class="{ 'premium-option': !isPremiumUser }"
            @click="handleWordDownload"
          >
            <div class="option-icon word-icon">
              <i class="bi bi-file-word"></i>
            </div>
            <div class="option-content">
              <h5>
                Word Document
                <span v-if="!isPremiumUser" class="premium-badge"
                  ><i class="bi bi-gem me-1"></i>Premium</span
                >
              </h5>
              <p v-if="isPremiumUser">Editable in Microsoft Word</p>
              <p v-else>Edit, customize, and tailor your document</p>
            </div>
          </div>

          <div class="download-option disabled">
            <div class="option-icon txt-icon">
              <i class="bi bi-file-text"></i>
            </div>
            <div class="option-content">
              <h5>Plain Text</h5>
              <p>Coming soon</p>
              <span class="coming-soon-badge">Soon</span>
            </div>
          </div>
        </div>
      </div>
    </template>
  </Modal>

  <!-- Use the existing Subscribe component as a modal -->
  <Teleport to="body">
    <div
      v-if="showSubscribeModal"
      class="modal fade show d-block"
      tabindex="-1"
      style="background: rgba(0, 0, 0, 0.5); overflow-y: auto; height: 100vh"
    >
      <Subscribe :is-modal="true" @close="closeSubscribeModal" />
      <button
        type="button"
        class="btn-close position-absolute top-0 end-0 m-4"
        @click="closeSubscribeModal"
        style="z-index: 1050"
      ></button>
    </div>
  </Teleport>
</template>

<script setup lang="ts">
import { ref, computed } from "vue";
import { toast } from "vue3-toastify";
import Modal from "./Modal.vue";
import Subscribe from "../views/Subscribe.vue";
import { DocumentApiService } from "../services/DocumentApiService";
import { ResumeGeneratorService } from "../services/ResumeGeneratorService";
import { FileApiService } from "../services/FilesApiService";
import { ResponseDto } from "../../../server/src/models/dtos/ResponseDtos";
import { TemplateOptions } from "../../../server/src/models/dtos/ResponseDtos";
import { ResponseType } from "../../../server/src/models/Response";
import { Tone } from "../../../server/src/models/SystemEnums";
import { SubscriptionPlan } from "../../../server/src/models/SystemEnums";
import type { Resume } from "../../../server/src/models/Resume";
import { useStore } from "vuex";

const store = useStore();
const modal = ref<InstanceType<typeof Modal> | null>(null);
const loading = ref(false);
const showSubscribeModal = ref(false);

// Props
const props = defineProps<{
  resumeId?: string | null;
  resume?: Resume | null;
  documentId?: string | null;
  documentType?: "resume" | "coverLetter";
}>();

// Check if user has premium subscription
const isPremiumUser = computed(() => {
  const user = store.state.user.user;
  return user && user.subscriptionPlan !== SubscriptionPlan.BASIC && user.subscriptionPlan !== "";
});

// Shared function to get resume data if not provided
async function getResumeData() {
  if (props.resume) return props.resume;
  if (!props.resumeId && props.documentType !== "coverLetter")
    throw new Error("No resume ID provided");

  if (props.documentType === "coverLetter") {
    return null; // No need to fetch resume data for cover letter
  }

  const response = await DocumentApiService.getUserResume(props.resumeId as string);
  if (response.status !== 200 || !response.data) {
    throw new Error("Failed to fetch resume data");
  }
  return response.data;
}

// Shared function to trigger file download
function triggerFileDownload(blob: Blob, filename: string) {
  const url = window.URL.createObjectURL(blob);
  const link = document.createElement("a");
  link.href = url;
  link.download = filename;
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
  window.URL.revokeObjectURL(url);
  modal.value?.hide();
}

async function downloadAsPdf() {
  loading.value = true;
  try {
    if (props.documentType === "coverLetter") {
      if (!props.documentId) {
        throw new Error("No document ID provided");
      }

      // Get the actual PDF buffer from the API
      const response = await FileApiService.getFileById(props.documentId, true);

      if (response.status !== 200) {
        throw new Error("Failed to fetch the file data.");
      }

      // Create blob from the actual buffer data
      const blob = new Blob([response.data], { type: "application/pdf" });
      triggerFileDownload(blob, "CoverLetter.pdf");

      toast.success("PDF downloaded successfully!", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 3000,
      });
      return;
    }

    const resume = await getResumeData();
    if (!resume) {
      throw new Error("Failed to get resume data");
    }

    const template = resume.template || TemplateOptions.RESUME_CLASSIC;

    const result = await FileApiService.getPDFPreview(
      new ResponseDto(
        null,
        "",
        "",
        resume.userId,
        false,
        ResponseType.CreateResume,
        null,
        0.7,
        Tone.FORMAL,
        template,
        "",
        resume as unknown as Record<string, unknown>,
        null,
        null
      )
    );

    if (result.status !== 200) {
      throw new Error("Failed to generate PDF");
    }

    const blob = new Blob([result.data], { type: "application/pdf" });
    triggerFileDownload(blob, `${resume.name || "Resume"}.pdf`);

    toast.success("PDF downloaded successfully!", {
      position: toast.POSITION.TOP_RIGHT,
      autoClose: 3000,
    });
  } catch (error) {
    console.error("Error downloading PDF:", error);
    toast.error("Failed to download PDF. Please try again.", {
      position: toast.POSITION.TOP_RIGHT,
      autoClose: 3000,
    });
  } finally {
    loading.value = false;
  }
}

// Handle Word document download based on premium status
function handleWordDownload() {
  if (!isPremiumUser.value) {
    showUpgradeModal();
    return;
  }

  downloadAsWord();
}

// Show the upgrade modal
function showUpgradeModal() {
  modal.value?.hide();
  showSubscribeModal.value = true;
  document.body.style.overflow = "hidden"; // Prevent scrolling when modal is open
}

// Close the subscription modal
function closeSubscribeModal() {
  showSubscribeModal.value = false;
  document.body.style.overflow = ""; // Re-enable scrolling
}

async function downloadAsWord() {
  loading.value = true;
  try {
    if (props.documentType === "coverLetter") {
      if (!props.documentId) {
        throw new Error("No document ID provided");
      }

      const result = await FileApiService.getFileById(props.documentId, false);
      if (result.status != 200) {
        throw new Error("There has been a problem downloading this document");
      }

      const blob = new Blob([result.data], {
        type: "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
      });
      triggerFileDownload(blob, "CoverLetter.docx");

      toast.success("Word document downloaded successfully!", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 3000,
      });
      return;
    }

    const resume = await getResumeData();
    if (!resume) {
      throw new Error("Failed to get resume data");
    }

    const template = resume.template || TemplateOptions.RESUME_CLASSIC;

    const result = await ResumeGeneratorService.generateWordDocument(resume, template);
    if (result.status !== 200 || !result.data?.fileId) {
      throw new Error("Failed to generate document");
    }

    const wordDocResult = await ResumeGeneratorService.downloadWordDocument(result.data.fileId);
    if (wordDocResult.status !== 200) {
      throw new Error("Failed to download document");
    }

    const blob = new Blob([wordDocResult.data], {
      type: "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
    });
    triggerFileDownload(blob, `${resume.name || "Resume"}.docx`);

    toast.success("Word document downloaded successfully!", {
      position: toast.POSITION.TOP_RIGHT,
      autoClose: 3000,
    });
  } catch (error) {
    console.error("Error downloading Word document:", error);
    toast.error("Failed to download Word document. Please try again.", {
      position: toast.POSITION.TOP_RIGHT,
      autoClose: 3000,
    });
  } finally {
    loading.value = false;
  }
}

// Expose methods to parent
defineExpose({
  show: () => modal.value?.show(),
  hide: () => modal.value?.hide(),
});
</script>

<style scoped>
.download-container {
  padding: 1.5rem;
  padding-top: 0;
  min-height: 300px;
}

.download-header {
  text-align: center;
  margin-bottom: 2rem;
}

.download-title {
  font-weight: 600;
  margin-bottom: 0.5rem;
  color: #2c3e50;
}

.download-subtitle {
  color: #7f8c8d;
  font-size: 0.9rem;
  margin-bottom: 0;
}

.download-options {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.download-option {
  display: flex;
  align-items: center;
  padding: 1.25rem;
  border-radius: 12px;
  background-color: #f8f9fa;
  transition: all 0.2s ease;
  cursor: pointer;
  position: relative;
  border: 1px solid transparent;
}

.download-option:hover {
  background-color: #f1f3f5;
  transform: translateY(-2px);
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.05);
  border-color: #e9ecef;
}

.download-option.disabled {
  opacity: 0.7;
  cursor: not-allowed;
}

.download-option.disabled:hover {
  transform: none;
  box-shadow: none;
}

.option-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50px;
  height: 50px;
  border-radius: 10px;
  margin-right: 1rem;
  font-size: 1.5rem;
}

.pdf-icon {
  background-color: rgba(220, 53, 69, 0.1);
  color: #dc3545;
}

.word-icon {
  background-color: rgba(13, 110, 253, 0.1);
  color: #0d6efd;
}

.txt-icon {
  background-color: rgba(108, 117, 125, 0.1);
  color: #6c757d;
}

.option-content {
  flex: 1;
}

.option-content h5 {
  margin: 0;
  font-size: 1rem;
  font-weight: 600;
}

.option-content p {
  margin: 0;
  font-size: 0.85rem;
  color: #6c757d;
}

.coming-soon-badge {
  position: absolute;
  top: 10px;
  right: 10px;
  background-color: #6c757d;
  color: white;
  font-size: 0.7rem;
  padding: 0.2rem 0.5rem;
  border-radius: 20px;
  font-weight: 600;
}

.premium-badge {
  background-color: #ffc107;
  color: #212529;
  font-size: 0.7rem;
  padding: 0.1rem 0.4rem;
  border-radius: 20px;
  font-weight: 600;
  margin-left: 0.5rem;
  display: inline-block;
  vertical-align: middle;
}

.premium-option {
  background-color: #f8f9fa;
  position: relative;
  overflow: hidden;
}

.premium-option:hover {
  background-color: #fff8e6;
  transform: translateY(-2px);
  box-shadow: 0 4px 12px rgba(255, 193, 7, 0.15);
  border-color: #ffc107;
}

.upgrade-button {
  margin-top: 0.5rem;
  background-color: #ffc107;
  color: #212529;
  border: none;
  padding: 0.25rem 0.75rem;
  border-radius: 4px;
  font-size: 0.8rem;
  font-weight: 600;
  cursor: pointer;
  transition: all 0.2s ease;
}

.upgrade-button:hover {
  background-color: #e0a800;
}

.loading-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 200px;
}

.spinner-container {
  margin-bottom: 1rem;
}

.spinner {
  width: 40px;
  height: 40px;
  border: 3px solid rgba(0, 0, 0, 0.1);
  border-radius: 50%;
  border-top-color: #0d6efd;
  animation: spin 1s ease-in-out infinite;
}

.loading-text {
  color: #6c757d;
  font-size: 0.9rem;
}

/* Modal backdrop and container styles */
.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1050;
}

.subscription-modal-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1051;
  overflow-y: auto;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}
</style>
