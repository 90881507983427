<template>
  <div class="ai-resume-editor">
    <!-- Add Breadcrumb Navigation -->
    <BreadcrumbNav
      :back-link="`/application/${route.params.applicationId}`"
      back-text="Application"
      current-page="Resume Editor"
    />

    <!-- Main Layout -->
    <div class="container-fluid resume-content p-0">
      <div class="row h-100 content-container">
        <!-- Main Content - Resume List and Preview -->
        <div class="col-md-8 preview-panel p-0">
          <!-- Resume List when no resume is selected -->
          <ResumeList
            v-if="!selectedResumeId"
            :resumes="userResumes"
            :loading="loadingResumes"
            :templates="ResumeTemplates"
            @select="handleResumeSelect"
          />

          <!-- Resume Preview when a resume is selected -->
          <div v-else class="resume-preview-container">
            <!-- Document Preview Area -->
            <div class="preview-content">
              <!-- Loading State -->

              <div v-if="currentResponse" class="preview-with-suggestions">
                <!-- PDF Preview -->
                <div v-if="pdfBuffer" class="pdf-viewer">
                  <div class="pdf-content d-flex justify-content-center">
                    <div class="pdf-page position-relative">
                      <VuePdfEmbed
                        :source="pdfBuffer"
                        :width="pdfWidth"
                        :text-layer="true"
                        :annotation-layer="true"
                        :height="getPdfHeight()"
                        class="pdf-embed"
                      >
                        <template #after-page>
                          <div style="height: 32px"></div>
                        </template>
                      </VuePdfEmbed>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- Right Panel -->
        <div class="col-md-4 chat-panel-container">
          <div class="chat-panel">
            <!-- Add score section -->
            <ResumeScorePanel
              v-if="selectedResumeId"
              :score="resumeScore"
              :keywords-score="keywordsScore"
              :experience-score="experienceScore"
              :skills-score="skillsScore"
              :updating="updatingScores"
            />

            <!-- AI Suggestions Panel -->
            <ResumeSuggestionsPanel
              v-if="selectedResumeId && aiSuggestions.length"
              :suggestions="aiSuggestions"
              :resume="currentResponse?.schema"
              @accept="handleAcceptSuggestion"
              @reject="handleRejectSuggestion"
              @refresh="handleRefreshSuggestion"
              @highlight-text="handleHighlightText"
            >
              <template #before-suggestions>
                <div class="text-muted small px-3 py-2">Suggested change</div>
              </template>
            </ResumeSuggestionsPanel>

            <!-- Empty state when no resume is selected -->
            <EmptyStatePanel
              v-if="!selectedResumeId"
              icon="bi bi-file-earmark-text"
              title="Select a resume to start"
              description="Choose a resume to view and get AI-powered suggestions"
            />

            <!-- Empty state when no suggestions are available -->
            <EmptyStatePanel
              v-else-if="selectedResumeId && !aiSuggestions.length"
              icon="bi bi-lightbulb"
              title="No suggestions yet"
              description="Click generate suggestions to get started"
              button-text="Generate Suggestions"
              :loading="loading"
              :loading-text="loadingText"
              @action="generateAISuggestions"
            />
          </div>
        </div>
      </div>
    </div>

    <!-- Modals -->
    <Modal ref="errorModal" title="Error">
      <template #body>
        <p>{{ errorMessage }}</p>
      </template>
    </Modal>

    <Modal ref="resumeSelectionModal" title="" id="resumeSelectionModal">
      <template #body>
        <div class="resume-selection-content p-2">
          <h4 class="text-center mb-4">Resume Options</h4>
          <p class="text-muted text-center mb-4">
            Choose whether to modify the original resume or create a new copy to preserve the
            original.
          </p>
          <div class="resume-options">
            <button class="btn btn-outline-primary w-100 mb-3" @click="editOriginalResume">
              <i class="bi bi-pencil me-2"></i>
              Modify Original Resume
            </button>
            <button class="btn btn-primary w-100" @click="createResumeCopy">
              <i class="bi bi-files me-2"></i>
              Create a New Copy
            </button>
          </div>
        </div>
      </template>
    </Modal>

    <Modal ref="downloadModal" title="Download Options">
      <template #body>
        <div class="download-options">
          <button class="btn btn-outline-primary w-100 mb-2" @click="downloadAsPdf">
            <i class="bi bi-file-pdf me-2"></i>
            Download as PDF
          </button>
          <button class="btn btn-outline-primary w-100" @click="downloadAsWord">
            <i class="bi bi-file-word me-2"></i>
            Download as Word
          </button>
        </div>
      </template>
    </Modal>

    <!-- Add highlight overlay that will be positioned absolutely -->
    <div ref="highlightOverlay" class="highlight-overlay" v-if="highlightedText"></div>

    <!-- Add PDF Debug Panel -->
    <!-- <PdfDebugPanel v-if="pdfBuffer && isDevelopmentMode" /> -->
  </div>
</template>

<script setup lang="ts">
import { ref, onMounted, computed } from "vue";
import VuePdfEmbed from "vue-pdf-embed";
import Modal from "@/components/Modal.vue";
import { useRoute } from "vue-router";
import { toast } from "vue3-toastify";
import { ResumeTemplates } from "@/data/Templates";
import { FileApiService } from "@/services/FilesApiService";
import BreadcrumbNav from "@/components/BreadcrumbNav.vue";
import { DocumentApiService } from "@/services/DocumentApiService";
import { ApplicationApiService } from "@/services/ApplicationApiService";
// Import server types directly
import { ResponseDto, TemplateOptions } from "../../../server/src/models/dtos/ResponseDtos";
import { ResponseType } from "../../../server/src/models/Response";
import { Tone } from "../../../server/src/models/SystemEnums";
import type { Resume, Project, Achievement } from "../../../server/src/models/Resume";
import type { ResumeListItem } from "../../../server/src/models/dtos/ResumesList";
import { type ATSScoreResult } from "@models/dtos/ATSScoreResult";

// Import our components with relative paths
import ResumeList from "../components/resume-editor/ResumeList.vue";
import ResumeScorePanel from "../components/resume-editor/ResumeScorePanel.vue";
import ResumeSuggestionsPanel from "../components/resume-editor/ResumeSuggestionsPanel.vue";
import EmptyStatePanel from "../components/resume-editor/EmptyStatePanel.vue";
import PdfDebugPanel from "@/components/PdfDebugPanel.vue";

// Import utilities
import { highlightTextInPdf, clearHighlights } from "@/utils/pdfHighlighter";
import {
  calculateScores as calculateSuggestionScores,
  transformSuggestions,
} from "@/services/SuggestionService";

// Import ATS Score Service
import { ATSScoreService } from "@/services/ATSScoreApiService";

// Import our composables
import { useResumeEditor } from "@/composables/useResumeEditor";
import { usePdfHandling } from "@/composables/usePdfHandling";
import {
  AI_RESUME_COMMANDS,
  ResumeSuggestion,
} from "../../../server/src/models/dtos/ResumeSuggestionsOutput";

const route = useRoute();
const errorModal = ref();
const downloadModal = ref();
const resumeSelectionModal = ref();

// State
const loading = ref(false);
const loadingText = ref("");
const pdfBuffer = ref("");
const pdfWidth = ref(700);
const errorMessage = ref("");
const currentResponse = ref<any | null>(null);
const selectedTemplate = ref(TemplateOptions.RESUME_CLASSIC);
const userResumes = ref<ResumeListItem[]>([]);
const selectedResumeId = ref<string | null>(null);
const application = ref<any | null>(null);
const textOverlays = ref<
  Array<{ id: string; left: number; top: number; width: number; height: number }>
>([]);
const pdfDoc = ref(null);
const aiSuggestions = ref<ResumeSuggestion[]>([]);
const atsScore = ref<ATSScoreResult | null>(null);
const highlightedText = ref<string | null>(null);
const updatingScores = ref(false);
const pendingResumeSelection = ref<ResumeListItem | null>(null);

// ATS scoring parameters
const jobTitle = ref<string>("");
const requiredExperience = ref<string>("");
const requiredSkills = ref<string[]>([]);
const keywords = ref<Array<{ keyword: string; weight: number }>>([]);
const atsParametersLoaded = ref(false);

//Computed
const resumeScore = computed(() => {
  if (!atsScore.value) {
    return 0;
  }
  return Math.round(atsScore.value?.overallScore * 100) || 0;
});

const keywordsScore = computed(() => {
  if (!atsScore.value) {
    return 0;
  }
  return Math.round(atsScore.value?.keywordsScore * 100) || 0;
});
const experienceScore = computed(() => {
  if (!atsScore.value) {
    return 0;
  }
  return Math.round(atsScore.value?.experienceScore * 100) || 0;
});
const skillsScore = computed(() => {
  if (!atsScore.value) {
    return 0;
  }
  return Math.round(atsScore.value?.skillsScore * 100) || 0;
});

// Add a ref for the highlight overlay
const highlightOverlay = ref<HTMLDivElement | null>(null);

// Get composable functionality
const { getPdfHeight, getPdfBuffer } = usePdfHandling({
  pdfWidth,
  DocumentApiService,
  FileApiService,
  ResponseDto,
  ResponseType,
  Tone,
});

const { recalculateScores, refreshPdfPreview, applySuggestionChange } = useResumeEditor({
  selectedResumeId,
  aiSuggestions,
  resumeScore,
  keywordsScore,
  experienceScore,
  skillsScore,
  currentResponse,
  pdfBuffer,
  calculateSuggestionScores,
  getPdfBuffer,
});

// Check if we're in development mode
const isDevelopmentMode = process.env.NODE_ENV === "development";

// Methods for downloading documents
function downloadDocument() {
  downloadModal.value.show();
}

async function downloadAsPdf() {
  if (!currentResponse.value || !currentResponse.value.fileId) {
    errorMessage.value = "No document to download";
    errorModal.value.show();
    return;
  }

  try {
    const response = await FileApiService.getFileById(currentResponse.value.fileId, true);

    if (response.status !== 200) {
      throw new Error("Failed to fetch the file data.");
    }

    const blob = new Blob([response.data], { type: "application/pdf" });
    const url = window.URL.createObjectURL(blob);

    const link = document.createElement("a");
    link.href = url;
    link.download = "Resume.pdf";
    document.body.appendChild(link);
    link.click();

    document.body.removeChild(link);
    window.URL.revokeObjectURL(url);
    downloadModal.value.hide();
  } catch (error) {
    console.error("Error downloading PDF:", error);
    errorMessage.value = "Failed to download PDF";
    errorModal.value.show();
  }
}

async function downloadAsWord() {
  if (!currentResponse.value?.fileId) {
    return;
  }

  try {
    const result = await FileApiService.getFileById(currentResponse.value.fileId, false);
    if (result.status !== 200) {
      toast.error("There has been a problem downloading this document");
      return;
    }
    const blob = new Blob([result.data], { type: "application/msword" });
    const url = window.URL.createObjectURL(blob);
    const filename = "Resume.docx";

    const downloadLink = document.createElement("a");
    document.body.appendChild(downloadLink);
    downloadLink.href = url;
    downloadLink.download = filename;
    downloadLink.click();
    document.body.removeChild(downloadLink);
  } catch (error) {
    console.error("Error downloading Word document:", error);
    errorMessage.value = "Failed to download Word document";
    errorModal.value.show();
  }
}

const loadingResumes = ref(false);
// Resume data handling
async function fetchUserResumes() {
  loadingResumes.value = true;
  try {
    const response = await DocumentApiService.getUserResumes();
    userResumes.value = response.data.map((resume) => ({
      ...resume,
      id: resume.id || crypto.randomUUID(),
      name: resume.name || "Untitled Resume",
      lastModified: resume.lastModified ? new Date(resume.lastModified) : new Date(),
      previewUrl: resume.previewUrl || null,
    }));
  } catch (error) {
    console.error("Error fetching resumes:", error);
    toast.error("Failed to load resumes");
  } finally {
    loadingResumes.value = false;
  }
}

async function handleResumeSelect(resumeId: string) {
  const resume = userResumes.value.find((r) => r.id === resumeId);
  if (resume) {
    // Store the selected resume in the pending selection
    pendingResumeSelection.value = resume;

    // Show the modal to ask the user what they want to do
    resumeSelectionModal.value.show();
  }
}

// Function to handle editing the original resume
async function editOriginalResume() {
  // Hide the modal
  resumeSelectionModal.value.hide();

  // Proceed with loading the original resume
  if (pendingResumeSelection.value) {
    await loadResume(pendingResumeSelection.value);
  }
}

// Function to handle creating a copy of the resume
async function createResumeCopy() {
  // Hide the modal
  resumeSelectionModal.value.hide();

  if (!pendingResumeSelection.value) return;

  try {
    loading.value = true;
    loadingText.value = "Creating a copy of the resume...";
    if (!application.value) {
      const appResponse = await ApplicationApiService.getApplicationById(route.params.applicationId as string);
      application.value = appResponse.data;
    }
    // Use the duplicateResume method to create a copy on the server
    const response = await DocumentApiService.duplicateResume({
      id: pendingResumeSelection.value.id,
      name: `${application.value?.roleTitle} Resume`,
    });

    if (response.data) {
      // Get the new resume data
      const newResumeId = response.data;
      const newResumeResponse = await DocumentApiService.getResumeById(newResumeId);

      if (newResumeResponse.data) {
        // Add the new resume to the list
        const newResume = {
          id: newResumeId,
          name: application.value?.roleTitle || "Untitled Resume (Copy)",
          lastModified: new Date(),
          previewUrl: null,
        };

        userResumes.value.push(newResume);

        // Load the new resume
        await loadResume(newResume);

        toast.success("Resume copy created successfully!");
      }
    }
  } catch (error) {
    console.error("Error creating resume copy:", error);
    toast.error("Failed to create a copy of the resume");
  } finally {
    loading.value = false;
  }
}

// Function to load a resume (extracted from the original handleResumeSelect)
async function loadResume(resume: ResumeListItem) {
  try {
    loading.value = true;
    loadingText.value = "Loading resume...";
    // Set updatingScores to true when loading a resume
    updatingScores.value = true;

    // First load the application
    if (!application.value) {
      const appResponse = await ApplicationApiService.getApplicationById(route.params.applicationId as string);
      application.value = appResponse.data;
    }

    // Load the resume
    selectedResumeId.value = resume.id;
    const response = await DocumentApiService.getResumeById(resume.id);
    if (response.data) {
      currentResponse.value = {
        id: resume.id,
        fileId: resume.id,
        schema: response.data,
        template: response.data.template || TemplateOptions.RESUME_CLASSIC,
      };

      // Update the template if it exists
      if (response.data.template) {
        selectedTemplate.value = response.data.template;
      }

      // Load the PDF preview
      try {
        const blob = await getPdfBuffer(resume.id);
        pdfBuffer.value = window.URL.createObjectURL(blob);

        // If ATS parameters are loaded, calculate the ATS score
        if (atsParametersLoaded.value) {
          await updateATSScores();
        }
      } catch (previewError) {
        console.error("Error loading PDF preview:", previewError);
        toast.error("Failed to load resume preview");
        // Still keep the resume selected even if preview fails
      }
    }

    // Fetch ATS parameters for the application
    await fetchATSParameters();
    await updateATSScores();
  } catch (error) {
    console.error("Error loading resume:", error);
    toast.error("Failed to load resume");
    selectedResumeId.value = null;
  } finally {
    loading.value = false;
    // Set updatingScores to false when done loading a resume
    updatingScores.value = false;
  }
}

// Function to generate AI suggestions based on resume analysis
async function generateAISuggestions() {
  if (!selectedResumeId.value || !application.value) return;

  try {
    loading.value = true;
    loadingText.value = "Analyzing resume...";
    // Set updatingScores to true when starting to generate suggestions
    updatingScores.value = true;

    // First, fetch ATS parameters for the application
    await fetchATSParameters();

    // Make the actual API call to get suggestions
    // Use application._id for the API call
    if (!atsScore.value) {
      toast.error("Failed to fetch job requirements");
      return;
    }
    const response = await DocumentApiService.getResumeSuggestions(
      selectedResumeId.value,
      application.value._id || application.value.id,
      atsScore.value
    );

    // Transform the suggestions to match our UI format using the utility
    aiSuggestions.value = transformSuggestions(response.suggestions, currentResponse.value?.schema);

    // Get ATS scores based on actual resume content instead of suggestion-based scores
    await updateATSScores();

    toast.success("Resume analysis complete!");
  } catch (error) {
    console.error("Error generating AI suggestions:", error);
    toast.error("Failed to analyze resume");
  } finally {
    loading.value = false;
    // Set updatingScores to false when done generating suggestions
    updatingScores.value = false;
  }
}

// Function to fetch ATS parameters for the application
async function fetchATSParameters() {
  if (!application.value || atsParametersLoaded.value) return;

  try {
    const applicationId = application.value._id || application.value.id;
    const response = await ATSScoreService.getATSParametersForApplication(applicationId);

    if (response.data) {
      jobTitle.value = response.data.jobTitle || "";
      requiredExperience.value = response.data.requiredExperience || "";
      requiredSkills.value = response.data.requiredSkills || [];
      keywords.value = response.data.keywords || [];
      atsParametersLoaded.value = true;
    }
  } catch (error) {
    console.error("Error fetching ATS parameters:", error);
    toast.error("Failed to fetch job requirements");
  }
}

// Function to update ATS scores based on resume content
async function updateATSScores() {
  if(!atsParametersLoaded.value) {
    await fetchATSParameters();
  }
  if(!atsParametersLoaded.value)
  {
    console.error('Failed to fetch ATS parameters');
    return;
  }
  if (!selectedResumeId.value || !currentResponse.value?.schema)
    return;

  try {
    loadingText.value = "Calculating ATS score...";
    // Set updatingScores to true when starting the update
    updatingScores.value = true;

    console.log("Sending resume to ATS scoring service with parameters:", {
      jobTitle: jobTitle.value,
      requiredExperience: requiredExperience.value,
      requiredSkillsCount: requiredSkills.value.length,
      keywordsCount: keywords.value.length,
    });

    const response = await ATSScoreService.scoreResume(
      currentResponse.value.schema,
      jobTitle.value,
      requiredExperience.value,
      requiredSkills.value,
      keywords.value
    );

    if (response.data) {
      // Log the raw scores from the API
      console.log("Raw ATS scores from API:", response.data);

      atsScore.value = response.data;

      // Force a UI update by creating a small delay
      await new Promise((resolve) => setTimeout(resolve, 0));
    } else {
      console.warn("ATS score response missing expected data structure:", response.data);
    }
  } catch (error) {
    console.error("Error updating ATS scores:", error);
    // Don't show an error toast here, as we'll fall back to the suggestion-based scores
  } finally {
    // Set updatingScores back to false when the update is complete
    updatingScores.value = false;
    loading.value = false;
  }
}

// Handlers for suggestion actions
async function handleAcceptSuggestion(suggestion: ResumeSuggestion) {
  try {
    handleHighlightText({ suggestion, highlight: false });

    loading.value = true;
    loadingText.value = "Applying suggestion...";
    // Set updatingScores to true when accepting a suggestion
    updatingScores.value = true;

    // Handle different suggestion types using appropriate strategies
    let updatedResume;

    if (
      suggestion.location.includes("additionalSkills") &&
      suggestion.command === AI_RESUME_COMMANDS.ADD_SKILL_GROUP &&
      suggestion.metadata?.skillGroup
    ) {
      updatedResume = await handleSkillGroupSuggestion(suggestion);
    } else if (suggestion.command === AI_RESUME_COMMANDS.EDIT_SUMMARY) {
      updatedResume = await handleSummarySuggestion(suggestion);
    } else if (
      suggestion.location.includes("projects") &&
      [
        AI_RESUME_COMMANDS.EDIT_PROJECT,
        AI_RESUME_COMMANDS.ADD_PROJECT,
        AI_RESUME_COMMANDS.REMOVE_PROJECT,
      ].includes(suggestion.command)
    ) {
      updatedResume = await handleProjectSuggestion(suggestion);
    } else {
      // Regular suggestion handling
      updatedResume = await applySuggestionChange(suggestion);
    }

    // Update UI after successful change
    if (updatedResume) {
      // Update the resume data in memory
      currentResponse.value = {
        ...currentResponse.value,
        schema: updatedResume,
      };

      // Refresh the PDF preview
      await refreshPdfPreview();

      // Remove the accepted suggestion
      aiSuggestions.value = aiSuggestions.value.filter((s) => s.id !== suggestion.id);

      // Update ATS scores based on actual resume content
      await updateATSScores();

      // Show success message
      toast.success(getSuccessMessageForAction(suggestion.command));
    }
  } catch (error) {
    console.error("Error applying suggestion:", error);
    toast.error("Failed to apply suggestion");
  } finally {
    loading.value = false;
    // Set updatingScores to false when done accepting a suggestion
    updatingScores.value = false;
  }
}

// Helper functions for suggestion handling
async function handleSkillGroupSuggestion(suggestion: ResumeSuggestion) {
  const { skillGroup } = suggestion.metadata || {};

  // Ensure we have the resume data to modify
  if (!currentResponse.value?.schema) {
    throw new Error("Resume data is missing");
  }

  // Create or update the additionalSkills array in the resume
  if (!currentResponse.value.schema.additionalSkills) {
    currentResponse.value.schema.additionalSkills = [];
  }

  // Add the new skill group with a UUID
  currentResponse.value.schema.additionalSkills.push({
    skillGroup: skillGroup?.skillGroup || "",
    skillDetails: skillGroup?.skillDetails || [],
    uuid: crypto.randomUUID(), // Generate a new UUID for the skill group
  });

  // Apply the updated resume to the backend
  const updatedResume = await DocumentApiService.updateUserResume({
    ...currentResponse.value.schema,
    id: selectedResumeId.value!,
  });

  return updatedResume;
}

async function handleSummarySuggestion(suggestion: ResumeSuggestion) {
  // Ensure we have the resume data to modify
  if (!currentResponse.value?.schema) {
    throw new Error("Resume data is missing");
  }

  // Make sure we have a metadata object with summary property
  if (!suggestion.metadata) suggestion.metadata = {};
  if (!suggestion.metadata.summary) suggestion.metadata.summary = {};

  // If the user has edited the suggestion, make sure it's in the metadata
  if (suggestion.change) {
    suggestion.metadata.summary.description = suggestion.change;
  }

  // Get the updated summary description from metadata
  const changeToApply = suggestion.metadata.summary.description || suggestion.change;

  // If the summary doesn't have a UUID, generate one
  if (!currentResponse.value.schema.summary.uuid) {
    currentResponse.value.schema.summary.uuid = crypto.randomUUID();
  }

  // Apply the summary change using the DocumentApiService
  return await DocumentApiService.applySuggestion(selectedResumeId.value!, {
    category: "resume",
    change: changeToApply,
    location: `summary:${currentResponse.value.schema.summary.uuid}`,
    type: suggestion.type,
    originalContent: suggestion.originalContent,
    command: suggestion.command,
    metadata: suggestion.metadata,
  });
}

async function handleProjectSuggestion(suggestion: ResumeSuggestion) {
  // Apply the project change using the DocumentApiService
  // The suggestion.location now contains the section path and UUID in format "projects:uuid"
  return await DocumentApiService.applySuggestion(selectedResumeId.value!, {
    category: "resume",
    change: suggestion.change,
    location: suggestion.location,
    type: suggestion.type,
    originalContent: suggestion.originalContent,
    command: suggestion.command,
    metadata: {
      ...suggestion.metadata,
      uuid: suggestion.location?.split(":")[1], // Extract UUID from location
    },
  });
}

function getSuccessMessageForAction(command: AI_RESUME_COMMANDS): string {
  const messages: Record<AI_RESUME_COMMANDS, string> = {
    [AI_RESUME_COMMANDS.ADD_SKILL_GROUP]: "Skill group added successfully!",
    [AI_RESUME_COMMANDS.EDIT_SUMMARY]: "Summary updated successfully!",
    [AI_RESUME_COMMANDS.EDIT_PROJECT]: "Project updated successfully!",
    [AI_RESUME_COMMANDS.ADD_PROJECT]: "Project added successfully!",
    [AI_RESUME_COMMANDS.REMOVE_PROJECT]: "Project removed successfully!",
    [AI_RESUME_COMMANDS.EDIT_WORK_ACHIEVEMENT]: "Work experience updated successfully!",
    [AI_RESUME_COMMANDS.ADD_WORK_ACHIEVEMENT]: "Work experience added successfully!",
    [AI_RESUME_COMMANDS.REMOVE_WORK_ACHIEVEMENT]: "Work experience removed successfully!",
  };

  return messages[command] || "Suggestion applied successfully!";
}

function handleRejectSuggestion(suggestion: ResumeSuggestion) {
  // Remove the rejected suggestion
  aiSuggestions.value = aiSuggestions.value.filter((s) => s.id !== suggestion.id);

  // No need to recalculate scores since we're only using ATS API scores

  toast.info("Suggestion rejected");
}

async function handleRefreshSuggestion(suggestion: ResumeSuggestion) {
  // Implement the logic to regenerate a specific suggestion
  console.log("Refreshing suggestion:", suggestion);

  if (!selectedResumeId.value || !currentResponse.value?.schema) return;

  loading.value = true;
  loadingText.value = "Regenerating suggestion...";

  try {
    // Get the section and item that needs to be refreshed
    const { location, type } = suggestion;

    // Call the API to generate a new suggestion for this specific item
    const newSuggestion = await DocumentApiService.generateSingleSuggestion(
      selectedResumeId.value,
      application.value?._id || application.value?.id,
      location,
      type
    );

    if (newSuggestion) {
      // Transform the new suggestion using our utility
      const transformed = transformSuggestions([newSuggestion], currentResponse.value.schema);

      if (transformed.length > 0) {
        // Find and replace the old suggestion with the new one
        const index = aiSuggestions.value.findIndex(
          (s) => s.location === suggestion.location && s.type === suggestion.type
        );

        if (index !== -1) {
          // Replace the old suggestion with the new one
          aiSuggestions.value.splice(index, 1, transformed[0]);

          // No need to recalculate scores since we're only using ATS API scores

          toast.success("Suggestion refreshed successfully");
        }
      } else {
        toast.error("Failed to process the regenerated suggestion");
      }
    } else {
      toast.error("Failed to regenerate suggestion");
    }
  } catch (error) {
    console.error("Error refreshing suggestion:", error);
    toast.error("Error refreshing suggestion");
  } finally {
    loading.value = false;
  }
}

// This is the handler that is triggered when hovering over a suggestion
async function handleHighlightText(payload: { suggestion: ResumeSuggestion; highlight: boolean }) {
  const { suggestion, highlight } = payload;

  // Always clear existing highlights first
  clearHighlights();

  // If no longer hovering, we're done - just return after clearing
  if (!highlight) {
    return;
  }

  try {
    // Get the text to highlight based on the suggestion type
    const textsToHighlight = await extractTextToHighlight(suggestion);

    if (!textsToHighlight.length) {
      console.error("No text to highlight found");
      return;
    }

    // Determine if this is a deletion highlight based on the suggestion action
    const isDeleteHighlight =
      suggestion.command === AI_RESUME_COMMANDS.REMOVE_WORK_ACHIEVEMENT ||
      suggestion.command === AI_RESUME_COMMANDS.REMOVE_PROJECT ||
      suggestion.command === AI_RESUME_COMMANDS.DELETE_SKILL_GROUP;

    // Highlight each text in the array
    for (const text of textsToHighlight) {
      // Use the utility to highlight the text in the PDF with the appropriate style
      await highlightTextInPdf(text, true, isDeleteHighlight);
    }

    // Add suggested change labels
    addHighlightLabels();
  } catch (error) {
    console.error("Error highlighting text:", error);
  }
}

async function extractTextToHighlight(suggestion: ResumeSuggestion): Promise<string[]> {
  const textsToHighlight: string[] = [];

  if (!suggestion.location || !currentResponse.value?.schema) {
    return [];
  }

  const uuid = suggestion.location;
  const schema = currentResponse.value.schema;

  // Helper function to find an item by UUID in an array, including nested achievements
  const findByUuid = (items: any[] | undefined) => {
    if (!items) return null;

    // First check top level items
    const item = items.find((item: any) => item.uuid === uuid);
    if (item) return item;

    // Then check nested achievements
    for (const item of items) {
      if (item.achievements) {
        const achievement = item.achievements.find((a: Achievement) => a.uuid === uuid);
        if (achievement) {
          return achievement;
        }
      }
    }
    return null;
  };

  // Try to find the item in each possible section
  const project = findByUuid(schema.projects);
  if (project) {
    if ("achievement" in project) {
      // It's a project achievement
      textsToHighlight.push(project.achievement);
    }
  }

  const experience = findByUuid(schema.workExperience);
  if (experience) {
    if ("achievement" in experience) {
      // It's a work experience achievement
      textsToHighlight.push(experience.achievement);
    }
  }

  const education = findByUuid(schema.education);
  if (education) {
    textsToHighlight.push(education.description || "");
  }

  // Check summary section
  if (schema.summary?.uuid === uuid) {
    textsToHighlight.push(schema.summary.description || "");
  }

  const skillGroup = findByUuid(schema.additionalSkills);
  if (skillGroup) {
    textsToHighlight.push(skillGroup.skillGroup);
    textsToHighlight.push(skillGroup.skillDetails.join(", "));
  }

  // If no text was found through UUID matching, try to use the change field
  if (textsToHighlight.length === 0 && suggestion.change) {
    textsToHighlight.push(suggestion.change);
  }

  return textsToHighlight.filter((text) => text.trim().length > 0);
}

function addHighlightLabels() {
  const highlightRects = document.querySelectorAll(".highlight-rect");
  highlightRects.forEach((rect) => {
    const label = document.createElement("div");
    label.textContent = "Suggested change";
    label.style.position = "absolute";

    // Type assertion to HTMLElement to access offsetTop/offsetLeft
    const htmlRect = rect as HTMLElement;
    label.style.top = `${htmlRect.offsetTop - 20}px`;
    label.style.left = `${htmlRect.offsetLeft}px`;

    label.style.fontSize = "10px";
    label.style.color = "#000";
    label.style.background = "rgba(255, 230, 0, 0.9)";
    label.style.padding = "2px 4px";
    label.style.borderRadius = "2px";
    label.style.zIndex = "1000";
    label.style.fontWeight = "500";
    label.style.boxShadow = "0 1px 2px rgba(0,0,0,0.1)";
    label.className = "suggestion-label";

    const pdfContent = document.querySelector(".pdf-content");
    if (pdfContent) {
      pdfContent.appendChild(label);
    }
  });
}

onMounted(async () => {
  // Initialize with empty suggestions
  aiSuggestions.value = [];

  // Fetch user resumes
  await fetchUserResumes();
  
  // Check if we have route parameters for resume and application
  const applicationId = route.params.applicationId as string;
  const resumeId = route.params.resumeId as string | undefined;
  
  if (applicationId) {
    // Load the application data
    try {
      const appResponse = await ApplicationApiService.getApplicationById(applicationId);
      application.value = appResponse.data;
    } catch (error) {
      console.error("Error loading application:", error);
      toast.error("Failed to load application data");
    }
    
    if (resumeId) {
      // Find the resume in the user's resumes
      const resume = userResumes.value.find((r) => r.id === resumeId);
      if (resume) {
        // Load the resume
        await loadResume(resume);
      } else {
        toast.warning("Resume not found, creating a new one");
        // Create a new resume if the specified one doesn't exist
        await createNewResume();
      }
    } else {
      // No resumeId provided, create a new resume
      await createNewResume();
    }
    
    // Fetch ATS parameters for the application
    await fetchATSParameters();
  }
});

// Function to create a new resume
async function createNewResume() {
  try {
    // Create a new resume with default values
    const newResume: Resume = {
      id: "",
      userId: "",
      name: application.value?.roleTitle ? `Resume for ${application.value.roleTitle}` : "New Resume",
      summary: "",
      contact: {
        name: "",
        email: "",
        phone: "",
        location: "",
        links: []
      },
      education: [],
      experience: [],
      skills: [],
      projects: [],
      certifications: [],
      languages: [],
      customSections: []
    };

    // Save the new resume to get an ID
    const response = await DocumentApiService.createResume(newResume);
    if (response.data) {
      // Load the newly created resume
      newResume.id = response.data.id;
      await loadResume(newResume);
      toast.success("Created a new resume");
    }
  } catch (error) {
    console.error("Error creating new resume:", error);
    toast.error("Failed to create a new resume");
  }
}
</script>

<style scoped>
.chat-panel-container {
  padding: 1.5rem 1.5rem 1.5rem 0;
}

.ai-resume-editor {
  height: 100vh;
  display: flex;
  flex-direction: column;
  background: #f8f9fa;
}

.resume-content {
  flex: 1;
  display: flex;
  flex-direction: column;
  height: calc(100vh - 56px);
  padding: 1.5rem 1.5rem 1.5rem 0;
}

.row {
  flex: 1;
  margin: 0;
}

.preview-panel {
  height: 100%;
  background: #f8f9fa;
  position: relative;
  padding: 0;
  margin-left: -1.5rem;
  overflow: visible;
}

.col-md-4 {
  padding: 1.5rem 1.5rem 1.5rem 0;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.resume-preview-container {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.preview-content {
  flex: 1;
  overflow-y: auto;
  background: #f8f9fa;
}

.chat-panel {
  background: white;
  border-radius: 12px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.08);
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.loading-overlay {
  position: absolute;
  inset: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  backdrop-filter: blur(4px);
}

/* PDF Viewer Styles */
.pdf-viewer {
  height: 100%;
  display: flex;
  flex-direction: column;
  background: #f8f9fa;
  overflow: visible;
  padding-left: 1rem;
}

.pdf-content {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0;
  margin: 0;
  min-height: 100%;
  overflow-y: auto;
}

.pdf-page {
  width: 100%;
  height: auto;
  margin: 0;
  padding: 0;
  overflow: visible;
  position: relative;
}

.pdf-embed {
  padding: 1.6rem;
  padding-right: 1rem;
  overflow-y: auto;
  height: 100%;
  max-height: calc(100vh - 20px);
  margin-bottom: 1.6rem;
  display: flex;
  flex-direction: column;
  align-items: center;
}

div .vue-pdf-embed__page {
  padding: 25px;
  margin: 0 0 1rem 0;
  background: white;
  display: flex;
  justify-content: center;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
}

.pdf-content::-webkit-scrollbar {
  width: 8px;
}

.pdf-content::-webkit-scrollbar-track {
  background: #f8f9fa;
}

.pdf-content::-webkit-scrollbar-thumb {
  background: #dee2e6;
  border-radius: 4px;
}

.pdf-content::-webkit-scrollbar-thumb:hover {
  background: #ced4da;
}

.download-options {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.download-options button {
  transition: all 0.3s ease;
  padding: 0.75rem 1rem;
  font-weight: 500;
  position: relative;
  overflow: hidden;
}

.download-options button::before {
  content: "";
  position: absolute;
  top: 0;
  left: -100%;
  width: 100%;
  height: 100%;
  background: linear-gradient(
    90deg,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 0.2) 50%,
    rgba(255, 255, 255, 0) 100%
  );
  transition: all 0.6s ease;
}

.download-options button:hover::before {
  left: 100%;
}

.download-options button:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.resume-options {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  padding: 0.5rem 0;
}

.resume-options button {
  transition: all 0.3s ease;
  padding: 0.75rem 1rem;
  font-weight: 500;
  position: relative;
  overflow: hidden;
}

.resume-options button::before {
  content: "";
  position: absolute;
  top: 0;
  left: -100%;
  width: 100%;
  height: 100%;
  background: linear-gradient(
    90deg,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 0.2) 50%,
    rgba(255, 255, 255, 0) 100%
  );
  transition: all 0.6s ease;
}

.resume-options button:hover::before {
  left: 100%;
}

.resume-options button:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.resume-options i {
  font-size: 1.1rem;
}

.resume-selection-content {
  padding: 1.5rem !important;
  border-radius: 12px;
  background: linear-gradient(to bottom, #ffffff, #f8f9fa);
}

.resume-selection-content h4 {
  font-weight: 600;
  color: #2c3e50;
  position: relative;
  padding-bottom: 12px;
}

.resume-selection-content h4::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 40px;
  height: 3px;
  background: linear-gradient(to right, #4a6cf7, #3b5de7);
  border-radius: 3px;
}

.resume-selection-content p {
  color: #6c757d;
  font-size: 0.95rem;
  line-height: 1.5;
}

/* Add styles for the highlighted text */
.highlighted-text {
  background-color: rgba(255, 230, 0, 0.4);
  border-radius: 2px;
  transition: background-color 0.2s ease;
  animation: pulse-highlight 1.5s infinite;
  box-shadow: 0 0 2px rgba(255, 180, 0, 0.8);
  position: relative;
  z-index: 50;
}

@keyframes pulse-highlight {
  0% {
    background-color: rgba(255, 230, 0, 0.3);
  }
  50% {
    background-color: rgba(255, 230, 0, 0.8);
  }
  100% {
    background-color: rgba(255, 230, 0, 0.3);
  }
}

.highlight-rect {
  position: absolute;
  background-color: rgba(255, 230, 0, 0.4);
  border: 2px solid rgba(255, 180, 0, 0.8);
  border-radius: 4px;
  pointer-events: none;
  z-index: 999;
  animation: pulse-highlight 1.5s infinite;
}

@keyframes pulse-highlight {
  0% {
    background-color: rgba(255, 230, 0, 0.3);
    box-shadow: 0 0 5px rgba(255, 180, 0, 0.4);
  }
  50% {
    background-color: rgba(255, 230, 0, 0.6);
    box-shadow: 0 0 10px rgba(255, 180, 0, 0.7);
  }
  100% {
    background-color: rgba(255, 230, 0, 0.3);
    box-shadow: 0 0 5px rgba(255, 180, 0, 0.4);
  }
}
</style>

<!-- Add global styles that won't be scoped -->
<style>
/* Add styles for the highlighted text - GLOBAL STYLES (not scoped) */
.textLayer span.highlighted-text {
  background-color: rgba(255, 230, 0, 0.6) !important;
  border-radius: 2px !important;
  transition: background-color 0.2s ease !important;
  animation: pulse-highlight 1.5s infinite !important;
  box-shadow: 0 0 5px rgba(255, 180, 0, 0.8) !important;
  position: relative !important;
  z-index: 50 !important;
  color: black !important;
  display: inline-block !important;
  padding: 0 2px !important;
  border: 1px solid orange !important;
}

@keyframes pulse-highlight {
  0% {
    background-color: rgba(255, 230, 0, 0.4) !important;
  }
  50% {
    background-color: rgba(255, 230, 0, 0.8) !important;
  }
  100% {
    background-color: rgba(255, 230, 0, 0.4) !important;
  }
}

/* Add styles for PDF text highlighting */
.pdf-highlighted-text {
  background-color: rgba(255, 230, 0, 0.4) !important;
  border-radius: 2px !important;
  border: 1px solid orange !important;
  position: relative !important;
  z-index: 50 !important;
  padding: 0 2px !important;
  animation: pulse-highlight 1.5s infinite !important;
}

/* Modal animation styles */
.modal-dialog {
  transform: translateY(0);
  transition: transform 0.3s ease-out;
}

.modal.fade .modal-dialog {
  transform: translateY(-20px);
}

.modal.show .modal-dialog {
  transform: translateY(0);
}

.modal-content {
  box-shadow: 0 5px 30px rgba(0, 0, 0, 0.15);
  border: none;
  border-radius: 16px;
  overflow: hidden;
}

/* Custom styling for the resume selection modal */
#resumeSelectionModal .modal-dialog {
  max-width: 450px;
}

#resumeSelectionModal .modal-header,
#resumeSelectionModal .modal-footer {
  display: none !important;
}

#resumeSelectionModal .modal-body {
  padding: 0 !important;
}

#resumeSelectionModal .btn {
  border-radius: 8px;
  font-weight: 500;
  letter-spacing: 0.3px;
}

#resumeSelectionModal .btn-primary {
  background: linear-gradient(135deg, #4a6cf7, #3b5de7);
  border: none;
  box-shadow: 0 4px 12px rgba(59, 93, 231, 0.2);
}

#resumeSelectionModal .btn-primary:hover {
  background: linear-gradient(135deg, #3b5de7, #2a4cd7);
  box-shadow: 0 6px 15px rgba(59, 93, 231, 0.3);
}

#resumeSelectionModal .btn-outline-primary {
  border-color: #4a6cf7;
  color: #4a6cf7;
}

#resumeSelectionModal .btn-outline-primary:hover {
  background-color: rgba(74, 108, 247, 0.05);
  color: #3b5de7;
}
</style>
