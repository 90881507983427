<template>
  <div class="suggestions-panel">
    <!-- Display all suggestions in a flat list -->
    <div v-if="suggestions.length > 0" class="suggestions-list">
      <SuggestionItem
        v-for="suggestion in suggestions"
        :key="suggestion.id"
        :suggestion="suggestion"
        :resume="resume"
        @accept="handleAccept"
        @reject="handleReject"
        @refresh="handleRefresh"
        @highlight-text="emitHighlightText"
        @update:content="updateSuggestionContent"
      />
    </div>

    <!-- Empty state when no suggestions -->
    <div v-else class="no-suggestions">
      <p>No suggestions available</p>
    </div>
  </div>
</template>

<script setup lang="ts">
import { defineProps, defineEmits } from "vue";
import SuggestionItem from "./SuggestionItem.vue";
import { useSuggestionHandlers } from "../../composables/useSuggestionHandlers";
import {
  AI_RESUME_COMMANDS,
  type AddWorkAchievementMetadata,
  type EditWorkAchievementMetadata,
  type ResumeSuggestion,
} from "../../../../server/src/models/dtos/ResumeSuggestionsOutput";

const props = defineProps<{
  suggestions: ResumeSuggestion[];
  resume: {
    additionalSkills?: Array<{
      skillGroup: string;
      skillDetails?: string[];
    }>;
    [key: string]: any;
  } | null;
}>();

const emit = defineEmits<{
  (e: "accept", suggestion: ResumeSuggestion): void;
  (e: "reject", suggestion: ResumeSuggestion): void;
  (e: "refresh", suggestion: ResumeSuggestion): void;
  (e: "highlight-text", payload: { suggestion: ResumeSuggestion; highlight: boolean }): void;
}>();

const { handleAccept, handleReject, handleRefresh } = useSuggestionHandlers(emit);

function emitHighlightText(payload: { suggestion: ResumeSuggestion; highlight: boolean }) {
  emit("highlight-text", payload);
}

function updateSuggestionContent(payload: { suggestion: ResumeSuggestion; content: string }) {
  // Update the suggestion content
  payload.suggestion.change = payload.content;

  // If this is a summary suggestion, update the metadata
  if (
    payload.suggestion.command === AI_RESUME_COMMANDS.ADD_SUMMARY ||
    payload.suggestion.command === AI_RESUME_COMMANDS.EDIT_SUMMARY
  ) {
    if (!payload.suggestion.metadata) payload.suggestion.metadata = {};
    if (!payload.suggestion.metadata.summary) payload.suggestion.metadata.summary = {};
    payload.suggestion.metadata.summary.description = payload.content;
  } else if (
    payload.suggestion.command === AI_RESUME_COMMANDS.ADD_WORK_ACHIEVEMENT ||
    payload.suggestion.command === AI_RESUME_COMMANDS.EDIT_WORK_ACHIEVEMENT ||
    payload.suggestion.command === AI_RESUME_COMMANDS.ADD_PROJECT ||
    payload.suggestion.command === AI_RESUME_COMMANDS.EDIT_PROJECT
  ) {
    const metadata = { achievement: payload.content };
    payload.suggestion.metadata = metadata;
  }
}
</script>

<style>
/* Moving styles to SuggestionItem.vue and a separate CSS file */
.suggestions-panel {
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  padding: 0 0.5rem;
  margin-top: 0.5rem;
}

.suggestions-list {
  flex: 1;
  overflow-y: auto;
  padding-right: 0.5rem;
  width: 100%;
}

.no-suggestions {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  color: #6c757d;
  font-style: italic;
}
</style>
