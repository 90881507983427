import { AI_RESUME_COMMANDS, DeleteSkillGroupMetadata, EditSkillGroupMetadata } from '@models/dtos/ResumeSuggestionsOutput';
import { ResumeSuggestion } from "@models/dtos/ResumeSuggestionsOutput";


export function useSkillSuggestions() {
  // Check if a suggestion is related to skills
  function isSkillSuggestion(suggestion: ResumeSuggestion): boolean {
    if (!suggestion) {
      return false
    }
    // Check if the location contains 'skill' or 'additionalskill'
    return suggestion.command === AI_RESUME_COMMANDS.ADD_SKILL_GROUP ||
      suggestion.command === AI_RESUME_COMMANDS.EDIT_SKILL_GROUP ||
      suggestion.command === AI_RESUME_COMMANDS.DELETE_SKILL_GROUP;
  }

  // Helper function to get skill group data
  function getSkillGroupData(suggestion: ResumeSuggestion): { title: string; skills: string[] } | null {
    if (!suggestion || !suggestion.command) return null;

    const metadata = suggestion.metadata as EditSkillGroupMetadata;
    // Handle ADD_SKILL_GROUP action (for adding new skill groups)
    if (suggestion.command === AI_RESUME_COMMANDS.ADD_SKILL_GROUP && suggestion.metadata) {
      // Handle the structure with skillGroup and skillDetails
      if (metadata.skillGroup) {
        const skillDetails = metadata.skillGroup.skillDetails || [];
        return {
          title: metadata.skillGroup.skillGroup || "Technical Skills",
          skills: skillDetails,
        };
      }
    }

    // For EDIT_SKILL_GROUP actions (both Edit and Remove types)
    if (suggestion.command === AI_RESUME_COMMANDS.EDIT_SKILL_GROUP && suggestion.metadata) {

      const metadata = suggestion.metadata as EditSkillGroupMetadata;
      // Handle the exact API structure with command, groupIndex, and skillGroup
      if (
        metadata.skillGroup &&
         metadata.skillGroup.skillDetails
      ) {
        const skillDetails = metadata.skillGroup.skillDetails;
        return {
          title: metadata.skillGroup.skillGroup || "Technical Skills",
          skills: skillDetails,
        };
      }
      // Fallback to just checking if skillGroup exists in metadata
      else if (metadata.skillGroup) {
        // Extract skillDetails from the nested structure
        const skillDetails = metadata.skillGroup.skillDetails || [];
        return {
          title: metadata.skillGroup.skillGroup || "Technical Skills",
          skills: skillDetails,
        };
      }
    }

    // For other skill-related suggestions, try to parse from change field
    if (suggestion.command === AI_RESUME_COMMANDS.DELETE_SKILL_GROUP) {
      const metadata = suggestion.metadata as DeleteSkillGroupMetadata;
      return {
        title: metadata.skillGroup || "Technical Skills",
        skills: [],
      };
    }

    // If all else fails, return a default set of skills for demonstration
    if (isSkillSuggestion(suggestion)) {
      console.error("No skill group data found for suggestion", suggestion);
    }

    return null;
  }

  // Helper functions for SkillGroup
  function getSkillGroupTitle(suggestion: ResumeSuggestion): string {
    const data = getSkillGroupData(suggestion);
    return data?.title || "Technical Skills";
  }

  function getSkillGroupSkills(suggestion: ResumeSuggestion): string[] {
    const data = getSkillGroupData(suggestion);

    // If we have data and skills from the metadata, use those directly
    if (data?.skills && data.skills.length > 0) {
      return data.skills;
    }

    return ["SQL", "NoSQL", "Git"]; // Default fallback skills
  }

  // Function to update skills in the suggestion metadata
  function updateSkills(suggestion: ResumeSuggestion, newSkills: string[]) {
    if (!suggestion.metadata) {
      suggestion.metadata = {};
    }

    const metadata = suggestion.metadata as EditSkillGroupMetadata;

    // Handle ADD_SKILL_GROUP action for new skill groups
    if (suggestion.command === AI_RESUME_COMMANDS.ADD_SKILL_GROUP && metadata.skillGroup) {
      metadata.skillGroup.skillDetails = newSkills;
    }
    // Update the skills in the metadata based on the structure
    // Handle the exact API structure with command+groupIndex+skillGroup
    else if (suggestion.command === "EDIT_SKILL_GROUP" && metadata.skillGroup) {
      metadata.skillGroup.skillDetails = newSkills;
    }
    // Fallback to simple skillGroup structure
    else if (metadata.skillGroup && metadata.skillGroup.skillDetails != null) {
      metadata.skillGroup.skillDetails = newSkills;
    } else if (metadata.skillGroup.skillDetails == null) {
      metadata.skillGroup.skillDetails = newSkills;
    } else {
      // Create the structure if it doesn't exist
      // Use ADD_SKILL_GROUP for new suggestions, EDIT_SKILL_GROUP for edits
      suggestion.command =
        suggestion.type === "Add" ? AI_RESUME_COMMANDS.ADD_SKILL_GROUP : AI_RESUME_COMMANDS.EDIT_SKILL_GROUP;
      metadata.skillGroup = {
        skillGroup: getSkillGroupTitle(suggestion),
        skillDetails: newSkills,
      };
    }
  }

  // Function to update the title in the suggestion metadata
  function updateTitle(suggestion: ResumeSuggestion, newTitle: string) {

    const metadata = suggestion.metadata as EditSkillGroupMetadata;

    if (!suggestion.metadata) {
      suggestion.metadata = {};
    }

    else if (suggestion.command === "EDIT_SKILL_GROUP" && metadata.skillGroup) {
      metadata.skillGroup.skillGroup = newTitle;
    }
  }

  return {
    isSkillSuggestion,
    getSkillGroupData,
    getSkillGroupTitle,
    getSkillGroupSkills,
    updateSkills,
    updateTitle
  };
} 