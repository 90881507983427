<template>
  <div class="master-detail-layout">
    <!-- List Panel (Left) -->
    <div class="list-panel">
      <div class="section-header">
        <div>
          <h2>Additional Information</h2>
          <p>Add your skills, certifications, and more</p>
        </div>
      </div>
      <div class="item-list">
        <!-- Skills -->
        <div
          class="list-item"
          :class="{ active: selectedSection === 'skills' }"
          @click="selectedSection = 'skills'"
        >
          <i class="bi bi-tools"></i>
          <div class="list-item-content">
            <div class="list-item-title">Skills</div>
            <div class="list-item-subtitle">{{ (skills || []).length }} skill groups</div>
          </div>
        </div>

        <!-- Certifications -->
        <div
          class="list-item"
          :class="{ active: selectedSection === 'certifications' }"
          @click="selectedSection = 'certifications'"
        >
          <i class="bi bi-award"></i>
          <div class="list-item-content">
            <div class="list-item-title">Certifications</div>
            <div class="list-item-subtitle">{{ (certifications || []).length }} certifications</div>
          </div>
        </div>

        <!-- Projects -->
        <div
          class="list-item"
          :class="{ active: selectedSection === 'projects' }"
          @click="selectedSection = 'projects'"
        >
          <i class="bi bi-folder"></i>
          <div class="list-item-content">
            <div class="list-item-title">Projects</div>
            <div class="list-item-subtitle">{{ (projects || []).length }} projects</div>
          </div>
        </div>
      </div>
    </div>

    <!-- Detail Panel (Right) -->
    <div class="detail-panel">
      <!-- Skills -->
      <div v-if="selectedSection === 'skills'" class="section-content">
        <div class="section-header">
          <div class="mb-2">
            <h2>Skills</h2>
            <p>Add your key skills grouped by category</p>
          </div>
          <button class="btn-add" @click="addSkillGroup">
            <i class="bi bi-plus-lg"></i>
            Add Skill Group
          </button>
        </div>

        <VueDraggableNext
          :model-value="skills"
          @update:model-value="$emit('update:skills', $event)"
          group="skills"
          handle=".drag-handle"
          tag="div"
          class="experience-list"
        >
          <div v-for="(skill, index) in skills" :key="skill.uuid" class="experience-card">
            <div class="experience-header">
              <div class="drag-handle">
                <i class="bi bi-grip-vertical"></i>
              </div>
              <input
                v-model="skill.skillGroup"
                type="text"
                placeholder="Skill Category (e.g., Programming Languages)"
                class="title-input"
                @input="emitSkillsUpdate"
              />
              <button class="btn-remove" @click="removeSkillGroup(index)">
                <i class="bi bi-trash"></i>
              </button>
            </div>
            <div class="form-group">
              <div class="pills-input-container">
                <div class="pills-container">
                  <span
                    v-for="(item, skillIndex) in skill.skillDetails || []"
                    :key="skillIndex"
                    class="pill"
                  >
                    {{ item }}
                    <button class="pill-remove" @click="removeSkillItem(index, skillIndex)">
                      <i class="bi bi-x"></i>
                    </button>
                  </span>
                </div>
                <input
                  v-model="skillInputs[index]"
                  type="text"
                  placeholder="Type a skill and press Enter"
                  class="form-control pill-input"
                  @keydown.enter.prevent="addSkillItem(index)"
                  @keydown.backspace="handleSkillBackspace(index)"
                />
              </div>
            </div>
          </div>
        </VueDraggableNext>
      </div>

      <!-- Certifications -->
      <div v-else-if="selectedSection === 'certifications'" class="section-content">
        <div class="section-header">
          <div class="mb-2">
            <h2>Certifications</h2>
            <p>Add your professional certifications and qualifications</p>
          </div>
          <button class="btn-add" @click="addCertification">
            <i class="bi bi-plus-lg"></i>
            Add Certification
          </button>
        </div>

        <VueDraggableNext
          :model-value="certifications"
          @update:model-value="$emit('update:certifications', $event)"
          group="certifications"
          handle=".drag-handle"
          tag="div"
          class="experience-list"
        >
          <div v-for="(cert, index) in certifications" :key="cert.uuid" class="experience-card">
            <div class="experience-header">
              <div class="drag-handle">
                <i class="bi bi-grip-vertical"></i>
              </div>
              <input
                v-model="cert.name"
                type="text"
                placeholder="Certification Name"
                class="title-input"
                @input="emitCertificationsUpdate"
              />
              <button class="btn-remove" @click="removeCertification(index)">
                <i class="bi bi-trash"></i>
              </button>
            </div>

            <div class="form-grid">
              <div class="form-group">
                <label>Issuing Organization</label>
                <input
                  v-model="cert.issuedBy"
                  type="text"
                  class="form-control"
                  placeholder="e.g., AWS, Microsoft, Google"
                  @input="emitCertificationsUpdate"
                />
              </div>
              <div class="form-group">
                <label>Issue Date</label>
                <input
                  v-model="cert.issueDate"
                  type="text"
                  class="form-control"
                  placeholder="e.g., June 2023"
                  @input="emitCertificationsUpdate"
                />
              </div>
            </div>
          </div>
        </VueDraggableNext>
      </div>

      <!-- Projects -->
      <div v-else-if="selectedSection === 'projects'" class="section-content">
        <div class="section-header">
          <div class="mb-2">
            <h2>Projects</h2>
            <p>Showcase your key projects</p>
          </div>
          <button class="btn-add" @click="addProject">
            <i class="bi bi-plus-lg"></i>
            Add Project
          </button>
        </div>

        <VueDraggableNext
          :model-value="projects"
          @update:model-value="$emit('update:projects', $event)"
          group="projects"
          handle=".drag-handle"
          tag="div"
          class="experience-list"
        >
          <div v-for="(project, index) in projects" :key="project.uuid" class="experience-card">
            <div class="experience-header">
              <div class="drag-handle">
                <i class="bi bi-grip-vertical"></i>
              </div>
              <input
                v-model="project.name"
                type="text"
                class="title-input"
                placeholder="Project Name"
                @input="emitProjectsUpdate"
              />
              <button class="btn-remove" @click="removeProject(index)">
                <i class="bi bi-trash"></i>
              </button>
            </div>

            <div class="form-grid">
              <div class="form-group">
                <label>Dates</label>
                <input
                  v-model="project.dates"
                  type="text"
                  class="form-control"
                  placeholder="e.g., Feb 2023 - Present"
                  @input="emitProjectsUpdate"
                />
              </div>
              <div class="form-group col-span-2">
                <label>Achievements</label>
                <div class="achievements-list">
                  <VueDraggableNext
                    v-model="project.achievements"
                    group="achievements"
                    handle=".drag-handle"
                    tag="div"
                    @change="emitProjectsUpdate"
                  >
                    <div
                      v-for="(achievement, achievementIndex) in project.achievements"
                      :key="achievement.uuid"
                      class="achievement-item"
                    >
                      <div class="drag-handle">
                        <i class="bi bi-grip-vertical"></i>
                      </div>
                      <span class="bullet-point">•</span>
                      <textarea
                        v-model="achievement.achievement"
                        v-auto-resize
                        class="form-control"
                        rows="1"
                        placeholder="Describe a key achievement or responsibility..."
                        @input="emitProjectsUpdate"
                      ></textarea>
                      <button
                        class="btn-remove-small"
                        @click="removeAchievement(index, achievementIndex)"
                      >
                        <i class="bi bi-trash"></i>
                      </button>
                    </div>
                  </VueDraggableNext>
                  <button class="btn-add-achievement" @click="addAchievement(index)">
                    <i class="bi bi-plus-lg"></i>
                    Add Achievement
                  </button>
                </div>
              </div>
            </div>
          </div>
        </VueDraggableNext>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref, watch } from "vue";
import { VueDraggableNext } from "vue-draggable-next";
import type { AdditionalSkill, Certification, Project } from "../../../../server/src/models/Resume";

const props = defineProps<{
  skills: AdditionalSkill[];
  certifications: Certification[];
  projects: Project[];
}>();

const emit = defineEmits<{
  (e: "update:skills", value: AdditionalSkill[]): void;
  (e: "update:certifications", value: Certification[]): void;
  (e: "update:projects", value: Project[]): void;
}>();

const selectedSection = ref("skills");
const skillInputs = ref<{ [key: number]: string }>({});
const techInput = ref("");

// Skills
function addSkillGroup() {
  const newSkills = [...props.skills];
  newSkills.push({
    skillGroup: "",
    skillDetails: [],
    uuid: crypto.randomUUID(),
  });
  emit("update:skills", newSkills);
}

function removeSkillGroup(index: number) {
  const newSkills = [...props.skills];
  newSkills.splice(index, 1);
  emit("update:skills", newSkills);
}

function addSkillItem(index: number) {
  if (!skillInputs.value[index] || !skillInputs.value[index].trim()) return;

  const newSkills = [...props.skills];
  const skill = newSkills[index];
  if (!skill.skillDetails) {
    skill.skillDetails = [];
  }

  skill.skillDetails.push(skillInputs.value[index].trim());
  emit("update:skills", newSkills);
  skillInputs.value[index] = "";
}

function removeSkillItem(groupIndex: number, skillIndex: number) {
  const newSkills = [...props.skills];
  const skill = newSkills[groupIndex];
  if (!skill.skillDetails) return;

  skill.skillDetails.splice(skillIndex, 1);
  emit("update:skills", newSkills);
}

function handleSkillBackspace(index: number) {
  if (!skillInputs.value[index]) {
    const newSkills = [...props.skills];
    const skill = newSkills[index];
    if (!skill.skillDetails?.length) return;

    skill.skillDetails.pop();
    emit("update:skills", newSkills);
  }
}

// Certifications
function addCertification() {
  const newCertifications = [...props.certifications];
  newCertifications.push({
    name: "",
    issuedBy: "",
    issueDate: "",
    uuid: crypto.randomUUID(),
  });
  emit("update:certifications", newCertifications);
}

function removeCertification(index: number) {
  const newCertifications = [...props.certifications];
  newCertifications.splice(index, 1);
  emit("update:certifications", newCertifications);
}

// Projects
function addProject() {
  const newProjects = [...props.projects];
  newProjects.push({
    name: "",
    dates: "",
    achievements: [
      {
        achievement: "",
        uuid: crypto.randomUUID(),
      },
    ],
    uuid: crypto.randomUUID(),
  });
  emit("update:projects", newProjects);
}

function removeProject(index: number) {
  const newProjects = [...props.projects];
  newProjects.splice(index, 1);
  emit("update:projects", newProjects);
}

function addAchievement(projectIndex: number) {
  const newProjects = [...props.projects];
  const project = newProjects[projectIndex];
  if (!project.achievements) {
    project.achievements = [];
  }
  project.achievements.push({
    achievement: "",
    uuid: crypto.randomUUID(),
  });
  emit("update:projects", newProjects);
}

function removeAchievement(projectIndex: number, achievementIndex: number) {
  const newProjects = [...props.projects];
  const project = newProjects[projectIndex];
  if (!project.achievements) return;

  project.achievements.splice(achievementIndex, 1);
  if (project.achievements.length === 0) {
    project.achievements.push({
      achievement: "",
      uuid: crypto.randomUUID(),
    });
  }
  emit("update:projects", newProjects);
}

// Emit updates
function emitSkillsUpdate() {
  emit("update:skills", [...props.skills]);
}

function emitCertificationsUpdate() {
  emit("update:certifications", [...props.certifications]);
}

function emitProjectsUpdate() {
  emit("update:projects", [...props.projects]);
}

// Add to script section after the existing imports
const vAutoResize = {
  mounted: (el: HTMLTextAreaElement) => {
    const resize = () => {
      el.style.height = "auto";
      el.style.height = el.scrollHeight + "px";
    };
    resize();
    el.addEventListener("input", resize);
    el.addEventListener("change", resize);
  },
  updated: (el: HTMLTextAreaElement) => {
    el.style.height = "auto";
    el.style.height = el.scrollHeight + "px";
  },
};
</script>

<style scoped>
.master-detail-layout {
  display: grid;
  grid-template-columns: 300px 1fr;
  gap: 2rem;
  height: 100%;
  min-height: 400px;
  max-height: 100%;
  overflow: hidden;
}

.list-panel {
  border-right: 1px solid #e5e7eb;
  padding: 0 1rem 1rem 0;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  max-height: 100%;
}

.section-header {
  padding: 0;
  margin-bottom: 1rem;
  border-bottom: 1px solid #e5e7eb;
  padding-bottom: 1rem;
  text-align: left;
}

.section-header h2 {
  font-size: 1.25rem;
  margin-bottom: 0.25rem;
  text-align: left;
}

.section-header p {
  font-size: 0.875rem;
  color: #6b7280;
  margin: 0;
  text-align: left;
}

.detail-panel {
  padding: 0;
  border-radius: 12px;
  overflow-y: auto;
  max-height: 100%;
}

.section-content {
  padding: 1rem;
  background: white;
  border-radius: 12px;
}

.item-list {
  flex: 1;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  min-height: 0;
  padding-top: 0.5rem;
}

.list-item {
  display: flex;
  align-items: center;
  gap: 0.75rem;
  padding: 0.875rem 1rem;
  border: 1px solid #e5e7eb;
  border-radius: 8px;
  cursor: pointer;
  background: white;
  transition: all 0.2s ease;
}

.list-item:hover {
  border-color: #0c6efd;
  transform: translateY(-1px);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
}

.list-item.active {
  border-color: #0c6efd;
  background: #f0f7ff;
}

.list-item i {
  font-size: 1.125rem;
  color: #6b7280;
  padding-top: 0.125rem;
}

.list-item-content {
  flex: 1;
  min-width: 0;
}

.list-item-title {
  font-weight: 500;
  color: #111827;
  margin-bottom: 0.25rem;
  font-size: 0.9375rem;
  text-align: left;
}

.list-item-subtitle {
  font-size: 0.8125rem;
  color: #6b7280;
  text-align: left;
}

.experience-list {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
}

.experience-card {
  background: #f9fafb;
  border-radius: 12px;
  padding: 1.5rem;
  border: 1px solid #e5e7eb;
  transition: all 0.2s ease;
}

.experience-card:hover {
  border-color: #0c6efd;
  box-shadow: 0 4px 6px -1px rgba(12, 110, 253, 0.1);
}

.experience-header {
  display: flex;
  align-items: center;
  gap: 0.75rem;
  margin-bottom: 1.25rem;
}

.drag-handle {
  cursor: grab;
  padding: 0.5rem;
  color: #6b7280;
  display: flex;
  align-items: center;
  margin-left: -0.5rem;
}

.drag-handle:active {
  cursor: grabbing;
}

.title-input {
  flex: 1;
  font-size: 1rem;
  font-weight: 500;
  border: none;
  background: transparent;
  color: #111827;
  padding: 0.5rem;
  border-radius: 6px;
  transition: all 0.2s ease;
}

.title-input:focus {
  outline: none;
  background: white;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
}

.btn-add {
  display: inline-flex;
  align-items: center;
  gap: 0.5rem;
  background: #0c6efd;
  color: white;
  border: none;
  padding: 0.625rem 1.25rem;
  border-radius: 8px;
  font-size: 0.875rem;
  font-weight: 500;
  transition: all 0.2s ease;
}

.btn-add:hover {
  background: #0b5ed7;
  transform: translateY(-1px);
}

.btn-add i {
  font-size: 1rem;
}

.btn-remove {
  padding: 0.5rem;
  color: #6b7280;
  background: none;
  border: none;
  border-radius: 6px;
  transition: all 0.2s ease;
  opacity: 0;
}

.experience-card:hover .btn-remove {
  opacity: 1;
}

.btn-remove:hover {
  color: #ef4444;
  background: rgba(239, 68, 68, 0.1);
}

.form-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 1rem;
}

.form-group {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.form-group.col-span-2 {
  grid-column: span 2;
  width: 100%;
}

.form-control {
  padding: 0.75rem;
  border: 1px solid #e5e7eb;
  border-radius: 8px;
  font-size: 0.875rem;
  transition: all 0.2s ease;
  background: #f9fafb;
}

.form-control:focus {
  outline: none;
  border-color: #0c6efd;
  background: white;
  box-shadow: 0 0 0 3px rgba(12, 110, 253, 0.1);
}

label {
  font-size: 0.875rem;
  font-weight: 500;
  color: #374151;
  text-align: left;
  margin-bottom: 0.25rem;
}

.pills-input-container {
  border: 1px solid #e5e7eb;
  border-radius: 8px;
  padding: 0.5rem;
  background: #f9fafb;
  min-height: 42px;
}

.pills-input-container:focus-within {
  border-color: #0c6efd;
  background: white;
  box-shadow: 0 0 0 3px rgba(12, 110, 253, 0.1);
}

.pills-container {
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;
  margin-bottom: 0.5rem;
}

.pill {
  display: inline-flex;
  align-items: center;
  gap: 0.25rem;
  background: #e9ecef;
  padding: 0.25rem 0.75rem;
  border-radius: 16px;
  font-size: 0.875rem;
  color: #495057;
  transition: all 0.2s ease;
}

.pill:hover {
  background: #dee2e6;
}

.pill-remove {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  margin-left: 0.25rem;
  width: 16px;
  height: 16px;
  border: none;
  background: none;
  color: #6c757d;
  border-radius: 50%;
  font-size: 1rem;
  line-height: 1;
  transition: all 0.2s ease;
}

.pill-remove:hover {
  color: #dc3545;
  background: rgba(220, 53, 69, 0.1);
}

.pill-input {
  border: none !important;
  padding: 0.375rem 0.5rem !important;
  background: transparent !important;
  box-shadow: none !important;
  width: 100%;
  min-width: 120px;
  flex: 1;
}

.pill-input:focus {
  outline: none !important;
  box-shadow: none !important;
}

.achievements-list {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: 0.5rem;
  background: #f9fafb;
  border-radius: 8px;
  height: 100%;
}

.achievement-item {
  width: 100%;
  display: flex;
  gap: 1rem;
  margin-bottom: 0.5rem;
  align-items: flex-start;
  padding: 0.5rem;
  border-radius: 6px;
  background: white;
  transition: all 0.2s ease;
}

.achievement-item:hover {
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
}

.achievement-item .bullet-point {
  color: #6b7280;
  font-size: 1.5rem;
  line-height: 1;
}

.achievement-item .form-control {
  flex: 1;
  border: none;
  background: transparent;
  padding: 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  resize: none;
  min-height: 30px;
  overflow: hidden;
}

.achievement-item .form-control:focus {
  outline: none;
  background: white;
  box-shadow: none;
}

.btn-remove-small {
  opacity: 0;
  color: #6b7280;
  padding: 0;
  border: none;
  background: none;
  transition: all 0.2s ease;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1rem;
}

.achievement-item:hover .btn-remove-small {
  opacity: 1;
}

.btn-remove-small:hover {
  color: #ef4444;
}

.btn-add-achievement {
  margin-top: 0.5rem;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  color: #0c6efd;
  background: white;
  border: 1px dashed #e5e7eb;
  padding: 0.75rem;
  border-radius: 6px;
  width: 100%;
  justify-content: center;
  font-size: 0.875rem;
  transition: all 0.2s ease;
}

.btn-add-achievement:hover {
  border-color: #0c6efd;
  background: rgba(12, 110, 253, 0.1);
}
</style>
