<template>
  <div class="application-selector-with-modal">
    <!-- Application Selector -->
    <div class="selector-container">
      <div class="selector-header">
        <div class="icon-container">
          <i class="bi bi-briefcase-fill"></i>
        </div>
        <div class="header-text">
          <h3 class="selector-title">Select Application</h3>
          <p class="selector-description">{{ selectorDescription }}</p>
        </div>
      </div>

      <ApplicationSelector
        :loading="loading"
        :applications="applications"
        @select-application="selectApplication"
        @create-application="showCreateApplicationModal = true"
      />
    </div>

    <!-- Create Application Modal -->
    <Teleport to="body">
      <CreateApplication
        v-if="showCreateApplicationModal"
        :show="showCreateApplicationModal"
        :is-modal="true"
        :resume-id="resumeId"
        @close="showCreateApplicationModal = false"
        @application-created="onApplicationCreated"
      />
    </Teleport>
  </div>
</template>

<script setup lang="ts">
import { ref, onMounted } from "vue";
import { useRouter } from "vue-router";
import ApplicationSelector from "@/components/ApplicationSelector.vue";
import CreateApplication from "@/views/CreateApplication.vue";
import { ApplicationApiService } from "@/services/ApplicationApiService";

interface Application {
  _id: string;
  roleTitle: string;
  companyName: string;
  applicationId: string;
  [key: string]: any;
}

const props = defineProps({
  redirectType: {
    type: String,
    default: "",
  },
  resumeId: {
    type: String,
    default: "",
  },
  redirectRoute: {
    type: String,
    default: "",
  },
  selectorDescription: {
    type: String,
    default: "Select an application",
  },
});

const emit = defineEmits(["application-selected", "application-created"]);

const router = useRouter();
const loading = ref(false);
const applications = ref<Application[]>([]);
const showCreateApplicationModal = ref(false);

// Fetch applications on component mount
onMounted(() => {
  fetchApplicationsList();
});

// Fetch the list of applications
const fetchApplicationsList = async () => {
  loading.value = true;
  try {
    const response = await ApplicationApiService.getUserApplicationList();
    if (response.status === 200 && response.data) {
      // Map the response data to include applicationId if it doesn't exist
      applications.value = response.data.applications.map((app: any) => ({
        ...app,
        applicationId: app.applicationId || app._id,
      }));
    }
  } catch (error) {
    console.error("Error fetching applications:", error);
  } finally {
    loading.value = false;
  }
};

// Select an application
const selectApplication = (app: Application) => {
  emit("application-selected", app.applicationId);

  // If redirectRoute is provided, redirect the user
  if (props.redirectRoute) {
    handleRedirect(app.applicationId);
  }
};

// Handle the application creation completion
const onApplicationCreated = (application: any) => {
  showCreateApplicationModal.value = false;
  const applicationId = application?.id || application;
  emit("application-created", applicationId);

  // If redirectRoute is provided, redirect the user
  if (props.redirectRoute) {
    handleRedirect(applicationId);
  }
};

// Handle redirection based on the redirectRoute prop
const handleRedirect = (applicationId: string) => {
  if (!applicationId) return;

  // Different redirect logic based on the redirectType
  if (props.redirectType === "resume" && props.resumeId) {
    router.push({
      name: props.redirectRoute,
      params: {
        applicationId: applicationId,
        resumeId: props.resumeId,
      },
    });
  } else if (props.redirectType === "coverLetter") {
    router.push({
      name: props.redirectRoute,
      query: { applicationId },
    });
  } else if (props.redirectType === "interviewPractice") {
    // Navigate to interview practice for the selected application
    router.push(`/application/${applicationId}/interview-practice`);
  } else {
    // Default redirect
    router.push({
      name: props.redirectRoute,
      query: { applicationId },
    });
  }
};
</script>

<style scoped>
.application-selector-with-modal {
  width: 100%;
  max-width: 600px;
  margin: 0 auto;
}

.selector-container {
  background-color: #ffffff;
  border-radius: 12px;
  box-shadow: 0 5px 20px rgba(0, 0, 0, 0.05);
  padding: 24px;
  margin-bottom: 20px;
  border: 1px solid #f0f0f0;
  transition: all 0.3s ease;
}

.selector-container:hover {
  box-shadow: 0 8px 25px rgba(0, 0, 0, 0.08);
}

.selector-header {
  display: flex;
  align-items: center;
  margin-bottom: 24px;
}

.icon-container {
  width: 50px;
  height: 50px;
  background-color: rgba(13, 110, 253, 0.1);
  border-radius: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 16px;
  color: #0d6efd;
  font-size: 1.5rem;
}

.header-text {
  flex: 1;
}

.selector-title {
  font-size: 1.5rem;
  font-weight: 600;
  color: #344767;
  margin-bottom: 4px;
}

.selector-description {
  color: #6c757d;
  margin-bottom: 0;
  font-size: 0.95rem;
}

@media (max-width: 768px) {
  .application-selector-with-modal {
    max-width: 100%;
    padding: 0 15px;
  }

  .selector-container {
    padding: 20px;
  }

  .selector-title {
    font-size: 1.3rem;
  }

  .icon-container {
    width: 40px;
    height: 40px;
    font-size: 1.2rem;
    margin-right: 12px;
  }
}
</style>
