import { Ref } from 'vue';
import { toast } from 'vue3-toastify';
import { ResumeSuggestion } from '@models/dtos/ResumeSuggestionsOutput';

interface ResumeEditorOptions {
  selectedResumeId: Ref<string | null>;
  aiSuggestions: Ref<ResumeSuggestion[]>;
  resumeScore: Ref<number>;
  keywordsScore: Ref<number>;
  experienceScore: Ref<number>;
  skillsScore: Ref<number>;
  currentResponse: Ref<any | null>;
  pdfBuffer: Ref<string>;
  calculateSuggestionScores: (suggestions: ResumeSuggestion[]) => any;
  getPdfBuffer: (fileId: string) => Promise<Blob>;
}

export function useResumeEditor(options: ResumeEditorOptions) {
  const {
    selectedResumeId,
    aiSuggestions,
    resumeScore,
    keywordsScore,
    experienceScore,
    skillsScore,
    currentResponse,
    pdfBuffer,
    calculateSuggestionScores,
    getPdfBuffer
  } = options;

  // Recalculate scores based on current suggestions
  const recalculateScores = () => {
    const scores = calculateSuggestionScores(aiSuggestions.value);
    resumeScore.value = scores.resumeScore;
    keywordsScore.value = scores.keywordsScore;
    experienceScore.value = scores.experienceScore;
    skillsScore.value = scores.skillsScore;
  };

  // Refresh the PDF preview
  const refreshPdfPreview = async () => {
    if (!selectedResumeId.value) return;
    
    try {
      const blob = await getPdfBuffer(selectedResumeId.value);
      pdfBuffer.value = window.URL.createObjectURL(blob);
    } catch (error) {
      console.error("Error refreshing PDF preview:", error);
      toast.error("Failed to refresh preview");
    }
  };

  // Apply a suggestion change to the document
  const applySuggestionChange = async (suggestion: ResumeSuggestion) => {
    if (!selectedResumeId.value) {
      throw new Error("No resume selected");
    }

    // Import here to avoid circular dependencies
    const { DocumentApiService } = await import('@/services/DocumentApiService');

    return await DocumentApiService.applySuggestion(selectedResumeId.value, {
      category: "resume",
      change: suggestion.change,
      location: suggestion.location,
      type: suggestion.type,
      command: suggestion.command,
      metadata: suggestion.metadata,
    });
  };

  return {
    recalculateScores,
    refreshPdfPreview,
    applySuggestionChange
  };
} 