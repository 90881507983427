<template>
  <div class="score-section">
    <div class="optimization-score" :class="{ expanded: isExpanded }" @click="toggleExpand">
      <div class="score-header">
        <div
          class="score-circle"
          :class="{ 'updating-score': updating }"
          :style="{ '--score': score }"
        >
          <div v-if="updating" class="spinner-overlay"></div>
          <span>{{ score }}%</span>
        </div>
        <div class="score-details">
          <div class="score-label">Resume Score</div>
          <div class="score-description text-muted">
            <span v-if="updating">Updating score...</span>
            <span v-else>Based on job requirements</span>
          </div>
        </div>
        <i class="bi" :class="isExpanded ? 'bi-chevron-up' : 'bi-chevron-down'"></i>
      </div>

      <div class="score-content">
        <div class="breakdown-item">
          <div class="breakdown-label">
            <span>Keywords Match</span>
            <span class="breakdown-score">{{ keywordsScore }}%</span>
          </div>
          <div class="progress">
            <div
              class="progress-bar"
              role="progressbar"
              :style="{ width: keywordsScore + '%' }"
            ></div>
          </div>
        </div>
        <div class="breakdown-item">
          <div class="breakdown-label">
            <span>Experience Match</span>
            <span class="breakdown-score">{{ experienceScore }}%</span>
          </div>
          <div class="progress">
            <div
              class="progress-bar"
              role="progressbar"
              :style="{ width: experienceScore + '%' }"
            ></div>
          </div>
        </div>
        <div class="breakdown-item">
          <div class="breakdown-label">
            <span>Skills Match</span>
            <span class="breakdown-score">{{ skillsScore }}%</span>
          </div>
          <div class="progress">
            <div
              class="progress-bar"
              role="progressbar"
              :style="{ width: skillsScore + '%' }"
            ></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { defineProps, ref } from "vue";

const props = defineProps<{
  score: number;
  keywordsScore: number;
  experienceScore: number;
  skillsScore: number;
  updating?: boolean;
}>();

const isExpanded = ref(false);

function toggleExpand() {
  isExpanded.value = !isExpanded.value;
}
</script>

<style scoped>
.score-section {
  border-bottom: 1px solid #f0f0f0;
}

.optimization-score {
  background: white;
  border-radius: 10px;
  padding: 1rem;
  cursor: pointer;
  transition: all 0.2s ease;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.04);
}

.optimization-score:hover {
  background: #f9f9f9;
}

.score-header {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.score-circle {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 600;
  font-size: 1rem;
  background: conic-gradient(#0d6efd calc(var(--score) * 1%), #e9ecef calc(var(--score) * 1%));
  position: relative;
  box-shadow: 0 2px 10px rgba(13, 110, 253, 0.15);
  transition: all 0.3s ease;
}

.score-circle::before {
  content: "";
  position: absolute;
  inset: 4px;
  border-radius: 50%;
  background: white;
}

.score-circle span {
  position: relative;
  z-index: 2;
}

/* Add styles for the updating state */
.spinner-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: 50%;
  border: 3px solid transparent;
  border-top: 3px solid #0d6efd;
  border-right: 3px solid #0d6efd;
  animation: spin 1s linear infinite;
  z-index: 1;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.score-details {
  flex: 1;
}

.score-label {
  font-weight: 600;
  margin-bottom: 0.25rem;
  font-size: 1.1rem;
  color: #333;
}

.score-description {
  font-size: 0.9rem;
}

.score-content {
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.3s ease-out, margin-top 0.3s ease-out;
}

.optimization-score.expanded .score-content {
  max-height: 300px;
  margin-top: 1.25rem;
  padding-top: 1.25rem;
  border-top: 1px solid #f0f0f0;
}

.breakdown-item {
  margin-bottom: 1.25rem;
}

.breakdown-item:last-child {
  margin-bottom: 0;
}

.breakdown-label {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 0.5rem;
  font-size: 0.9rem;
  font-weight: 500;
}

.breakdown-score {
  font-weight: 600;
  color: #0d6efd;
}

.progress {
  height: 6px;
  background: #e9ecef;
  border-radius: 3px;
  overflow: hidden;
}

.progress-bar {
  background: #0d6efd;
  transition: width 0.6s ease;
}
</style>
