import { Ref } from 'vue';

export function usePdfHandling(options: {
  pdfWidth: Ref<number>;
  DocumentApiService: any;
  FileApiService: any;
  ResponseDto: any;
  ResponseType: any;
  Tone: any;
}) {
  const { 
    pdfWidth, 
    DocumentApiService, 
    FileApiService, 
    ResponseDto, 
    ResponseType, 
    Tone 
  } = options;

  // Calculate PDF height based on width (aspect ratio ~1.415)
  function getPdfHeight(): number {
    return Math.floor(pdfWidth.value * 1.415);
  }

  // Get PDF buffer from the backend
  async function getPdfBuffer(fileId: string): Promise<Blob> {
    // First get the resume data
    const resumeResponse = await DocumentApiService.getUserResume(fileId);
    const resume = resumeResponse.data;

    const result = await FileApiService.getPDFPreview(
      new ResponseDto(
        null,
        "",
        "",
        resume.userId,
        false,
        ResponseType.CreateResume,
        null,
        0.7,
        Tone.FORMAL,
        resume.template,
        "",
        resume,
        null,
        null
      )
    );

    if (result.status !== 200) {
      throw new Error("Failed to fetch the preview data.");
    }

    const buffer = result.data;
    const mimetype = result.headers["Content-Type"] || "application/octet-stream";
    const blob = new Blob([buffer], { type: mimetype as string });
    return blob;
  }

  return {
    getPdfHeight,
    getPdfBuffer
  };
} 