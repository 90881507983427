<template>
  <div>
    <Modal title="There's been an error!" ref="errorModal">
      <template #body>There was an error processing this request. Please try again later!</template>
    </Modal>
    <Teleport to="body">
      <div
        v-if="showSubscribeModal"
        class="modal fade show d-block"
        tabindex="-1"
        style="background: rgba(0, 0, 0, 0.5)"
      >
        <Subscribe :is-modal="true" @close="showSubscribeModal = false" />
        <button
          type="button"
          class="btn-close position-absolute top-0 end-0 m-4"
          @click="showSubscribeModal = false"
          style="z-index: 1050"
        ></button>
      </div>
    </Teleport>
    <div class="modal fade" :class="{ 'show d-block': isModal && show }" tabindex="-1" style="background: rgba(0, 0, 0, 0.5)">
      <div class="modal-dialog modal-dialog-centered modal-lg" style="max-width: 800px;">
        <div class="modal-content border-0 shadow-lg rounded-lg">
          <div class="modal-header border-0 py-3">
            <button v-if="isModal" type="button" class="btn-close" @click="closeModal"></button>
          </div>
          <div class="modal-body p-4">
            <!-- Progress Steps -->
            <div class="progress-steps mb-4">
              <div class="step-container d-flex justify-content-between align-items-center position-relative">
                <div 
                  v-for="(step, index) in steps" 
                  :key="index"
                  class="step d-flex flex-column align-items-center position-relative"
                >
                  <div 
                    class="step-circle d-flex align-items-center justify-content-center rounded-circle mb-2"
                    :class="{ 
                      'active': applicationPhase >= index + 1,
                      'completed': applicationPhase > index + 1 
                    }"
                  >
                    <i v-if="applicationPhase > index + 1" class="bi bi-check-lg"></i>
                    <span v-else>{{ index + 1 }}</span>
                  </div>
                  <div class="step-label text-center" :class="{ 'active': applicationPhase >= index + 1 }">
                    {{ step }}
                  </div>
                </div>
                <!-- Connecting lines between steps -->
                <div class="connecting-line position-absolute"></div>
                <div 
                  class="connecting-line-progress position-absolute"
                  :style="{ width: applicationPhase >= steps.length ? `${100}%` : `${((applicationPhase - 1) / (steps.length - 1)) * 100}%`, maxWidth: 'calc(100% - 120px)' }"
                ></div>
              </div>
            </div>

            <!-- Step Content -->
            <div class="step-content" style="min-height: 400px; max-height: 400px; overflow-y: auto;">
              <div v-if="applicationPhase == 1" class="fade-in">
                <div class="url-input-container" v-if="!showDetails">
                  <div class="text-center mb-4">
                    <h4 class="fw-bold">Add a new job application</h4>
                    <p class="text-muted">Enter a job posting URL to auto-fill details or enter them manually</p>
                  </div>

                  <div v-if="!loading" class="url-input-group mb-4">
                    <div class="input-group">
                      <input
                        type="text"
                        class="form-control"
                        placeholder="Job listing URL"
                        v-model="jobListingURL"
                        @keyup.enter="loadDetailsFromURL"
                      />
                      <button class="btn btn-primary" @click="loadDetailsFromURL" :disabled="loading">
                        <i class="bi bi-cloud-download me-2"></i>
                        <span v-if="!loading">Load from URL</span>
                        <span v-else class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                      </button>
                    </div>
                    <div v-if="showUrlLoadError" class="text-danger mt-2">
                      <small>Unable to load job details from URL. Please try again or enter details manually.</small>
                    </div>
                  </div>

                  <div v-if="loading" class="text-center mt-4">
                    <div class="loading-pulse mb-3"></div>
                    <p class="text-muted mb-0">{{ currentLoadingMessage }}</p>
                  </div>

                  <div class="text-center" v-if="!loading">
                    <button type="button" class="btn btn-link text-muted" @click="toggleManualEntry">
                      Enter details manually
                    </button>
                  </div>
                </div>

                <div v-if="showDetails" class="manual-entry-form">
                  <div class="d-flex justify-content-between align-items-center mb-4">
                    <h4 class="h5 mb-0">Job Details</h4>
                    <button type="button" class="btn btn-link" @click="toggleManualEntry">
                      <i class="bi bi-link-45deg me-1"></i>
                      Load from URL instead
                    </button>
                  </div>

                  <div class="row g-4">
                    <div class="col-8">
                      <div class="form-floating">
                        <input
                          v-model="application.roleTitle"
                          class="form-control rounded-3"
                          id="roleTitle"
                          placeholder="Role Title"
                        />
                        <label for="roleTitle">Role Title</label>
                      </div>
                    </div>
                    <div class="col-md-4">
                      <div class="form-floating">
                        <input
                          type="date"
                          v-model="application.postedDate"
                          class="form-control rounded-3"
                          id="postedDate"
                          placeholder="Date Posted"
                        />
                        <label for="postedDate">Date Posted</label>
                      </div>
                    </div>
                    <div class="col-md-2">
                      <div class="form-floating">
                        <input
                          v-model="application.salaryCurrency"
                          class="form-control rounded-3"
                          id="salaryCurrency"
                          placeholder="Currency"
                        />
                        <label for="salaryCurrency">Currency</label>
                      </div>
                    </div>
                    <div class="col-md-4">
                      <div class="form-floating">
                        <input
                          v-model="application.salaryMin"
                          class="form-control rounded-3"
                          id="salaryMin"
                          placeholder="Minimum Salary"
                        />
                        <label for="salaryMin">Minimum Salary</label>
                      </div>
                    </div>
                    <div class="col-md-4">
                      <div class="form-floating">
                        <input
                          v-model="application.salaryMax"
                          class="form-control rounded-3"
                          id="salaryMax"
                          placeholder="Maximum Salary"
                        />
                        <label for="salaryMax">Maximum Salary</label>
                      </div>
                    </div>
                    <div class="col-md-2">
                      <div class="form-floating">
                        <input
                          v-model="application.salaryInterval"
                          class="form-control rounded-3"
                          placeholder="Interval"
                          id="salaryInterval"
                        />
                        <label for="salaryInterval">Interval</label>
                      </div>
                    </div>
                    <div class="col-12">
                      <div class="form-floating">
                        <textarea
                          v-model="application.jobDescription"
                          class="form-control rounded-3"
                          id="jobDescription"
                          placeholder="Job Description"
                          style="height: 150px"
                        />
                        <label for="jobDescription">Job Description</label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div v-if="applicationPhase == 2" class="fade-in">
                <h4 class="h5 mb-4">Company & Role Details</h4>
                <div class="row g-4">
                  <div class="col-md-6">
                    <div class="form-floating">
                      <input
                        v-model="application.companyName"
                        class="form-control rounded-3"
                        id="companyName"
                        placeholder="Company Name"
                      />
                      <label for="companyName">Company Name</label>
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="form-floating">
                      <input
                        v-model="application.location"
                        class="form-control rounded-3"
                        id="location"
                        placeholder="Location"
                      />
                      <label for="location">Location</label>
                    </div>
                  </div>
                  <div class="col-12">
                    <div class="form-floating">
                      <textarea
                        v-model="application.companyDescription"
                        class="form-control rounded-3"
                        style="height: 115px"
                        placeholder="Company Description"
                      />
                      <label>What does the company do? What's their mission?</label>
                    </div>
                  </div>
                  <div class="col-12">
                    <div class="form-floating">
                      <textarea
                        v-model="application.roleComments"
                        class="form-control rounded-3"
                        style="height: 115px"
                        placeholder="Your Interest"
                      />
                      <label>Why are you interested in this role? What makes you a good fit?</label>
                    </div>
                  </div>
                </div>
              </div>

              <div v-if="applicationPhase == 3" class="fade-in">
                <h4 class="fw-bold mb-3">Review Your Application</h4>

                <div class="review-container">
                  <!-- Role Details Section -->
                  <div class="review-card mb-3">
                    <div class="review-card-header">
                      <h5 class="mb-0">Role Details</h5>
                    </div>
                    <div class="review-card-body py-2">
                      <div class="row g-2">
                        <div class="col-md-6">
                          <div class="review-item">
                            <div class="d-flex align-items-center">
                              <span class="review-label me-2">Role Title:</span>
                              <span class="review-value">{{ application.roleTitle }}</span>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="review-item">
                            <div class="d-flex align-items-center">
                              <span class="review-label me-2">Company:</span>
                              <span class="review-value">{{ application.companyName }}</span>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="review-item">
                            <div class="d-flex align-items-center">
                              <span class="review-label me-2">Location:</span>
                              <span class="review-value">{{ application.location }}</span>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="review-item">
                            <div class="d-flex align-items-center">
                              <span class="review-label me-2">Salary Range:</span>
                              <span class="review-value">{{ application.salaryCurrency }} {{ application.salaryMin }} - {{ application.salaryMax }} / {{ application.salaryInterval }}</span>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="review-item">
                            <div class="d-flex align-items-center">
                              <span class="review-label me-2">Date Posted:</span>
                              <span class="review-value">{{ formatDisplayDate(application.postedDate) }}</span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <!-- Comments Section -->
                  <div class="review-card">
                    <div class="review-card-header">
                      <h5 class="mb-0">Your Comments</h5>
                    </div>
                    <div class="review-card-body py-2">
                      <p class="mb-0 small" style="white-space: pre-line; max-height: 100px; overflow-y: auto;">
                        {{ application.roleComments || "No comments added." }}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer border-0 p-4">
            <div class="d-flex gap-3">
              <button
                v-if="applicationPhase > 1"
                type="button"
                class="btn btn-light flex-grow-1"
                @click="applicationPhase--"
              >
                <i class="bi bi-arrow-left me-2"></i>
                Back
              </button>
              <button
                type="button"
                class="btn btn-primary flex-grow-1"
                :disabled="loading"
                @click="buttonClicked"
              >
                <span v-show="!loading">
                  {{ applicationPhase === 3 ? "Create Application" : "Continue" }}
                  <i class="bi bi-arrow-right ms-2"></i>
                </span>
                <div v-show="loading" class="spinner-border spinner-border-sm" />
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import CreateOrUpdateApplication from "@models/dtos/CreateOrUpdateApplication";
import { ref, onMounted, defineProps, defineEmits } from "vue";
import router from "../router";
import { ApplicationApiService } from "@/services/ApplicationApiService";
import Modal from "@/components/Modal.vue";
import { toast } from "vue3-toastify";
import { ApplicationStatus } from "@models/SystemEnums";
import { WebscrapeApiService } from "@/services/WebscrapeApiService";
import Subscribe from "@/views/Subscribe.vue";
import { useRoute } from "vue-router";

const props = defineProps({
  show: {
    type: Boolean,
    default: false
  },
  isModal: {
    type: Boolean,
    default: false
  },
  resumeId: {
    type: String,
    default: ""
  }
});

const emit = defineEmits(['close', 'application-created']);

const route = useRoute();

const loading = ref(false);
const applicationPhase = ref(1);
const showDetails = ref(false);
const steps = ref(["Job Details", "Company & Role", "Review"]);
const showSubscribeModal = ref(false);
const application = ref<CreateOrUpdateApplication | any>({
  id: null,
  userId: "",
  companyName: "",
  roleTitle: "",
  jobDescription: "",
  companyDescription: "",
  roleComments: "",
  applicationStatus: ApplicationStatus.APPLIED,
  index: 0,
  isDeleted: false,
  location: "",
  postedDate: "",
  salaryCurrency: "$",
  salaryMin: "",
  salaryMax: "",
  salaryInterval: "P/A",
  sourcedFrom: "",
  jobUrl: "",
  isRemote: false,
});
const jobListingURL = ref("");
const errorModal = ref(null);
const showUrlLoadError = ref(false);
const currentLoadingMessage = ref("");
const loadingMessages = [
  "Loading...",
  "Downloading webpage...",
  "Parsing webpage content...",
  "Extracting job description...",
  "Extracting company name...",
  "Extracting location...",
  "Extracting salary details...",
  "Extracting date posted...",
  "Analyzing job requirements...",
  "Extracting benefits information...",
  "Importing data...",
  "Validating data integrity...",
  "Organizing extracted information...",
  "Matching data to fields...",
  "Cleaning up extracted text...",
  "Formatting output...",
  "Preparing final data...",
  "Almost done...",
  "Finalizing details...",
  "Ready to display!",
];

let loadingInterval: number | null = null;

const startLoadingMessages = () => {
  let index = 0;
  currentLoadingMessage.value = loadingMessages[0];

  loadingInterval = window.setInterval(() => {
    index = (index + 1) % loadingMessages.length;
    currentLoadingMessage.value = loadingMessages[index];
  }, 2500);
};

const stopLoadingMessages = () => {
  if (loadingInterval) {
    clearInterval(loadingInterval);
    loadingInterval = null;
  }
};

async function loadDetailsFromURL() {
  if (!jobListingURL.value) return;

  loading.value = true;
  startLoadingMessages();

  try {
    const result = await WebscrapeApiService.getApplicationDetailsFromUrl(jobListingURL.value);
    if (result.status == 200) {
      application.value.companyName = result?.data.companyName;
      application.value.companyDescription = result?.data.companyDescription;
      application.value.jobDescription = result?.data.jobDescription;
      application.value.roleTitle = result?.data.roleTitle;
      application.value.location = result?.data.location;
      // Convert MM/DD/YYYY to ISO format
      const postedDate = result?.data.postedDate;
      if (postedDate) {
        const [month, day, year] = postedDate.split("/");
        application.value.postedDate = `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`;
      } else {
        application.value.postedDate = new Date().toISOString().split("T")[0];
      }
      application.value.salaryCurrency = result?.data.salaryCurrency || "$";
      application.value.salaryMin = result?.data.salaryMin;
      application.value.salaryMax = result?.data.salaryMax;
      application.value.salaryInterval = result?.data.salaryInterval || "P/A";
      application.value.sourcedFrom = result?.data.sourcedFrom;
      application.value.isRemote = result?.data.isRemote || false;
      application.value.jobUrl = result?.data.jobUrl;
      showDetails.value = true;
    }
  } catch (err: any) {
    showUrlLoadError.value = true;
    if (err.response.data.error == "Quota exceeded") {
      toast.error("Quota exceeded, please upgrade your account");
      loading.value = false;
      showSubscribeModal.value = true;
    }
  } finally {
    loading.value = false;
    stopLoadingMessages();
  }
}

// Add a function to format the date for display
function formatDisplayDate(dateString: string) {
  if (!dateString) return "";
  const date = new Date(dateString);
  return date.toLocaleDateString("en-US", {
    year: "numeric",
    month: "short",
    day: "numeric",
  });
}

async function buttonClicked() {
  if (applicationPhase.value === 2) {
    applicationPhase.value++;
  } else if (applicationPhase.value === 3) {
    await createApplication();
  } else {
    applicationPhase.value++;
  }
}

async function createApplication() {
  try {
    loading.value = true;
    const result = await ApplicationApiService.createOrUpdateApplication(application.value);
    window.dispatchEvent(new CustomEvent("application-created"));
    
    // Emit the application-created event with the new application data
    emit('application-created', result.data.id.toString());
    
    // Check if we should return to AIResumeEditor (coming from Optimize tab)
    const returnToAIResume = route.query.returnToAIResume === "true";
    const resumeId = props.resumeId || route.query.resumeId as string;
    
    if (!props.isModal) {
      if (returnToAIResume && resumeId && result.data?.id) {
        // Navigate to AIResumeEditor with the new application and resume IDs
        await router.push({
          name: "AIResumeEditorWithResume",
          params: {
            applicationId: result.data.id.toString(),
            resumeId: resumeId
          }
        });
      } else {
        // Default navigation to application details
        await router.push("/application/" + (result.data?.id || ""));
      }
    } else {
      // If in modal mode, just close the modal after emitting the event
      closeModal();
    }
  } catch (err: any) {
    await handleApplicationCreationError(err);
  } finally {
    loading.value = false;
  }
}

function handleApplicationCreationError(err: any) {
  if (err.status != 200) {
    if (err.response.data.error == "Quota exceeded") {
      toast.error("Quota exceeded, please upgrade your account");
      loading.value = false;
      showSubscribeModal.value = true;
    } else {
      toast.error("There has been a problem creating this application");
      loading.value = false;
      errorModal.value.show();
    }
  }
}

function getStepLabel(step: number) {
  switch (step) {
    case 1:
      return "Job Details";
    case 2:
      return "Company & Role";
    case 3:
      return "Review";
    default:
      return "";
  }
}

function toggleManualEntry() {
  showDetails.value = !showDetails.value;
  showUrlLoadError.value = false;
}

function closeModal() {
  emit('close');
}
</script>

<style scoped>
.modal-content {
  overflow: hidden;
}

.progress-steps {
  padding: 0 20px;
}

.step-container {
  width: 100%;
}

.step {
  z-index: 2;
  width: 120px;
}

.step-circle {
  width: 36px;
  height: 36px;
  background-color: #f0f0f0;
  color: #666;
  font-weight: 600;
  transition: all 0.3s ease;
}

.step-circle.active {
  background-color: #4285f4;
  color: white;
}

.step-circle.completed {
  background-color: #34a853;
  color: white;
}

.step-label {
  font-size: 14px;
  color: #666;
  transition: all 0.3s ease;
}

.step-label.active {
  color: #333;
  font-weight: 500;
}

.connecting-line {
  top: 18px;
  left: 60px;
  right: 60px;
  height: 2px;
  background-color: #e0e0e0;
  z-index: 1;
}

.connecting-line-progress {
  top: 18px;
  left: 60px;
  height: 2px;
  background-color: #4285f4;
  z-index: 1;
  transition: width 0.3s ease;
  max-width: calc(100% - 120px);
}

.step-content {
  transition: all 0.3s ease;
}

.form-control, .form-select {
  border-radius: 8px;
  border: 1px solid #e0e0e0;
  padding: 10px 15px;
  transition: all 0.2s ease;
}

.form-control:focus, .form-select:focus {
  border-color: #4285f4;
  box-shadow: 0 0 0 0.2rem rgba(66, 133, 244, 0.15);
}

.btn-primary {
  background-color: #4285f4;
  border-color: #4285f4;
  border-radius: 8px;
  padding: 10px 20px;
  font-weight: 500;
  transition: all 0.2s ease;
}

.btn-primary:hover {
  background-color: #3367d6;
  border-color: #3367d6;
}

.btn-secondary {
  background-color: #f8f9fa;
  border-color: #e0e0e0;
  color: #333;
  border-radius: 8px;
  padding: 10px 20px;
  font-weight: 500;
  transition: all 0.2s ease;
}

.btn-secondary:hover {
  background-color: #e9ecef;
  border-color: #ced4da;
}

.modal-content {
  border-radius: 12px;
}

.modal-header {
  padding-bottom: 0;
}

.card {
  border-radius: 12px;
  border: 1px solid #e0e0e0;
  transition: all 0.2s ease;
}

.card:hover {
  transform: translateY(-2px);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.05);
}

.card-header {
  background-color: #f8f9fa;
  border-bottom: 1px solid #e0e0e0;
  font-weight: 500;
}

.step-content {
  animation: fadeIn 0.3s ease;
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

/* Review step styling */
.review-container {
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
}

.review-card {
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  overflow: hidden;
  transition: all 0.2s ease;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.03);
}

.review-card-header {
  background-color: #f8f9fa;
  padding: 0.5rem 1rem;
  border-bottom: 1px solid #e0e0e0;
}

.review-card-header h5 {
  font-weight: 600;
  color: #333;
  font-size: 0.9rem;
}

.review-card-body {
  padding: 0.75rem 1rem;
}

.review-item {
  margin-bottom: 0.25rem;
}

.review-label {
  font-size: 0.85rem;
  color: #6c757d;
  white-space: nowrap;
}

.review-value {
  font-size: 0.85rem;
  font-weight: 500;
  color: #212529;
}

/* Button styling */
.btn {
  padding: 0.75rem 1.5rem;
  border-radius: 8px;
  font-weight: 500;
  transition: all 0.2s ease;
}

.btn-primary {
  background-color: #4285f4;
  border-color: #4285f4;
}

.btn-primary:hover {
  background-color: #3367d6;
  border-color: #3367d6;
}

.btn-light {
  background-color: #f8f9fa;
  border-color: #e0e0e0;
  color: #333;
}

.btn-light:hover {
  background-color: #e9ecef;
  border-color: #ced4da;
}
</style>
