import { AxiosResponse } from "axios";
import { instance } from "./apiProvider/ApiProvider";
import DocumentDto from "@models/dtos/DocumentDto";
import { Resume } from "@models/Resume";
import type { AI_RESUME_COMMANDS, ResumeSuggestionsOutput } from "../../../server/src/models/dtos/ResumeSuggestionsOutput";
import { type ATSScoreResult } from "@models/dtos/ATSScoreResult";
// Define Resume type locally to match our interface

export class DocumentApiService {
  public static async getUserDocumentsList(): Promise<AxiosResponse<DocumentDto[]>> {
    const axios = await instance();
    const response = await axios.get("/getUserDocumentsList");
    if (response.status == 200) {
      return response;
    } else {
      console.error(response);
      return response;
    }
  }
  public static async uploadResume(file: FormData): Promise<AxiosResponse<Resume>> {
    const axios = await instance();
    const response = await axios.post("/uploadResume", file);
    if (response.status == 200) {
      return response;
    } else {
      console.error(response);
      return response;
    }
  }

  public static async upgradeResume(data: {resumeId: string}): Promise<AxiosResponse<Resume>> {
    const axios = await instance();
    const response = await axios.post("/upgradeResume", data);
    if (response.status == 200) {
      return response;
    } else {
      console.error(response);
      return response;
    }
  }

  public static async createResume(data: { name: string }): Promise<AxiosResponse<string>> {
    const axios = await instance();
    const response = await axios.post("/createResume", data);
    return response;
  }

  public static async duplicateResume(data: { id: string, name?: string }): Promise<AxiosResponse<string>> {
    const axios = await instance();
    const response = await axios.post("/duplicateResume", data);
    return response;
  }

  public static async deleteResume(data: { id: string }): Promise<AxiosResponse<string>> {
    const axios = await instance();
    const response = await axios.delete("/deleteResume", { data });
    return response;
  }


  public static async delete(documentId: string): Promise<AxiosResponse> {
    const axios = await instance();
    const response = await axios.delete("/deleteDocument", { data: { id: documentId } });
    if (response.status == 200) {
      return response;
    } else {
      console.error(response);
      return response;
    }
  }

  //Create a new method to get the user resume
  //  getUserResume = this.asyncRouteHandler(async (req: e.Request, res: e.Response): Promise<void> => {
  //   const loggedInUserId = AuthUtils.getUserIdFromJwt(req.headers.authorization!)
  //   const resume = await this.documentService.getUserResume(loggedInUserId)
  //   res.status(200).send(resume)
  // })

  public static async getUserResume(resumeId: string | null): Promise<AxiosResponse<Resume>> {
    const axios = await instance();
    const response = await axios.get(`/getUserResume`, { params: { resumeId } });
    return response;
  }

  public static async updateUserResume(resume: Resume): Promise<AxiosResponse<Resume>> {
    const axios = await instance();
    const response = await axios.put("/updateUserResume", resume);
    return response;
  }

  public static async createUserResume(resume: Resume): Promise<AxiosResponse<any>> {
    const axios = await instance();
    const response = await axios.post("/createUserResume", resume);
    return response;
  }

  public static async getResumePreview(resume: Resume): Promise<AxiosResponse<any>> {
    const axios = await instance();
    const response = await axios.post("/getResumePreview", resume, {
      responseType: "blob",
    });
    return response;
  }

  static async getResumeById(id: string) {
    try {
      const axios = await instance();
      const response = await axios.get(`/getUserResume`, { params: { resumeId: id } });
      return response;
    } catch (error) {
      console.error("Error fetching resume by ID:", error);
      throw error;
    }
  }

  public static async getResumeSuggestions(
    resumeId: string,
    applicationId: string,
    atsScore: ATSScoreResult
  ): Promise<ResumeSuggestionsOutput> {
    try {
      const axios = await instance();
      const response = await axios.post(
        `/getResumeSuggestions`,
        {
          resumeId,
          applicationId,
          atsScore
        }
      );
      return response.data;
    } catch (error) {
      console.error("Error getting resume suggestions:", error);
      throw error;
    }
  }

  public static async generateSingleSuggestion(
    resumeId: string,
    applicationId: string,
    location: string,
    type: string
  ): Promise<any> {
    try {
      const axios = await instance();
      const response = await axios.post(
        `/generateSingleSuggestion`,
        { resumeId, applicationId, location, type }
      );
      return response.data;
    } catch (error) {
      console.error("Error generating single suggestion:", error);
      throw error;
    }
  }

  public static async applySuggestion(
    resumeId: string,
    suggestion: {
      category: string;
      originalContent?: string;
      change: string;
      location: string;
      type: string;
      command?: AI_RESUME_COMMANDS;
      metadata?: any;
    }
  ): Promise<Resume> {
    try {
      const axios = await instance();
      const response = await axios.post(
        `/applySuggestion`,
        { resumeId, suggestion }
      );
      return response.data;
    } catch (error) {
      console.error("Error applying suggestion:", error);
      throw error;
    }
  }

  public static async getUserResumes(): Promise<AxiosResponse<Resume[]>> {
    try {
      const axios = await instance();
      const response = await axios.get("/getUserResumes");
      return response;
    } catch (error) {
      console.error("Error getting user resumes:", error);
      throw error;
    }
  }
}
