<template>
  <div class="ats-score-tester">
    <h1>ATS Score Tester</h1>
    <p class="description">Test how your resume scores against job requirements</p>

    <div class="form-container">
      <div class="form-section">
        <h2>Resume Selection</h2>
        <div class="form-group">
          <label for="resume-select">Select Resume</label>
          <select id="resume-select" v-model="selectedResumeId" class="form-control">
            <option value="">-- Select a Resume --</option>
            <option v-for="resume in resumes" :key="resume.id" :value="resume.id">
              {{ resume.name }}
            </option>
          </select>
        </div>

        <div class="form-group">
          <label for="application-select">Select Application</label>
          <select id="application-select" v-model="selectedApplicationId" class="form-control">
            <option value="">-- Select an Application --</option>
            <option
              v-for="application in applications"
              :key="application.id"
              :value="application.id"
            >
              {{ application.roleTitle }} at {{ application.companyName }}
            </option>
          </select>
        </div>

        <div class="form-actions">
          <button
            @click="quickAnalyzeResume"
            class="btn-analyze"
            :disabled="!selectedResumeId || !selectedApplicationId"
          >
            Quick Analyze with Application
          </button>
        </div>
      </div>

      <div class="form-section">
        <h2>Manual Job Requirements</h2>

        <div class="form-group">
          <label for="job-title">Job Title</label>
          <input
            id="job-title"
            type="text"
            v-model="jobTitle"
            class="form-control"
            placeholder="e.g., Senior Software Engineer"
          />
        </div>

        <div class="form-group">
          <label for="required-experience">Required Experience</label>
          <input
            id="required-experience"
            type="text"
            v-model="requiredExperience"
            class="form-control"
            placeholder="e.g., 5+ years"
          />
        </div>

        <div class="form-group">
          <label for="required-skills">Required Skills (comma separated)</label>
          <textarea
            id="required-skills"
            v-model="requiredSkillsText"
            class="form-control"
            placeholder="e.g., JavaScript, React, Node.js"
            rows="3"
          ></textarea>
        </div>

        <div class="form-group">
          <label>Keywords with Weights</label>
          <div class="keywords-container">
            <div v-for="(keyword, index) in keywords" :key="index" class="keyword-item">
              <input
                type="text"
                v-model="keyword.keyword"
                class="form-control keyword-input"
                placeholder="Keyword"
              />
              <select v-model="keyword.weight" class="form-control weight-select">
                <option value="10">Critical (10)</option>
                <option value="5">Important (5)</option>
                <option value="2">Nice-to-have (2)</option>
              </select>
              <button @click="removeKeyword(index)" class="btn-remove">✕</button>
            </div>
            <button @click="addKeyword" class="btn-add">+ Add Keyword</button>
          </div>
        </div>

        <div class="form-actions">
          <button
            @click="analyzeResume"
            class="btn-analyze"
            :disabled="!selectedResumeId || !jobTitle"
          >
            Manual Analyze
          </button>
        </div>
      </div>
    </div>

    <div v-if="selectedResumeId && (selectedApplicationId || jobTitle)" class="results-container">
      <ATSScorePanel
        :resumeId="selectedResumeId"
        :applicationId="selectedApplicationId"
        :jobTitle="jobTitle"
        :requiredExperience="requiredExperience"
        :requiredSkills="requiredSkills"
        :keywords="keywords"
        ref="scorePanel"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref, computed, onMounted, defineAsyncComponent } from "vue";
import { DocumentApiService } from "../services/DocumentApiService";
import { ApplicationApiService } from "../services/ApplicationApiService";

// Import using defineAsyncComponent to handle the component without default export
const ATSScorePanel = defineAsyncComponent(() => import("./resume-editor/ATSScorePanel.vue"));

// State
const resumes = ref<Array<{ id: string; name: string }>>([]);
const applications = ref<Array<{ id: string; roleTitle: string; companyName: string }>>([]);
const selectedResumeId = ref("");
const selectedApplicationId = ref("");
const jobTitle = ref("");
const requiredExperience = ref("");
const requiredSkillsText = ref("");
const keywords = ref<Array<{ keyword: string; weight: number }>>([{ keyword: "", weight: 10 }]);
const scorePanel = ref<any>(null); // Use any type to avoid TypeScript errors with the ref

// Computed properties
const requiredSkills = computed(() => {
  return requiredSkillsText.value
    .split(",")
    .map((skill) => skill.trim())
    .filter((skill) => skill !== "");
});

// Methods
function addKeyword() {
  keywords.value.push({ keyword: "", weight: 5 });
}

function removeKeyword(index: number) {
  keywords.value.splice(index, 1);

  // Always keep at least one keyword field
  if (keywords.value.length === 0) {
    addKeyword();
  }
}

function analyzeResume() {
  if (scorePanel.value) {
    scorePanel.value.scoreResume();
  }
}

function quickAnalyzeResume() {
  if (scorePanel.value) {
    scorePanel.value.quickScoreResume();
  }
}

// Fetch user's resumes on component mount
async function fetchResumes() {
  try {
    const response = await DocumentApiService.getUserResumes();
    resumes.value = response.data.map((resume) => ({
      ...resume,
      id: resume.id || crypto.randomUUID(),
      name: resume.name || "Untitled Resume",
      lastModified: resume.lastModified ? new Date(resume.lastModified) : new Date(),
      previewUrl: resume.previewUrl || null,
    }));
  } catch (error) {
    console.error("Error fetching resumes:", error);
  }
}

// Fetch user's applications
async function fetchApplications() {
  try {
    const response = await ApplicationApiService.getUserApplicationList();
    applications.value = response.data.applications.map((application: any) => ({
      id: application.applicationId,
      roleTitle: application.roleTitle || "Untitled Position",
      companyName: application.companyName || "Unknown Company",
    }));
  } catch (error) {
    console.error("Error fetching applications:", error);
  }
}

onMounted(() => {
  fetchResumes();
  fetchApplications();
});
</script>

<style scoped>
.ats-score-tester {
  max-width: 1200px;
  margin: 0 auto;
  padding: 2rem;
}

h1 {
  font-size: 2rem;
  margin-bottom: 0.5rem;
  color: #333;
}

.description {
  color: #666;
  margin-bottom: 2rem;
}

.form-container {
  display: grid;
  grid-template-columns: 1fr 2fr;
  gap: 2rem;
  margin-bottom: 2rem;
}

.form-section {
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.05);
  padding: 1.5rem;
}

h2 {
  font-size: 1.25rem;
  margin-bottom: 1.5rem;
  color: #333;
}

.form-group {
  margin-bottom: 1.5rem;
}

label {
  display: block;
  margin-bottom: 0.5rem;
  font-weight: 500;
  color: #333;
}

.form-control {
  width: 100%;
  padding: 0.75rem;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 1rem;
}

.keywords-container {
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
}

.keyword-item {
  display: flex;
  gap: 0.5rem;
  align-items: center;
}

.keyword-input {
  flex: 3;
}

.weight-select {
  flex: 2;
}

.btn-remove {
  background-color: #f8d7da;
  color: #721c24;
  border: none;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  font-weight: bold;
}

.btn-add {
  background-color: #e9ecef;
  color: #495057;
  border: none;
  padding: 0.5rem;
  border-radius: 4px;
  cursor: pointer;
  font-weight: 500;
}

.form-actions {
  margin-top: 2rem;
}

.btn-analyze {
  background-color: #007bff;
  color: white;
  border: none;
  padding: 0.75rem 1.5rem;
  border-radius: 4px;
  font-weight: 500;
  cursor: pointer;
  transition: background-color 0.2s;
}

.btn-analyze:hover {
  background-color: #0069d9;
}

.btn-analyze:disabled {
  background-color: #6c757d;
  cursor: not-allowed;
}

.results-container {
  margin-top: 2rem;
}

@media (max-width: 768px) {
  .form-container {
    grid-template-columns: 1fr;
  }
}
</style>
