<template>
  <div class="resume-builder">
    <BreadcrumbNav back-link="/resumes" back-text="Resumes" current-page="Resume Editor" />

    <!-- Section Navigation -->
    <ResumeNavigation v-show="resume" v-model="currentSection" :resume="resume" />

    <div
      v-show="resume"
      class="edit-content"
      :class="{ 'preview-mode': currentSection === 'preview', loading: isLoading }"
    >
      <!-- PDF Preview Panel -->
      <PdfPreview v-show="currentSection === 'preview'" :loading="loading" :pdf-url="pdfUrl" />

      <!-- Edit Panel -->
      <div class="edit-panel">
        <div v-show="currentSection === 'preview'">
          <!-- Action Buttons -->
          <div class="d-flex gap-2 mb-3">
            <button class="btn btn-outline-primary flex-grow-1" @click="downloadDocument">
              <i class="bi bi-download me-2"></i>
              Download Resume
            </button>
            <button
              v-if="hasUnsavedChanges"
              class="btn btn-primary flex-grow-1"
              @click="saveResume"
            >
              <i class="bi bi-save me-2"></i>
              Save
            </button>
          </div>
          <TemplateSelector v-model="resume.template" />
        </div>
        <div v-show="currentSection !== 'preview'" class="section-content">
          <!-- Save Changes Button -->
          <div class="save-button-container" v-if="hasUnsavedChanges">
            <button class="btn-save-resume" @click="saveResume">
              <i class="bi bi-save me-2"></i>Save Changes
            </button>
          </div>

          <!-- Contact Information -->
          <ContactSection v-if="currentSection === 'contact'" v-model="resume.contactInfo" />

          <!-- Professional Summary -->
          <SummarySection v-else-if="currentSection === 'summary'" v-model="resume.summary" />

          <!-- Work Experience -->
          <WorkExperienceSection v-else-if="currentSection === 'work'" v-model="workExperience" />

          <!-- Education -->
          <EducationSection v-else-if="currentSection === 'education'" v-model="education" />

          <!-- Additional Information -->
          <AdditionalSection
            v-else-if="currentSection === 'additional'"
            v-model:skills="additionalSkills"
            v-model:certifications="certifications"
            v-model:projects="projects"
          />

          <!-- Optimize Resume Section -->
          <div v-else-if="currentSection === 'optimize'" class="optimize-section">
            <h3 class="section-title">Optimize Your Resume</h3>
            <div class="section-title-underline"></div>

            <div class="row g-4">
              <div class="col-12">
                <ApplicationSelectorWithModal
                  selectorDescription="Select an application to optimize your resume for"
                  redirectType="resume"
                  :resumeId="resumeId"
                  redirectRoute="AIResumeEditorWithResume"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Download Modal -->
    <DownloadModal ref="downloadModal" :resume="resume" />
    
    <!-- Upgrade Resume Modal -->
    <UpgradeResumeModal 
      ref="upgradeResumeModal" 
      :resumeId="resumeId" 
      @upgrade-complete="handleUpgradeComplete"
      @view-resume="viewNewResume"
    />
  </div>
</template>

<script setup lang="ts">
import { ref, watch, onMounted, computed } from "vue";
import { useRouter, useRoute } from "vue-router";
import { toast } from "vue3-toastify";
import "vue3-toastify/dist/index.css";
import BreadcrumbNav from "../components/BreadcrumbNav.vue";
import UpgradeResumeModal from "../components/UpgradeResumeModal.vue";

// Import types
import type {
  Resume,
  Project,
  Certification,
  AdditionalSkill,
  WorkExperience,
  Education,
  Activity,
} from "../../../server/src/models/Resume";
import { Tone } from "../../../server/src/models/SystemEnums";
import { ResponseDto, TemplateOptions } from "../../../server/src/models/dtos/ResponseDtos";

// Import components
import ResumeNavigation from "../components/resume-editor/ResumeNavigation.vue";
import ContactSection from "../components/resume-editor/ContactSection.vue";
import SummarySection from "../components/resume-editor/SummarySection.vue";
import WorkExperienceSection from "../components/resume-editor/WorkExperienceSection.vue";
import EducationSection from "../components/resume-editor/EducationSection.vue";
import AdditionalSection from "../components/resume-editor/AdditionalSection.vue";
import PdfPreview from "../components/resume-editor/PdfPreview.vue";
import TemplateSelector from "../components/resume-editor/TemplateSelector.vue";
import DownloadModal from "../components/DownloadModal.vue";
import ApplicationSelectorWithModal from "../components/ApplicationSelectorWithModal.vue"; // Import the new component

// Import services
import { DocumentApiService } from "../services/DocumentApiService";
import { FileApiService } from "../services/FilesApiService";
import { ResponseType } from "../../../server/src/models/Response";
import { ResumeGeneratorService } from "../services/ResumeGeneratorService";
import { ApplicationApiService } from "../services/ApplicationApiService";
import { onClickOutside } from "@vueuse/core";

// Component setup
const router = useRouter();
const route = useRoute();
const loading = ref(false);
const pdfUrl = ref<string>("");
const currentSection = ref("contact");
const hasUnsavedChanges = ref(false);
const originalResume = ref<Resume | null>(null);
const selectedWorkIndex = ref(0);
const selectedEducationIndex = ref(0);
const skillInputs = ref<string[]>([]);
const interestInput = ref("");
const techInput = ref("");
const isPreviewPending = ref(false);
const previewDebounceTimeout = ref<number | null>(null);
const downloadModal = ref<InstanceType<typeof DownloadModal> | null>(null);
const upgradeResumeModal = ref<InstanceType<typeof UpgradeResumeModal> | null>(null);
const isLoading = ref(false);
const loadingMessage = ref("Loading...");

// Application data for optimize tab
const loadingApplications = ref(false);

// Computed property for filtered applications
const filteredApplications = computed(() => {
  return [];
});

// Initialize the resume with default values
const resume = ref<Resume>({
  id: "",
  userId: "",
  name: "",
  lastModifiedAt: new Date(),
  previewUrl: null,
  template: TemplateOptions.RESUME_CLASSIC,
  contactInfo: {
    firstName: "",
    surname: "",
    email: "",
    phone: "",
    location: "",
    linkedin: "",
    websiteOrPortfolio: "",
    uuid: crypto.randomUUID(),
  },
  summary: {
    title: "",
    description: "",
    uuid: crypto.randomUUID(),
  },
  workExperience: [] as WorkExperience[],
  education: [] as Education[],
  projects: [] as Project[],
  activities: [] as Activity[],
  certifications: [] as Certification[],
  additionalSkills: [] as AdditionalSkill[],
  languages: [] as string[],
  interests: [] as string[],
});

// Watch for changes in the resume object
watch(
  () => JSON.stringify(resume.value),
  (newValue) => {
    if (originalResume.value) {
      hasUnsavedChanges.value = newValue !== JSON.stringify(originalResume.value);
    }
  },
  { deep: true }
);

// Consolidated preview handling
const updatePreview = async (force = false) => {
  if (!force && currentSection.value !== "preview") return;

  // Clear any pending preview update
  if (previewDebounceTimeout.value) {
    clearTimeout(previewDebounceTimeout.value);
  }

  // Set a debounced preview update
  previewDebounceTimeout.value = window.setTimeout(async () => {
    if (currentSection.value === "preview") {
      isPreviewPending.value = true;
      await previewResume();
      isPreviewPending.value = false;
    }
  }, 300);
};

// Watch for template changes
watch(
  () => resume.value.template,
  () => {
    // Immediately set loading state but keep previous PDF visible
    loading.value = true;
    updatePreview(true);
  }
);

// Watch for section changes
watch(
  () => currentSection.value,
  (newValue) => {
    if (newValue === "preview" && !pdfUrl.value) {
      updatePreview(true);
    }
  }
);

// Watch for resume changes to invalidate the preview
watch(
  () => JSON.stringify(resume.value),
  () => {
    if (pdfUrl.value) {
      URL.revokeObjectURL(pdfUrl.value);
      pdfUrl.value = "";
      updatePreview();
    }
  },
  { deep: true }
);

// Load resume data
onMounted(async () => {
  isLoading.value = true;
  loading.value = true;
  try {
    if (resumeId) {
      // Fetch existing resume
      const response = await DocumentApiService.getUserResume(resumeId);
      resume.value = response.data;
      originalResume.value = JSON.parse(JSON.stringify(response.data));
      
      ensureDefaults(resume.value);

      // Check if resume was created before March 19, 2025
      const resumeReworkDate =  new Date('2025-03-19');
      if (resume.value.id && isObjectIdCreatedBefore(resume.value.id, resumeReworkDate)) {
        // Show upgrade modal
        setTimeout(() => {
          upgradeResumeModal.value?.show();
        }, 500); // Small delay to ensure modal is properly mounted
      }
      else
      {
        ensureUuids(resume.value);
      }
    }
  } catch (error) {
    console.error("Error fetching resume:", error);
    toast.error("Failed to load resume", {
      position: toast.POSITION.TOP_RIGHT,
      autoClose: 3000,
    });
  } finally {
    isLoading.value = false;
    loading.value = false;
  }
});

function ensureDefaults(resume: Resume) {
  if (!resume.template)
  {
      resume.template = TemplateOptions.RESUME_CLASSIC;
  }
}

// Function to check if MongoDB ObjectId was created before a specific date
function isObjectIdCreatedBefore(objectId: string, targetDate: Date): boolean {
  if (!objectId || objectId.length !== 24) {
    return false;
  }
  
  // Extract timestamp from MongoDB ObjectId (first 4 bytes)
  const timestamp = parseInt(objectId.substring(0, 8), 16);
  
  // Convert to milliseconds (MongoDB timestamp is in seconds)
  const creationDate = new Date(timestamp * 1000);
  
  return creationDate < targetDate;
}

// Handle resume upgrade completion
function handleUpgradeComplete(updatedResume: Resume) {
  resume.value = updatedResume;
  toast.success("Resume upgraded successfully", {
    position: "top-right",
    autoClose: 3000,
  });

  originalResume.value = JSON.parse(JSON.stringify(updatedResume));
}

// Handle viewing the new resume
function viewNewResume(newResumeId: string) {
  router.push({
    name: "EditResume",
    params: { id: newResumeId }
  });
}

// Save resume changes
async function saveResume() {
  try {
    isLoading.value = true;
    loadingMessage.value = "Saving your resume...";

    const resumeData = { ...resume.value };
    await DocumentApiService.updateUserResume(resumeData);

    // Update last modified time
    resume.value.lastModifiedAt = new Date();

    // Reset unsaved changes flag
    hasUnsavedChanges.value = false;
    originalResume.value = JSON.parse(JSON.stringify(resume.value));
    // Update preview after saving
    updatePreview(true);
  } catch (error) {
    console.error("Error saving resume:", error);
    toast.error("Failed to save resume");
  } finally {
    isLoading.value = false;
  }
}

// Generate PDF preview
async function previewResume() {
  try {
    // Don't set loading.value here since we already set it in the template watcher
    // This prevents the PDF from disappearing during template changes
    if (!loading.value) {
      loading.value = true;
    }
    isLoading.value = true;
    loadingMessage.value = "Generating preview...";

    const resumeData = { ...resume.value };
    const response = await FileApiService.getPDFPreview(
      new ResponseDto(
        null,
        "",
        "",
        resume.value.userId,
        false,
        ResponseType.CreateResume,
        null,
        0.7,
        Tone.FORMAL,
        resume.value.template,
        "",
        resume.value,
        null,
        null
      )
    );
    const blob = new Blob([response.data], { type: "application/pdf" });
    if (pdfUrl.value) {
      URL.revokeObjectURL(pdfUrl.value);
    }
    pdfUrl.value = URL.createObjectURL(blob);
  } catch (error) {
    console.error("Error generating PDF preview:", error);
    toast.error("Failed to generate PDF preview");
  } finally {
    loading.value = false;
    isLoading.value = false;
  }
}

const openAccordionSections = ref<string[]>([]);

function toggleAccordion(sectionId: string) {
  const index = openAccordionSections.value.indexOf(sectionId);
  if (index === -1) {
    openAccordionSections.value.push(sectionId);
  } else {
    openAccordionSections.value.splice(index, 1);
  }
}

function isAccordionOpen(sectionId: string) {
  return openAccordionSections.value.includes(sectionId);
}

// Watch for preview mode and open first section
watch(
  () => currentSection.value,
  (newValue) => {
    if (newValue === "preview" && openAccordionSections.value.length === 0) {
      // Open the first non-preview section by default
      openAccordionSections.value.push("contact");
    }
  }
);

// Update the template selection function
function selectTemplate(template: TemplateOptions) {
  if (resume.value) {
    resume.value.template = template;
    // Show save button since template is part of resume data
    if (originalResume.value) {
      hasUnsavedChanges.value =
        JSON.stringify(resume.value) !== JSON.stringify(originalResume.value);
    }
  }
}

const selectedWork = computed(() => {
  const workExperience = resume.value.workExperience || [];
  if (!workExperience.length) return null;
  const work = workExperience[selectedWorkIndex.value];
  if (!work) return null;
  return {
    ...work,
    achievements: work.achievements || [],
  };
});

// Watch for changes in workExperience length
watch(
  () => resume.value?.workExperience?.length,
  (newLength) => {
    if (!newLength) {
      selectedWorkIndex.value = -1;
    } else if (selectedWorkIndex.value >= newLength) {
      selectedWorkIndex.value = newLength - 1;
    }
  }
);

const selectedEducation = computed(() => {
  if (!resume.value?.education?.length) return null;
  return resume.value.education[selectedEducationIndex.value] || null;
});

// Watch for changes in education length
watch(
  () => resume.value?.education?.length,
  (newLength) => {
    if (!newLength) {
      selectedEducationIndex.value = -1;
    } else if (selectedEducationIndex.value >= newLength) {
      selectedEducationIndex.value = newLength - 1;
    }
  }
);

// Computed property for filtered languages
const filteredLanguages = computed(() => {
  return resume.value.languages?.filter((lang) => lang?.trim() !== "") || [];
});

// Add this near the other computed properties
const filteredInterests = computed(() => {
  return resume.value.interests?.filter((interest) => interest?.trim() !== "") || [];
});

// Add function to generate UUIDs for new items
function generateUuid(): string {
  return crypto.randomUUID();
}

// Update addSkillItem to include UUID
function addSkillItem(index: number) {
  if (!skillInputs.value[index] || !skillInputs.value[index].trim()) return;

  const skills = resume.value.additionalSkills || [];
  if (!skills[index]) {
    skills[index] = {
      skillGroup: "",
      skillDetails: [],
      uuid: generateUuid(),
    };
  }

  if (!skills[index].skillDetails) {
    skills[index].skillDetails = [];
  }
  skills[index].skillDetails.push(skillInputs.value[index].trim());

  skillInputs.value[index] = "";
}

function removeSkillItem(groupIndex: number, skillIndex: number) {
  const skills = resume.value.additionalSkills || [];
  if (!skills[groupIndex] || !skills[groupIndex].skillDetails) return;

  // Filter out the skill at the specified index
  skills[groupIndex].skillDetails = skills[groupIndex].skillDetails.filter(
    (_, i: number) => i !== skillIndex
  );
}

function handleSkillBackspace(index: number) {
  if (!skillInputs.value[index]) {
    const skills = resume.value.additionalSkills || [];
    if (!skills[index] || !skills[index].skillDetails) return;

    // Remove the last skill in the array
    skills[index].skillDetails.pop();
  }
}

function handleInterestBackspace(event: KeyboardEvent) {
  if (!resume.value.interests) return;
  if (event.key === "Backspace" && !interestInput.value && resume.value.interests.length > 0) {
    event.preventDefault();
    resume.value.interests = resume.value.interests.slice(0, -1);
  }
}

// Computed property for each skill group's skills
function getFilteredSkills(skillDetails: string[] | undefined) {
  return skillDetails || [];
}

const resumeId = route.params.id as string;

function addTechnology(project: Project) {
  if (!techInput.value || !techInput.value.trim()) return;

  if (!project.achievements) {
    project.achievements = [];
  }

  project.achievements.push({
    achievement: `Technology: ${techInput.value.trim()}`,
    uuid: generateUuid(),
  });

  techInput.value = "";
}

function downloadDocument() {
  downloadModal.value?.show();
}

// Add these computed properties before the template
const workExperience = computed({
  get: () => resume.value.workExperience || [],
  set: (value) => {
    resume.value.workExperience = value;
  },
});

const education = computed({
  get: () => resume.value.education || [],
  set: (value) => {
    resume.value.education = value;
  },
});

const additionalSkills = computed({
  get: () => resume.value.additionalSkills || [],
  set: (value) => {
    resume.value.additionalSkills = value;
  },
});

const certifications = computed({
  get: () => resume.value.certifications || [],
  set: (value) => {
    resume.value.certifications = value;
  },
});

const projects = computed({
  get: () => resume.value.projects || [],
  set: (value) => {
    resume.value.projects = value;
  },
});

// Add function to ensure UUIDs exist on all items
function ensureUuids(resume: Resume) {
  if (!resume.contactInfo.uuid) {
    resume.contactInfo.uuid = generateUuid();
  }
  if (!resume.summary.uuid) {
    resume.summary.uuid = generateUuid();
  }

  resume.workExperience?.forEach((work) => {
    if (!work.uuid) work.uuid = generateUuid();
    work.achievements?.forEach((achievement) => {
      if (!achievement.uuid) achievement.uuid = generateUuid();
    });
  });

  resume.education?.forEach((edu) => {
    if (!edu.uuid) edu.uuid = generateUuid();
    edu.modules?.forEach((module) => {
      if (!module.uuid) module.uuid = generateUuid();
    });
  });

  resume.projects?.forEach((project) => {
    if (!project.uuid) project.uuid = generateUuid();
    project.achievements?.forEach((achievement) => {
      if (!achievement.uuid) achievement.uuid = generateUuid();
    });
  });

  resume.activities?.forEach((activity) => {
    if (!activity.uuid) activity.uuid = generateUuid();
    activity.achievements?.forEach((achievement) => {
      if (!achievement.uuid) achievement.uuid = generateUuid();
    });
  });

  resume.certifications?.forEach((cert) => {
    if (!cert.uuid) cert.uuid = generateUuid();
  });

  resume.additionalSkills?.forEach((skill) => {
    if (!skill.uuid) skill.uuid = generateUuid();
  });
}

// Watch for changes in the current section
watch(
  () => currentSection.value,
  (newValue) => {
    if (newValue === "optimize") {
      // No need to fetch applications as the component will handle it
    }
  }
);
</script>

<style scoped>
.resume-builder {
  height: 100vh;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  background: #f9fafb;
  padding: 1rem;
  padding-top: 3rem; /* Add space for the breadcrumb */
  gap: 1rem;
  position: relative; /* For absolute positioning of breadcrumb */
}

.edit-content {
  flex: 1;
  display: flex;
  overflow: hidden;
  position: relative;
  gap: 2rem;
}

.edit-panel {
  flex: 1;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.section-content {
  flex: 1;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  background: white;
  border-radius: 12px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  padding: 1.5rem;
}

.save-button-container {
  position: fixed;
  right: 2rem;
  top: 9rem;
  z-index: 10;
}

.btn-save-resume {
  display: inline-flex;
  align-items: center;
  gap: 0.5rem;
  background: #0c6efd;
  color: white;
  border: none;
  padding: 0.75rem 1.5rem;
  border-radius: 8px;
  font-size: 0.875rem;
  font-weight: 500;
  transition: all 0.2s ease;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.btn-save-resume:hover {
  background: #0b5ed7;
  transform: translateY(-1px);
}

.preview-mode {
  grid-template-columns: 1fr 300px;
}

.preview-mode .edit-panel {
  flex: 0 0 333px;
  max-width: 333px;
}

.breadcrumb-nav {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1;
}

.breadcrumb-link {
  display: inline-flex;
  align-items: center;
  color: #6b7280;
  text-decoration: none;
  font-size: 0.875rem;
  font-weight: 500;
  transition: color 0.2s ease;
}

.breadcrumb-link:hover {
  color: #374151;
}

.optimize-section {
  padding: 2rem;
  background: white;
  border-radius: 12px;
}

.section-title {
  font-weight: 600;
  color: #1f2937;
  text-align: center;
  margin-bottom: 0.5rem;
  font-size: 1.75rem;
}

.section-title-underline {
  width: 80px;
  height: 3px;
  background: #0d6efd;
  margin: 0 auto 1rem;
  border-radius: 3px;
}

.optimize-card {
  background: white;
  border-radius: 12px;
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.08);
  padding: 2rem;
  display: flex;
  flex-direction: column;
  transition: all 0.2s ease;
  border: 1px solid #f0f0f0;
}

.optimize-card:hover {
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.08);
}

.card-icon-container {
  display: flex;
  justify-content: center;
  margin-bottom: 1.5rem;
}

.card-icon {
  font-size: 1.5rem;
  color: #0d6efd;
  background: rgba(13, 110, 253, 0.1);
  width: 56px;
  height: 56px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
}

.card-title {
  font-weight: 600;
  font-size: 1.25rem;
  margin-bottom: 0.75rem;
  color: #1f2937;
}

.card-text {
  font-size: 0.95rem;
  color: #6b7280;
  line-height: 1.5;
}

.dropdown-container {
  position: relative;
  width: 100%;
}

.search-container {
  position: relative;
  display: flex;
  align-items: center;
  border: 1px solid #d1d5db;
  border-radius: 8px;
  background: white;
  transition: all 0.2s ease;
}

.search-container:focus-within {
  border-color: #0d6efd;
  box-shadow: 0 0 0 3px rgba(13, 110, 253, 0.15);
}

.search-input {
  padding: 0.75rem 1rem;
  border: none;
  outline: none;
  border-radius: 8px;
  width: 100%;
  font-size: 0.95rem;
  background: transparent;
}

.search-input:focus {
  box-shadow: none;
}

.search-icon {
  position: absolute;
  right: 1rem;
  font-size: 0.95rem;
  color: #6b7280;
}

.application-dropdown {
  position: absolute;
  top: calc(100% + 5px);
  left: 0;
  right: 0;
  background: white;
  border: 1px solid #d1d5db;
  border-radius: 8px;
  padding: 0.5rem;
  z-index: 10;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  max-height: 300px;
  overflow-y: auto;
}

.application-dropdown::-webkit-scrollbar {
  width: 6px;
}

.application-dropdown::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 10px;
}

.application-dropdown::-webkit-scrollbar-thumb {
  background: #d1d5db;
  border-radius: 10px;
}

.application-dropdown::-webkit-scrollbar-thumb:hover {
  background: #9ca3af;
}

.application-item {
  padding: 0.75rem 1rem;
  border-bottom: 1px solid #f0f0f0;
  cursor: pointer;
  transition: all 0.2s ease;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 6px;
}

.application-item:last-child {
  border-bottom: none;
}

.application-item:hover {
  background: #f9fafb;
}

.empty-state {
  text-align: center;
  padding: 2rem 1rem;
  background: #f9fafb;
  border-radius: 10px;
  border: 1px dashed #d1d5db;
  margin-top: 1.5rem;
}

.empty-state i {
  font-size: 2rem;
  color: #9ca3af;
  margin-bottom: 1rem;
}

.empty-state p {
  font-size: 0.95rem;
  color: #6b7280;
  margin-bottom: 0;
}

.no-results {
  padding: 1rem;
  text-align: center;
  font-size: 0.95rem;
  color: #6b7280;
  font-style: italic;
}

.btn-create-application {
  background: #0d6efd;
  color: white;
  border: none;
  padding: 0.75rem 1.5rem;
  border-radius: 8px;
  font-size: 0.95rem;
  font-weight: 500;
  transition: all 0.2s ease;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.btn-create-application:hover {
  background: #0b5ed7;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);
}

.app-details {
  flex: 1;
}

.app-details h5 {
  font-size: 1rem;
  font-weight: 600;
  margin-bottom: 0.25rem;
  color: #1f2937;
}

.company-name {
  font-size: 0.875rem;
  color: #6b7280;
  margin-bottom: 0;
}

.btn-select {
  background: #0d6efd;
  color: white;
  border: none;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.2s ease;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  margin-left: 1rem;
}

.btn-select:hover {
  background: #0b5ed7;
}

.btn-select i {
  font-size: 0.875rem;
}
</style>
