import { AxiosResponse } from "axios";
import { instance } from "./apiProvider/ApiProvider";
import { Resume } from "../../../server/src/models/Resume";
import { TemplateOptions } from "@models/dtos/ResponseDtos";

export class ResumeGeneratorService {
  public static async generateWordDocument(resume: Resume, template: TemplateOptions): Promise<AxiosResponse<{ fileId: string }>> {
    const axios = await instance();
    const response = await axios.post("/generateWord", {
      resume,
      template,
    });
    if (response.status === 200) {
      return response;
    } else {
      console.error(response);
      return response;
    }
  }

  public static async generatePdfDocument(resume: Resume, template: TemplateOptions): Promise<AxiosResponse<ArrayBuffer>> {
    const axios = await instance();
    const response = await axios.post("/resume-generator/generate-pdf", {
      resume,
      template,
    }, {
      responseType: 'arraybuffer'
    });
    if (response.status === 200) {
      return response;
    } else {
      console.error(response);
      return response;
    }
  }

  public static async downloadWordDocument(fileId: string): Promise<AxiosResponse<ArrayBuffer>> {
    const axios = await instance();
    const response = await axios.get(`/downloadWord/${fileId}`, {
      responseType: 'arraybuffer'
    });
    if (response.status === 200) {
      return response;
    } else {
      console.error(response);
      return response;
    }
  }
} 