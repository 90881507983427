<template>
  <div
    class="suggestion-item"
    :class="{
      'add-suggestion': suggestion.type === 'Add',
      'edit-suggestion': suggestion.type === 'Edit',
      'remove-suggestion': suggestion.type === 'Remove',
      'highlight-capable':
        suggestion.command === AI_RESUME_COMMANDS.EDIT_WORK_ACHIEVEMENT ||
        suggestion.command === AI_RESUME_COMMANDS.REMOVE_WORK_ACHIEVEMENT ||
        suggestion.command === AI_RESUME_COMMANDS.EDIT_SUMMARY ||
        suggestion.command === AI_RESUME_COMMANDS.EDIT_PROJECT ||
        suggestion.command === AI_RESUME_COMMANDS.ADD_PROJECT ||
        suggestion.command === AI_RESUME_COMMANDS.REMOVE_PROJECT,
    }"
    @mouseenter="handleSuggestionMouseEnter"
    @mouseleave="handleSuggestionMouseLeave"
  >
    <div class="suggestion-header">
      <p class="suggestion-description">{{ formatDescription(suggestion.description) }}</p>
    </div>

    <!-- Show the suggested change -->
    <div v-if="!isDeleteSuggestion(suggestion)">
      <div v-if="suggestion.metadata" class="suggestion-change">
        <!-- Use SkillGroup component for skill suggestions -->
        <SkillGroup
          v-if="isSkillSuggestion(suggestion)"
          :title="getSkillGroupTitle(suggestion)"
          :skills="getSkillGroupSkills(suggestion)"
          :interactive="true"
          :isRemoval="suggestion.type === 'Remove'"
          @update:skills="updateSkills"
          @update:title="updateTitle"
        />

        <!-- For non-skill suggestions, show content with edit button -->
        <div v-else class="content-wrapper">
          <div
            class="content"
            :contenteditable="isEditing"
            @input="handleContentEdit"
            @blur="handleContentBlur"
            @keydown.enter.prevent="handleContentEnter"
          >
            {{ getSuggestionChange(suggestion) }}
          </div>
          <button
            class="edit-button"
            @click="isEditing ? handleSaveContent() : startEditing()"
            :title="isEditing ? 'Save changes' : 'Edit suggestion'"
          >
            <i :class="isEditing ? 'bi bi-check' : 'bi bi-pencil'"></i>
          </button>
        </div>
      </div>
    </div>

    <div class="suggestion-footer">
      <span class="section-badge" :class="getSectionClass(suggestion.location)">
        {{ getLocationText(suggestion) }}
      </span>

      <div class="suggestion-actions">
        <button class="btn-reject" @click="$emit('reject', suggestion)" title="Reject">✕</button>
        <button
          class="btn-refresh"
          @click="$emit('refresh', suggestion)"
          title="Regenerate suggestion"
        >
          <i class="bi bi-arrow-clockwise"></i>
        </button>
        <button class="btn-accept" @click="$emit('accept', suggestion)" title="Accept">✓</button>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { defineProps, defineEmits, ref, nextTick, defineAsyncComponent } from "vue";
import { useSkillSuggestions } from "../../composables/useSkillSuggestions";
import { useSectionFormatting } from "../../composables/useSectionFormatting";
import {
  AddProjectAchievementMetadata,
  AddSummaryMetadata,
  AddWorkAchievementMetadata,
  AI_RESUME_COMMANDS,
  EditProjectAchievementMetadata,
  EditSummaryMetadata,
  EditWorkAchievementMetadata,
} from "../../../../server/src/models/dtos/ResumeSuggestionsOutput";
import { ResumeSuggestion } from "../../../../server/src/models/dtos/ResumeSuggestionsOutput";
import { Resume } from "@models/Resume";

// Import using defineAsyncComponent to handle the module without default export
const SkillGroup = defineAsyncComponent(() => import("./SkillGroup.vue"));

const props = defineProps<{
  suggestion: ResumeSuggestion;
  resume: Resume | null;
}>();

const emit = defineEmits<{
  (e: "accept", suggestion: ResumeSuggestion): void;
  (e: "reject", suggestion: ResumeSuggestion): void;
  (e: "refresh", suggestion: ResumeSuggestion): void;
  (e: "highlight-text", payload: { suggestion: ResumeSuggestion; highlight: boolean }): void;
  (e: "update:content", payload: { suggestion: ResumeSuggestion; content: string }): void;
}>();

// Local state
const isEditing = ref(false);

function getLocationText(suggestion: ResumeSuggestion) {
  if (suggestion.command === AI_RESUME_COMMANDS.ADD_WORK_ACHIEVEMENT) {
    const workExp = props.resume?.workExperience?.find((work) => work.uuid === suggestion.location);
    if (workExp) {
      return `${workExp.role} - ${workExp.company}`;
    }
  }
  if (
    suggestion.command === AI_RESUME_COMMANDS.EDIT_WORK_ACHIEVEMENT ||
    suggestion.command === AI_RESUME_COMMANDS.REMOVE_WORK_ACHIEVEMENT
  ) {
    const workExp = props.resume?.workExperience?.find((work) =>
      work.achievements?.some((achievement) => achievement.uuid === suggestion.location)
    );
    if (workExp) {
      return `${workExp.role} - ${workExp.company}`;
    }
  }
  if (
    suggestion.command === AI_RESUME_COMMANDS.EDIT_SUMMARY ||
    suggestion.command === AI_RESUME_COMMANDS.ADD_SUMMARY
  ) {
    return "Summary";
  }
  if (
    suggestion.command === AI_RESUME_COMMANDS.EDIT_PROJECT ||
    suggestion.command === AI_RESUME_COMMANDS.REMOVE_PROJECT
  ) {
    const project = props.resume?.projects?.find((project) =>
      project.achievements.some((p) => p.uuid === suggestion.location)
    );
    if (project) {
      return `${project.name}`;
    }
  }
  if (suggestion.command === AI_RESUME_COMMANDS.ADD_PROJECT) {
    const project = props.resume?.projects?.find((project) => project.uuid === suggestion.location);
    if (project) {
      return `${project.name}`;
    }
  }
  if (
    suggestion.command === AI_RESUME_COMMANDS.EDIT_SKILL_GROUP ||
    suggestion.command === AI_RESUME_COMMANDS.ADD_SKILL_GROUP ||
    suggestion.command === AI_RESUME_COMMANDS.REMOVE_SKILL
  ) {
    const skillGroup = props.resume?.additionalSkills?.find(
      (skillGroup) => skillGroup.uuid === suggestion.location
    );
    if (skillGroup) {
      return `${skillGroup.skillGroup}`;
    }
  }
  return "General";
}

function getSuggestionChange(suggestion: ResumeSuggestion) {
  if (
    suggestion.command === AI_RESUME_COMMANDS.EDIT_PROJECT ||
    suggestion.command === AI_RESUME_COMMANDS.ADD_PROJECT ||
    suggestion.command === AI_RESUME_COMMANDS.EDIT_WORK_ACHIEVEMENT ||
    suggestion.command === AI_RESUME_COMMANDS.ADD_WORK_ACHIEVEMENT
  ) {
    const metadata = suggestion.metadata as
      | AddProjectAchievementMetadata
      | AddWorkAchievementMetadata
      | EditProjectAchievementMetadata
      | EditWorkAchievementMetadata;
    return metadata?.achievement;
  }
  if (
    suggestion.command === AI_RESUME_COMMANDS.EDIT_SUMMARY ||
    suggestion.command === AI_RESUME_COMMANDS.ADD_SUMMARY
  ) {
    const metadata = suggestion.metadata as AddSummaryMetadata | EditSummaryMetadata;
    return metadata?.summary.description;
  }
  return suggestion.change;
}

function isDeleteSuggestion(suggestion: ResumeSuggestion): boolean {
  if (
    suggestion.command === AI_RESUME_COMMANDS.DELETE_SKILL_GROUP ||
    suggestion.command === AI_RESUME_COMMANDS.REMOVE_CERTIFICATION ||
    suggestion.command === AI_RESUME_COMMANDS.REMOVE_PROJECT ||
    suggestion.command === AI_RESUME_COMMANDS.REMOVE_WORK_ACHIEVEMENT ||
    suggestion.command === AI_RESUME_COMMANDS.REMOVE_SKILL ||
    suggestion.command === AI_RESUME_COMMANDS.REMOVE_EDUCATION
  ) {
    return true;
  }
  return false;
}
// Function to remove square brackets and their content from descriptions
function formatDescription(description: string): string {
  if (!description) return "";
  return description.replace(/\s*\[[^\]]*\]\s*/g, "");
}

// Import composables for skills and section formatting
const {
  isSkillSuggestion,
  getSkillGroupTitle,
  getSkillGroupSkills,
  updateSkills: handleUpdateSkills,
  updateTitle: handleUpdateTitle,
} = useSkillSuggestions();

const { formatSectionName, getSectionClass } = useSectionFormatting(props.resume);

// Handle skill group updates
function updateSkills(newSkills: string[]) {
  handleUpdateSkills(props.suggestion, newSkills);
}

function updateTitle(newTitle: string) {
  handleUpdateTitle(props.suggestion, newTitle);
}

// Handle mouse events for highlighting
function handleSuggestionMouseEnter() {
  if (
    props.suggestion.command === AI_RESUME_COMMANDS.EDIT_WORK_ACHIEVEMENT ||
    props.suggestion.command === AI_RESUME_COMMANDS.REMOVE_WORK_ACHIEVEMENT ||
    props.suggestion.command === AI_RESUME_COMMANDS.EDIT_SUMMARY ||
    props.suggestion.command === AI_RESUME_COMMANDS.EDIT_PROJECT ||
    props.suggestion.command === AI_RESUME_COMMANDS.ADD_PROJECT ||
    props.suggestion.command === AI_RESUME_COMMANDS.REMOVE_PROJECT ||
    props.suggestion.command === AI_RESUME_COMMANDS.EDIT_SKILL_GROUP ||
    props.suggestion.command === AI_RESUME_COMMANDS.DELETE_SKILL_GROUP
  ) {
    emit("highlight-text", {
      suggestion: props.suggestion,
      highlight: true,
    });
  }
}

function handleSuggestionMouseLeave() {
  if (
    props.suggestion.command === AI_RESUME_COMMANDS.EDIT_WORK_ACHIEVEMENT ||
    props.suggestion.command === AI_RESUME_COMMANDS.REMOVE_WORK_ACHIEVEMENT ||
    props.suggestion.command === AI_RESUME_COMMANDS.EDIT_SUMMARY ||
    props.suggestion.command === AI_RESUME_COMMANDS.EDIT_PROJECT ||
    props.suggestion.command === AI_RESUME_COMMANDS.ADD_PROJECT ||
    props.suggestion.command === AI_RESUME_COMMANDS.REMOVE_PROJECT ||
    props.suggestion.command === AI_RESUME_COMMANDS.EDIT_SKILL_GROUP ||
    props.suggestion.command === AI_RESUME_COMMANDS.ADD_SKILL_GROUP ||
    props.suggestion.command === AI_RESUME_COMMANDS.REMOVE_SKILL
  ) {
    emit("highlight-text", {
      suggestion: props.suggestion,
      highlight: false,
    });
  }
}

// Content editing functions
function handleContentEdit(event: Event) {
  const target = event.target as HTMLDivElement;
  const content = target.innerText;

  emit("update:content", {
    suggestion: props.suggestion,
    content,
  });
}

function handleContentBlur(event: Event) {
  const target = event.target as HTMLDivElement;
  const content = target.innerText.trim();

  emit("update:content", {
    suggestion: props.suggestion,
    content,
  });

  isEditing.value = false;
}

function handleContentEnter(event: KeyboardEvent) {
  event.preventDefault();
  const target = event.target as HTMLDivElement;
  const content = target.innerText;

  emit("update:content", {
    suggestion: props.suggestion,
    content,
  });

  (event.target as HTMLElement).blur();
}

function startEditing() {
  isEditing.value = true;
  nextTick(() => {
    const contentElement = document.querySelector('.content[contenteditable="true"]');
    if (contentElement) {
      contentElement.focus();

      // Place cursor at the end of content
      const range = document.createRange();
      const selection = window.getSelection();

      // Create a text node if there's no content
      if (!contentElement.firstChild) {
        contentElement.appendChild(document.createTextNode(""));
      }

      // Set selection to end of content
      const text = contentElement.firstChild as Text;
      const textLength = text.length;

      range.setStart(text, textLength);
      range.setEnd(text, textLength);

      if (selection) {
        selection.removeAllRanges();
        selection.addRange(range);
      }
    }
  });
}

function handleSaveContent() {
  isEditing.value = false;
}
</script>

<style scoped>
.suggestion-item {
  padding: 0.7rem;
  margin-bottom: 0.7rem;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.06);
  transition: transform 0.2s ease, box-shadow 0.2s ease;
  width: 100%;
  box-sizing: border-box;
}

.add-suggestion {
  background-color: #f0fff4;
}

.edit-suggestion {
  background-color: #fff9f0;
}

.remove-suggestion {
  background-color: #fff5f5;
}

.suggestion-item:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.08);
}

.suggestion-header {
  display: flex;
  align-items: center;
  margin-bottom: 8px;
  width: 100%;
}

.suggestion-description {
  margin: 0;
  font-weight: 500;
  color: #2c3e50;
  line-height: 1.3;
  font-size: 0.9rem;
  text-align: left;
}

.suggestion-change {
  background: rgba(255, 255, 255, 0.7);
  padding: 8px;
  border-radius: 6px;
  font-family: "Roboto Mono", monospace;
  font-size: 0.85rem;
  color: #495057;
  white-space: pre-wrap;
  width: 100%;
  box-sizing: border-box;
  text-align: left;
  margin-bottom: 8px;
}

.add-suggestion .suggestion-change {
  border-left: 2px solid #28a745;
}

.edit-suggestion .suggestion-change {
  border-left: 2px solid #fd7e14;
}

.remove-suggestion .suggestion-change {
  border-left: 2px solid #dc3545;
}

.suggestion-footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-top: 4px;
}

.suggestion-actions {
  display: flex;
  justify-content: flex-end;
  gap: 8px;
}

.btn-accept,
.btn-reject,
.btn-refresh {
  border: none;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: background-color 0.2s ease;
}

.btn-accept {
  background-color: #e6f7ef;
  color: #28a745;
}

.btn-reject {
  background-color: #f8e6e6;
  color: #dc3545;
}

.btn-refresh {
  background-color: #e3f2fd;
  color: #0d6efd;
}

.btn-accept:hover {
  background-color: #d1f0e0;
}

.btn-reject:hover {
  background-color: #f0d1d1;
}

.btn-refresh:hover {
  background-color: #cce5ff;
}

.btn-refresh i {
  font-size: 0.9rem;
}

/* Section badge styles */
.section-badge {
  font-size: 0.7rem;
  padding: 0.25rem 0.5rem;
  border-radius: 12px;
  font-weight: 600;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 60%;
}

.badge-experience {
  background-color: #e3f2fd;
  color: #0d6efd;
}

.badge-education {
  background-color: #e6f7ef;
  color: #198754;
}

.badge-skills {
  background-color: #fff3cd;
  color: #fd7e14;
}

.badge-summary {
  background-color: #f8d7da;
  color: #dc3545;
}

.badge-projects {
  background-color: #d1e7dd;
  color: #20c997;
}

.badge-achievements {
  background-color: #cff4fc;
  color: #0dcaf0;
}

.badge-general {
  background-color: #e9ecef;
  color: #6c757d;
}

/* Add a special badge style for skill groups */
.badge-skills.badge-skill-group {
  background-color: #4a5568;
  color: white;
}

.content-wrapper {
  position: relative;
  width: 100%;
}

.content-wrapper:hover .edit-button {
  opacity: 1;
}

.content {
  font-family: "Roboto Mono", monospace;
  outline: none;
}

.content[contenteditable="true"] {
  background: white;
  box-shadow: inset 0 0 0 2px rgba(13, 110, 253, 0.25);
  border-radius: 4px;
  padding: 4px;
}

.edit-button {
  position: absolute;
  bottom: 1px;
  right: 1px;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background: white;
  border: 1px solid #dee2e6;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  opacity: 0;
  transition: all 0.2s ease;
  color: #6c757d;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  z-index: 1;
}

.edit-button:hover {
  background: #f8f9fa;
  transform: scale(1.1);
}

.edit-button:hover i.bi-pencil {
  color: #0d6efd;
}

.edit-button:hover i.bi-check {
  color: #28a745;
}

/* Skill interaction note */
.skill-interaction-note {
  font-size: 0.75rem;
  color: #6c757d;
  margin-bottom: 8px;
  font-style: italic;
}

/* Add styles for highlight trigger */
.highlight-trigger {
  cursor: pointer;
  background-color: #f8f9fa;
  color: #0d6efd;
  padding: 5px 10px;
  border-radius: 4px;
  margin-bottom: 8px;
  display: inline-flex;
  align-items: center;
  font-size: 0.875rem;
  border: 1px solid #dee2e6;
}

.highlight-trigger:hover {
  background-color: #e9ecef;
}

.highlight-trigger i {
  margin-right: 5px;
}

.highlight-capable {
  position: relative;
}
</style>
