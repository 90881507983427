<template>
  <div class="application-selector">
    <div v-if="loading" class="text-center py-3">
      <div class="spinner-border text-primary" role="status">
        <span class="visually-hidden">Loading...</span>
      </div>
      <p class="text-muted mt-2 small">Loading your applications...</p>
    </div>

    <div v-else-if="applications.length === 0" class="empty-state">
      <div class="empty-state-icon">
        <i class="bi bi-briefcase"></i>
      </div>
      <h5 class="empty-state-title">No applications found</h5>
      <p class="empty-state-text">Create your first job application to get started</p>
      <button @click="createApplication" class="btn btn-primary">
        <i class="bi bi-plus-circle me-2"></i>Add Job Details
      </button>
    </div>

    <div v-else class="application-with-create">
      <div class="selector-container">
        <!-- Search input that's always visible -->
        <div class="search-container">
          <i class="bi bi-search search-icon"></i>
          <input
            type="text"
            class="form-control search-input"
            :placeholder="selectedApplication ? `${selectedApplication.roleTitle} at ${selectedApplication.companyName}` : 'Search applications...'"
            v-model="searchQuery"
            @focus="showDropdown = true"
            ref="searchInput"
          />
        </div>
        
        <!-- Custom dropdown that appears below the search input -->
        <div v-if="showDropdown" class="custom-dropdown">          
          <div class="dropdown-body">
            <div v-if="filteredApplications.length === 0 && !loading" class="no-results">
              <p class="text-muted mb-0">No matching applications found</p>
            </div>
            
            <div v-for="app in filteredApplications" :key="app._id" 
                 class="application-item"
                 @click="selectApp(app)">
              <div class="d-flex justify-content-between align-items-center">
                <div class="app-details">
                  <h5>{{ app.roleTitle }}</h5>
                  <p class="company-name">{{ app.companyName }}</p>
                </div>
                <span class="badge bg-light text-primary select-badge">Select</span>
              </div>
            </div>
            
            <div class="create-new-item" @click="createApplication">
              <div class="d-flex align-items-center">
                <i class="bi bi-plus-circle me-2"></i>
                <span>Create New Application</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      
      <!-- Divider with "or" in the middle -->
      <div class="divider-container">
        <div class="divider-line"></div>
        <div class="divider-text">or</div>
        <div class="divider-line"></div>
      </div>
      
      <button class="btn btn-primary create-app-button" @click="createApplication">
        <i class="bi bi-plus-circle me-2"></i>
        <span>Create New Application</span>
      </button>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref, defineProps, defineEmits, computed, onMounted, onUnmounted } from "vue";

interface Application {
  _id: string;
  roleTitle: string;
  companyName: string;
  [key: string]: any;
}

const props = defineProps({
  applications: {
    type: Array as () => Application[],
    default: () => [],
  },
  loading: {
    type: Boolean,
    default: false,
  },
});

const emit = defineEmits(["select-application", "create-application"]);

const selectedApplication = ref<Application | null>(null);
const searchQuery = ref("");
const searchInput = ref<HTMLInputElement | null>(null);
const showDropdown = ref(false);

const filteredApplications = computed(() => {
  if (!searchQuery.value) return props.applications;

  const query = searchQuery.value.toLowerCase();
  return props.applications.filter(
    (app) =>
      app.roleTitle.toLowerCase().includes(query) || app.companyName.toLowerCase().includes(query)
  );
});

function selectApp(app: Application) {
  selectedApplication.value = app;
  searchQuery.value = "";
  showDropdown.value = false;
  emit("select-application", app);
}

function createApplication() {
  searchQuery.value = "";
  showDropdown.value = false;
  emit("create-application");
}

// Close dropdown when clicking outside
function handleClickOutside(event: MouseEvent) {
  const element = event.target as HTMLElement;
  if (searchInput.value && !searchInput.value.contains(element) && 
      !element.closest('.custom-dropdown') && 
      !element.closest('.search-input')) {
    showDropdown.value = false;
  }
}

onMounted(() => {
  document.addEventListener('click', handleClickOutside);
  
  // Show selected application in search input if available
  if (props.applications.length > 0 && props.applications[0]) {
    selectedApplication.value = props.applications[0];
  }
});

onUnmounted(() => {
  document.removeEventListener('click', handleClickOutside);
});
</script>

<style scoped>
.application-selector {
  width: 100%;
}

.selector-container {
  position: relative;
  width: 100%;
}

.search-container {
  position: relative;
  width: 100%;
  margin-bottom: 8px;
}

.search-input {
  padding-left: 2.5rem;
  border-radius: 8px;
  border: 1px solid #e0e0e0;
  height: 38px;
  font-size: 0.95rem;
  transition: all 0.2s ease;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.05);
  width: 100%;
}

.search-input:focus {
  border-color: #0d6efd;
  box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
  outline: none;
}

.search-icon {
  position: absolute;
  left: 12px;
  top: 50%;
  transform: translateY(-50%);
  color: #6c757d;
}

/* Custom dropdown styling */
.custom-dropdown {
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  background: white;
  border-radius: 8px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
  z-index: 1050;
  margin-top: 4px;
  border: 1px solid #e0e0e0;
  max-height: 350px;
  display: flex;
  flex-direction: column;
}

.dropdown-body {
  overflow-y: auto;
  max-height: 300px;
  padding: 8px 0;
}

.no-results {
  padding: 16px;
  text-align: center;
}

.application-item {
  padding: 12px 16px;
  cursor: pointer;
  transition: background-color 0.2s ease;
}

.application-item:hover {
  background-color: #f8f9fa;
}

.app-details {
  flex: 1;
}

.app-details h5 {
  margin: 0;
  font-size: 0.95rem;
  font-weight: 600;
  color: #344767;
}

.company-name {
  margin: 0;
  font-size: 0.85rem;
  color: #6c757d;
}

.select-badge {
  font-size: 0.75rem;
  padding: 0.35em 0.65em;
  opacity: 0;
  transition: opacity 0.2s ease;
}

.application-item:hover .select-badge {
  opacity: 1;
}

.create-new-item {
  padding: 12px 16px;
  cursor: pointer;
  color: #0d6efd;
  font-weight: 500;
  transition: background-color 0.2s ease;
  border-top: 1px solid #e0e0e0;
  margin-top: 4px;
}

.create-new-item:hover {
  background-color: #f0f7ff;
}

.empty-state {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 1.5rem;
  text-align: center;
  background-color: #f8f9fa;
  border-radius: 10px;
  border: 1px dashed #dee2e6;
}

.empty-state-icon {
  font-size: 3rem;
  margin-bottom: 1rem;
  color: #6c757d;
}

.empty-state-title {
  font-weight: 600;
  font-size: 1.25rem;
  margin-bottom: 0.5rem;
}

.empty-state-text {
  font-size: 1rem;
  margin-bottom: 1.5rem;
  color: #6c757d;
}

.application-with-create {
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 100%;
}

.create-app-button {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
  font-weight: 500;
}

.divider-container {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 8px 0;
}

.divider-line {
  height: 1px;
  background-color: #e0e0e0;
  flex: 1;
}

.divider-text {
  font-size: 0.85rem;
  color: #6c757d;
  padding: 0 10px;
}

.sticky-top {
  position: sticky;
  top: 0;
}
</style>
