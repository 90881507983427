<template>
  <div class="container-fluid m-0 p-0">
    <BreadcrumbNav
      :back-link="`/application/${route.params.id}`"
      back-text="Application"
      current-page="Interview Analysis"
    />

    <div class="analysis-container">
      <div v-if="isLoading" class="text-center py-5">
        <div class="spinner-border text-primary" role="status">
          <span class="visually-hidden">Loading...</span>
        </div>
        <p class="mt-3 text-muted">Loading your interview analysis...</p>
      </div>

      <template v-else>
        <div class="analysis-header text-center mb-5">
          <h2 class="display-6 mb-3">Interview Analysis</h2>
          <p class="text-muted lead">Here's a detailed breakdown of your interview performance</p>
        </div>

        <!-- Overall Performance Card -->
        <div v-if="analysis" class="row g-4 mb-4">
          <div class="col-md-12">
            <div class="card overall-performance-card">
              <div class="card-body">
                <h4 class="card-title mb-4">Overall Performance</h4>
                <div class="overall-score-section mb-4">
                  <div class="score-gauge">
                    <div class="circle-progress">
                      <svg viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
                        <!-- Background circle -->
                        <circle
                          cx="50"
                          cy="50"
                          r="45"
                          fill="none"
                          stroke="#e2e8f0"
                          stroke-width="10"
                        />
                        <!-- Score circle -->
                        <circle
                          cx="50"
                          cy="50"
                          r="45"
                          fill="none"
                          :stroke="getScoreColor(analysis.overallMetrics.overallScore)"
                          stroke-width="10"
                          stroke-dasharray="282.7"
                          :stroke-dashoffset="getScoreOffset()"
                          transform="rotate(-90 50 50)"
                        />
                      </svg>
                      <div class="circle-content">
                        <div class="score-value">{{ analysis.overallMetrics.overallScore }}</div>
                        <div class="score-label">Score</div>
                      </div>
                    </div>
                  </div>
                  <div class="overall-feedback">
                    <i class="bi bi-quote me-2 text-primary"></i>
                    {{ analysis.overallMetrics.overallFeedback }}
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-8">
                    <div class="talk-time-stats">
                      <div class="talk-time-circle">
                        <div class="circle-progress">
                          <svg viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
                            <!-- Background circle -->
                            <circle
                              cx="50"
                              cy="50"
                              r="45"
                              fill="none"
                              stroke="#e2e8f0"
                              stroke-width="10"
                            />
                            <!-- Progress circle -->
                            <circle
                              cx="50"
                              cy="50"
                              r="45"
                              fill="none"
                              :stroke="getTalkTimeColor()"
                              stroke-width="10"
                              stroke-dasharray="282.7"
                              :stroke-dashoffset="getTalkTimeOffset()"
                              transform="rotate(-90 50 50)"
                            />
                          </svg>
                          <div class="circle-content">
                            <div class="talk-time-ratio">
                              {{ Math.round(getTalkTimeRatio() * 100) }}%
                            </div>
                            <div class="talk-time-label">Talk Time</div>
                          </div>
                        </div>
                      </div>
                      <div class="talk-time-details">
                        <div class="talk-time-item">
                          <div class="item-label">
                            <i class="bi bi-person-fill text-primary"></i>
                            Your Talk Time
                          </div>
                          <div class="item-value">
                            {{ Math.round(analysis.overallMetrics.totalTalkTime.interviewee / 60) }}
                            min
                          </div>
                        </div>
                        <div class="talk-time-item">
                          <div class="item-label">
                            <i class="bi bi-person-video3 text-primary"></i>
                            Interviewer Talk Time
                          </div>
                          <div class="item-value">
                            {{ Math.round(analysis.overallMetrics.totalTalkTime.interviewer / 60) }}
                            min
                          </div>
                        </div>
                        <div class="talk-time-feedback" :class="getTalkTimeClass('interviewee')">
                          {{ getTalkTimeContext("interviewee") }}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-4">
                    <div class="overall-stats">
                      <div class="stat-card">
                        <div class="stat-icon">
                          <i
                            class="bi bi-emoji-smile-fill"
                            :class="getSentimentClass(analysis.overallMetrics.overallSentiment)"
                          ></i>
                        </div>
                        <div class="stat-content">
                          <div class="stat-label">Overall Sentiment</div>
                          <div class="stat-value">
                            {{ analysis.overallMetrics.overallSentiment }}
                          </div>
                        </div>
                      </div>
                      <div class="stat-card">
                        <div class="stat-icon">
                          <i class="bi bi-chat-dots-fill text-primary"></i>
                        </div>
                        <div class="stat-content">
                          <div class="stat-label">Filler Words Used</div>
                          <div class="stat-value">
                            {{ analysis.overallMetrics.fillerWordCount }}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- Question Analysis Cards -->
        <div v-if="analysis" class="row g-4">
          <div class="col-12" v-for="(question, index) in analysis.questions" :key="index">
            <div class="card h-100 question-card">
              <div class="card-body">
                <div
                  class="question-header mb-4 d-flex justify-content-between align-items-center cursor-pointer"
                  @click="toggleAnswer(index)"
                  role="button"
                  :aria-expanded="expandedAnswers[index]"
                >
                  <div class="d-flex align-items-center gap-3">
                    <span class="question-number">Q{{ index + 1 }}</span>
                    <div
                      class="question-type-badge"
                      :class="getQuestionTypeClass(question.questionAnalysis.type)"
                    >
                      {{ question.questionAnalysis.type }}
                    </div>
                    <div
                      class="complexity-badge"
                      :class="getComplexityClass(question.questionAnalysis.complexity)"
                    >
                      {{ question.questionAnalysis.complexity }}
                    </div>
                  </div>
                  <i
                    class="bi"
                    :class="expandedAnswers[index] ? 'bi-chevron-up' : 'bi-chevron-down'"
                  ></i>
                </div>

                <div class="question-content mb-4">
                  <p class="question-text">{{ question.questionAnalysis.question }}</p>
                </div>

                <div v-show="expandedAnswers[index]" class="answer-section">
                  <div class="answer-content mb-4">
                    <h6 class="text-muted mb-2">Your Answer:</h6>
                    <p class="answer-text">{{ question.answerAnalysis.answer }}</p>
                  </div>

                  <div class="exemplar-content mb-4">
                    <div class="exemplar-header">
                      <i class="bi bi-check-circle-fill text-success me-2"></i>
                      Example Strong Answer
                    </div>
                    <div class="exemplar-text">
                      {{ question.answerAnalysis.examplarResponse }}
                    </div>
                  </div>

                  <div class="metrics-section mb-4">
                    <div class="metrics-grid">
                      <div class="metric-card">
                        <div class="metric-header">
                          <i class="bi bi-text-paragraph text-primary"></i>
                          <span>Word Count</span>
                        </div>
                        <div class="metric-value">{{ question.answerAnalysis.wordCount }}</div>
                        <div
                          class="metric-context"
                          :class="getWordCountClass(question.answerAnalysis.wordCount)"
                        >
                          {{ getWordCountContext(question.answerAnalysis.wordCount) }}
                        </div>
                      </div>

                      <div class="metric-card">
                        <div class="metric-header">
                          <i class="bi bi-bar-chart-fill text-primary"></i>
                          <span>Clarity Score</span>
                        </div>
                        <div class="clarity-score">
                          <div class="progress">
                            <div
                              class="progress-bar"
                              role="progressbar"
                              :style="{ width: question.answerAnalysis.clarityScore + '%' }"
                              :class="getClarityScoreClass(question.answerAnalysis.clarityScore)"
                            >
                              {{ question.answerAnalysis.clarityScore }}%
                            </div>
                          </div>
                        </div>
                      </div>

                      <div v-if="question.answerAnalysis.clarityScore >= 70" class="metric-card">
                        <div class="metric-header">
                          <i class="bi bi-emoji-smile-fill text-success"></i>
                          <span>Strong Response</span>
                        </div>
                        <div class="metric-value">Clear & Detailed</div>
                        <div class="metric-context text-success">
                          Your answer was well-structured
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="feedback-section">
                    <div class="feedback-column">
                      <h6 class="feedback-title text-success">
                        <i class="bi bi-check-circle-fill me-2"></i>Strengths
                      </h6>
                      <ul class="feedback-list strengths">
                        <li v-for="(strength, idx) in question.feedback.strengths" :key="idx">
                          {{ strength }}
                        </li>
                      </ul>
                    </div>
                    <div class="feedback-column">
                      <h6 class="feedback-title text-warning">
                        <i class="bi bi-lightbulb-fill me-2"></i>Areas for Improvement
                      </h6>
                      <ul class="feedback-list improvements">
                        <li v-for="(improvement, idx) in question.feedback.improvements" :key="idx">
                          {{ improvement }}
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </template>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref, onMounted, computed } from "vue";
import { useRoute, useRouter } from "vue-router";
import BreadcrumbNav from "@/components/BreadcrumbNav.vue";
import VChart from "vue-echarts";
import { use } from "echarts/core";
import { CanvasRenderer } from "echarts/renderers";
import { PieChart, BarChart } from "echarts/charts";
import {
  TitleComponent,
  TooltipComponent,
  LegendComponent,
  GridComponent,
} from "echarts/components";
import { toast } from "vue3-toastify";

// Register ECharts components
use([
  CanvasRenderer,
  PieChart,
  BarChart,
  TitleComponent,
  TooltipComponent,
  LegendComponent,
  GridComponent,
]);

const route = useRoute();
const router = useRouter();
const analysis = ref<AnalysisResults | null>(null);
const expandedAnswers = ref<boolean[]>([]);
const isLoading = ref(true);

interface TalkTime {
  interviewer: number;
  interviewee: number;
}

interface OverallMetrics {
  totalTalkTime: TalkTime;
  overallSentiment: "Positive" | "Neutral" | "Negative";
  fillerWordCount: number;
  overallScore: number;
  overallSummary: string;
}

interface QuestionAnalysis {
  question: string;
  type: "Technical" | "Behavioral" | "General" | "Situational";
  complexity: "Easy" | "Moderate" | "Hard" | "Difficult";
  expectedAnswer: string;
}

interface AnswerAnalysis {
  answer: string;
  wordCount: number;
  clarityScore: number;
  usesSTAR: boolean;
  examplarResponse: string;
}

interface Feedback {
  strengths: string[];
  improvements: string[];
}

interface Question {
  questionAnalysis: QuestionAnalysis;
  answerAnalysis: AnswerAnalysis;
  feedback: Feedback;
}

interface AnalysisResults {
  applicationId: string;
  userId: string;
  questions: Question[];
  overallMetrics: OverallMetrics;
  overallScore: number;
  overallFeedback: string;
}

onMounted(() => {
  // Get analysis from local storage
  const storedAnalysis = localStorage.getItem("interviewAnalysis");
  if (storedAnalysis) {
    try {
      analysis.value = JSON.parse(storedAnalysis);
      // Initialize expandedAnswers array with false values for each question
      if (analysis.value && analysis.value.questions) {
        expandedAnswers.value = new Array(analysis.value.questions.length).fill(false);

        // Only remove old analysis if there's already one stored for this application
        const applicationSpecificKey = `interviewAnalysis_${analysis.value.applicationId}`;
        const existingAnalysis = localStorage.getItem(applicationSpecificKey);
        if (existingAnalysis) {
          localStorage.removeItem("interviewAnalysis");
          localStorage.removeItem(applicationSpecificKey);
        }
      }
    } catch (error) {
      console.error("Error parsing interview analysis:", error);
      toast.error("Error loading interview analysis");
      router.push("/");
    } finally {
      isLoading.value = false;
    }
  } else {
    toast.error("No interview analysis found");
    router.push("/");
  }
});

const toggleAnswer = (index: number) => {
  expandedAnswers.value[index] = !expandedAnswers.value[index];
};

const overallMetricsChartOption = computed(() => ({
  tooltip: {
    trigger: "axis",
    backgroundColor: "rgba(255, 255, 255, 0.9)",
    borderWidth: 0,
    textStyle: { color: "#333" },
    extraCssText: "box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);",
  },
  grid: {
    left: "3%",
    right: "4%",
    bottom: "3%",
    containLabel: true,
  },
  xAxis: {
    type: "category",
    data: ["Interviewer Talk Time", "Interviewee Talk Time"],
    axisLine: { lineStyle: { color: "#eee" } },
    axisTick: { show: false },
  },
  yAxis: {
    type: "value",
    splitLine: { lineStyle: { color: "#eee" } },
    axisLine: { show: false },
    axisTick: { show: false },
  },
  series: [
    {
      type: "bar",
      data: [
        {
          value: analysis.value?.overallMetrics?.totalTalkTime?.interviewer || 0,
          itemStyle: { color: "#87CEEB" },
        },
        {
          value: analysis.value?.overallMetrics?.totalTalkTime?.interviewee || 0,
          itemStyle: { color: "#4682B4" },
        },
      ],
      itemStyle: {
        borderRadius: [4, 4, 0, 0],
      },
    },
  ],
}));

const getQuestionChartOption = (question: any) => ({
  tooltip: {
    trigger: "axis",
    backgroundColor: "rgba(255, 255, 255, 0.9)",
    borderWidth: 0,
    textStyle: { color: "#333" },
    extraCssText: "box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);",
  },
  grid: {
    left: "3%",
    right: "4%",
    bottom: "3%",
    containLabel: true,
  },
  xAxis: {
    type: "category",
    data: ["Word Count", "Clarity Score"],
    axisLine: { lineStyle: { color: "#eee" } },
    axisTick: { show: false },
  },
  yAxis: {
    type: "value",
    splitLine: { lineStyle: { color: "#eee" } },
    axisLine: { show: false },
    axisTick: { show: false },
  },
  series: [
    {
      type: "bar",
      data: [
        {
          value: question.answerAnalysis.wordCount,
          itemStyle: { color: "#87CEEB" },
        },
        {
          value: question.answerAnalysis.clarityScore,
          itemStyle: { color: "#4682B4" },
        },
      ],
      itemStyle: {
        borderRadius: [4, 4, 0, 0],
      },
    },
  ],
});

const getSentimentClass = (sentiment: "Positive" | "Neutral" | "Negative") => {
  const classes = {
    Positive: "text-success",
    Neutral: "text-warning",
    Negative: "text-danger",
  } as const;
  return classes[sentiment] || "text-primary";
};

const getQuestionTypeClass = (type: QuestionAnalysis["type"]) => {
  const classes = {
    Technical: "type-technical",
    Behavioral: "type-behavioral",
    General: "type-general",
    Situational: "type-behavioral", // Treat Situational as Behavioral for styling
  } as const;
  return classes[type] || "type-general";
};

const getComplexityClass = (complexity: QuestionAnalysis["complexity"]) => {
  const classes = {
    Easy: "complexity-easy",
    Moderate: "complexity-moderate",
    Hard: "complexity-hard",
    Difficult: "complexity-hard", // Treat Difficult as Hard for styling
  } as const;
  return classes[complexity] || "complexity-moderate";
};

const getWordCountContext = (wordCount: number) => {
  if (wordCount < 50) return "Too brief - aim for more detail";
  if (wordCount > 200) return "Consider being more concise";
  return "Good length";
};

const getWordCountClass = (wordCount: number) => {
  if (wordCount < 50) return "text-warning";
  if (wordCount > 200) return "text-warning";
  return "text-success";
};

const getClarityScoreClass = (score: number) => {
  if (score < 40) return "bg-danger";
  if (score < 70) return "bg-warning";
  return "bg-success";
};

const getTalkTimeClass = (role: "interviewer" | "interviewee") => {
  if (!analysis.value) return "";

  const intervieweeTalkTime = analysis.value.overallMetrics.totalTalkTime.interviewee;
  const interviewerTalkTime = analysis.value.overallMetrics.totalTalkTime.interviewer;
  const totalTime = intervieweeTalkTime + interviewerTalkTime;

  const intervieweeRatio = intervieweeTalkTime / totalTime;

  if (role === "interviewee") {
    if (intervieweeRatio < 0.4) return "text-warning";
    if (intervieweeRatio > 0.8) return "text-warning";
    return "text-success";
  } else {
    if (intervieweeRatio < 0.4) return "text-success";
    if (intervieweeRatio > 0.8) return "text-warning";
    return "text-success";
  }
};

const getTalkTimeContext = (role: "interviewer" | "interviewee") => {
  if (!analysis.value) return "";

  const intervieweeTalkTime = analysis.value.overallMetrics.totalTalkTime.interviewee;
  const interviewerTalkTime = analysis.value.overallMetrics.totalTalkTime.interviewer;
  const totalTime = intervieweeTalkTime + interviewerTalkTime;

  const intervieweeRatio = intervieweeTalkTime / totalTime;

  if (role === "interviewee") {
    if (intervieweeRatio < 0.4) return "Try to provide more detailed answers";
    if (intervieweeRatio > 0.8) return "Consider being more concise";
    return "Good balance of talk time";
  }
};

const getTalkTimeRatio = () => {
  if (!analysis.value) return 0;
  const total =
    analysis.value.overallMetrics.totalTalkTime.interviewee +
    analysis.value.overallMetrics.totalTalkTime.interviewer;
  return analysis.value.overallMetrics.totalTalkTime.interviewee / total;
};

const getTalkTimeOffset = () => {
  const ratio = getTalkTimeRatio();
  const circumference = 2 * Math.PI * 45; // r = 45
  return circumference * (1 - ratio);
};

const getTalkTimeColor = () => {
  const ratio = getTalkTimeRatio();
  if (ratio < 0.4) return "#fbbf24"; // warning yellow
  if (ratio > 0.8) return "#fbbf24"; // warning yellow
  return "#22c55e"; // success green
};

const getScoreOffset = () => {
  if (!analysis.value) return 282.7; // Full circle circumference
  const score = analysis.value.overallMetrics.overallScore;
  const percentage = Math.min(Math.max(score, 0), 100) / 100;
  const circumference = 2 * Math.PI * 45; // r = 45
  return circumference * (1 - percentage);
};

const getScoreColor = (score: number) => {
  if (score >= 80) return "#22c55e"; // success green
  if (score >= 60) return "#0d6efd"; // primary blue
  if (score >= 40) return "#fbbf24"; // warning yellow
  return "#ef4444"; // danger red
};
</script>

<style scoped>
.analysis-container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 2rem;
}

.chart {
  height: 300px;
  width: 100%;
}

.overall-performance-card {
  border: none;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.05);
  margin-bottom: 2rem;
}

.card-body {
  padding: 2rem;
}

.question-card {
  border: none;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.05);
  margin-bottom: 1.5rem;
}

.question-content {
  background: #f8fafc;
  border-radius: 0.75rem;
  padding: 1.5rem;
  margin: 1.5rem 0;
}

.answer-section {
  padding: 1.5rem;
  background: #f8fafc;
  border-radius: 0.75rem;
  margin-top: 1.5rem;
}

.exemplar-content {
  background: #fff;
  border-radius: 0.75rem;
  padding: 1.5rem;
  margin: 1.5rem 0;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.05);
}

.metrics-section {
  margin-top: 2rem;
}

.metrics-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 1.5rem;
  margin-top: 1rem;
}

.metric-card {
  background: #fff;
  border-radius: 0.75rem;
  padding: 1.5rem;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.05);
}

.stat-card {
  display: flex;
  align-items: center;
  padding: 1.25rem;
  background: white;
  border-radius: 1rem;
  margin-bottom: 1rem;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
  transition: transform 0.2s ease;
}

.stat-card:hover {
  transform: translateY(-2px);
}

.stat-icon {
  font-size: 1.5rem;
  margin-right: 1rem;
  padding: 0.75rem;
  background: #f8f9fa;
  border-radius: 0.75rem;
}

.stat-content {
  flex: 1;
}

.stat-label {
  font-size: 0.875rem;
  color: #64748b;
  margin-bottom: 0.25rem;
}

.stat-value {
  font-size: 1.25rem;
  font-weight: 600;
  color: #1e293b;
}

.question-header {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.question-number {
  font-size: 1.25rem;
  font-weight: 600;
  color: #0d6efd;
  background: #e6f0ff;
  padding: 0.5rem 1rem;
  border-radius: 0.75rem;
}

.question-type-badge,
.complexity-badge {
  padding: 0.35rem 0.75rem;
  border-radius: 0.5rem;
  font-size: 0.875rem;
  font-weight: 500;
}

.type-technical {
  background: #e6f0ff;
  color: #0d6efd;
}

.type-behavioral {
  background: #f0fdf4;
  color: #16a34a;
}

.type-general {
  background: #f1f5f9;
  color: #64748b;
}

.complexity-easy {
  background: #f0fdf4;
  color: #16a34a;
}

.complexity-moderate {
  background: #fef3c7;
  color: #d97706;
}

.complexity-hard {
  background: #fee2e2;
  color: #dc2626;
}

.question-text {
  font-weight: 500;
  color: #1e293b;
  margin-bottom: 0.75rem;
}

.answer-text {
  color: #64748b;
  font-style: italic;
}

.feedback-section {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1.5rem;
  background: #f8fafc;
  padding: 1.5rem;
  border-radius: 0.75rem;
}

.feedback-title {
  font-size: 1rem;
  margin-bottom: 1rem;
  display: flex;
  align-items: center;
}

.feedback-list {
  list-style: none;
  padding: 0;
  margin: 0;
}

.feedback-list li {
  margin-bottom: 0.75rem;
  padding: 0.75rem;
  background: white;
  border-radius: 0.5rem;
  font-size: 0.875rem;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.05);
}

.strengths li {
  border-left: 3px solid #16a34a;
}

.improvements li {
  border-left: 3px solid #ca8a04;
}

@media (max-width: 768px) {
  .feedback-section {
    grid-template-columns: 1fr;
  }

  .stat-card {
    margin-bottom: 0.75rem;
  }
}

.answer-section {
  transition: all 0.3s ease;
}

.btn-link {
  color: #64748b;
  padding: 0.5rem;
  border-radius: 0.5rem;
  transition: all 0.2s ease;
}

.btn-link:hover {
  background: #f1f5f9;
  color: #1e293b;
}

.answer-content {
  background: #f8fafc;
  padding: 1.5rem;
  border-radius: 0.75rem;
  margin-top: 1rem;
}

.question-header {
  border-bottom: none;
}

.question-text {
  font-size: 1.1rem;
  font-weight: 500;
  color: #1e293b;
  margin-bottom: 0;
}

.cursor-pointer {
  cursor: pointer;
  padding: 0.5rem;
  border-radius: 0.75rem;
  transition: background-color 0.2s ease;
}

.cursor-pointer:hover {
  background-color: #f1f5f9;
}

.question-header {
  border-bottom: none;
  margin: -0.5rem;
  margin-bottom: calc(1.5rem - 0.5rem);
}

.question-header i {
  color: #64748b;
  font-size: 1.25rem;
  transition: transform 0.2s ease;
}

.question-header:hover i {
  color: #1e293b;
}

.metric-header {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  color: #64748b;
  margin-bottom: 0.75rem;
}

.metric-value {
  font-size: 1.5rem;
  font-weight: 600;
  color: #1e293b;
}

.metric-context {
  font-size: 0.875rem;
  margin-top: 0.25rem;
}

.clarity-score {
  padding: 0.5rem 0;
}

.progress {
  height: 1.5rem;
  background-color: #f1f5f9;
  border-radius: 1rem;
  overflow: hidden;
}

.progress-bar {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  font-weight: 500;
  transition: width 0.3s ease;
}

.star-method {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  font-size: 1.1rem;
  padding: 0.5rem 0;
}

.star-method i {
  font-size: 1.5rem;
}

.talk-time-stats {
  display: grid;
  grid-template-columns: auto 1fr;
  gap: 2rem;
  padding: 1.5rem;
  background: white;
  border-radius: 1rem;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
  margin-bottom: 1.5rem;
}

.talk-time-circle {
  width: 150px;
  height: 150px;
  position: relative;
}

.circle-progress {
  width: 100%;
  height: 100%;
  position: relative;
}

.circle-progress svg {
  width: 100%;
  height: 100%;
  transform: rotate(-90deg);
}

circle {
  transition: stroke-dashoffset 0.5s ease;
}

.circle-content {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
}

.talk-time-ratio {
  font-size: 1.75rem;
  font-weight: 600;
  color: #1e293b;
  line-height: 1;
}

.talk-time-label {
  font-size: 0.875rem;
  color: #64748b;
  margin-top: 0.5rem;
}

.talk-time-details {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  justify-content: center;
}

.talk-time-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.75rem;
  background: #f8fafc;
  border-radius: 0.75rem;
}

.item-label {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  color: #64748b;
}

.item-value {
  font-weight: 600;
  color: #1e293b;
}

.talk-time-feedback {
  text-align: center;
  padding: 0.75rem;
  border-radius: 0.75rem;
  font-size: 0.875rem;
  background: #f8fafc;
}

@media (max-width: 768px) {
  .talk-time-stats {
    grid-template-columns: 1fr;
    justify-items: center;
    text-align: center;
  }
}

.overall-stats {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  height: 100%;
  justify-content: center;
}

.stat-card {
  background: white;
  padding: 1rem;
  border-radius: 0.75rem;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
  display: flex;
  align-items: center;
  gap: 1rem;
}

.stat-icon {
  font-size: 1.5rem;
  padding: 0.75rem;
  background: #f8fafc;
  border-radius: 0.75rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

@media (max-width: 768px) {
  .overall-stats {
    margin-top: 1rem;
  }
}

.overall-score-section {
  display: grid;
  grid-template-columns: auto 1fr;
  gap: 3rem;
  padding: 2rem;
  background: #f8fafc;
  border-radius: 1rem;
  align-items: center;
}

.score-gauge {
  width: 180px;
  height: 180px;
  position: relative;
  padding: 1rem;
  background: white;
  border-radius: 1rem;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.05);
}

.circle-progress {
  width: 100%;
  height: 100%;
  position: relative;
}

.score-value {
  font-size: 48px;
  font-weight: 700;
  color: #1e293b;
  line-height: 1;
}

.score-label {
  font-size: 14px;
  color: #64748b;
  margin-top: 0.5rem;
  text-transform: uppercase;
  letter-spacing: 0.05em;
}

.overall-feedback {
  font-size: 1.25rem;
  color: #1e293b;
  line-height: 1.6;
  font-style: italic;
  padding: 1.5rem;
  background: white;
  border-radius: 1rem;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.05);
}

@media (max-width: 768px) {
  .overall-score-section {
    grid-template-columns: 1fr;
    text-align: center;
    gap: 2rem;
  }

  .score-gauge {
    margin: 0 auto;
  }
}

.exemplar-header {
  color: #16a34a;
  font-weight: 600;
  font-size: 0.875rem;
  text-transform: uppercase;
  letter-spacing: 0.025em;
  margin-bottom: 1rem;
  display: flex;
  align-items: center;
}

.exemplar-text {
  color: #1e293b;
  line-height: 1.6;
  padding: 1rem;
  background: white;
  border-radius: 0.5rem;
  border-left: 3px solid #22c55e;
}
</style>
