<template>
  <div class="section">
    <div class="section-header">
      <h2>Contact Information</h2>
    </div>

    <div class="form-grid">
      <div class="form-group">
        <label>First Name</label>
        <input
          v-model="contactInfo.firstName"
          type="text"
          placeholder="e.g., John"
          class="form-control"
          @input="emitUpdate"
        />
      </div>
      <div class="form-group">
        <label>Surname</label>
        <input
          v-model="contactInfo.surname"
          type="text"
          placeholder="e.g., Smith"
          class="form-control"
          @input="emitUpdate"
        />
      </div>
      <div class="form-group col-span-2">
        <label>Location</label>
        <input
          v-model="contactInfo.location"
          type="text"
          placeholder="e.g., London, United Kingdom"
          class="form-control"
          @input="emitUpdate"
        />
      </div>
      <div class="form-group">
        <label>Email</label>
        <input v-model="contactInfo.email" type="email" class="form-control" @input="emitUpdate" />
      </div>
      <div class="form-group">
        <label>Phone</label>
        <input v-model="contactInfo.phone" type="tel" class="form-control" @input="emitUpdate" />
      </div>
      <div class="form-group">
        <label>LinkedIn (Optional)</label>
        <input v-model="contactInfo.linkedin" type="url" class="form-control" @input="emitUpdate" />
      </div>
      <div class="form-group">
        <label>Portfolio/Website (Optional)</label>
        <input
          v-model="contactInfo.websiteOrPortfolio"
          type="url"
          class="form-control"
          @input="emitUpdate"
        />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref, watch } from "vue";
import type { ContactInfo } from "../../../../server/src/models/Resume";

const props = defineProps<{
  modelValue: ContactInfo;
}>();

const emit = defineEmits<{
  (e: "update:modelValue", value: ContactInfo): void;
}>();

const contactInfo = ref<ContactInfo>({ ...props.modelValue });

watch(
  () => props.modelValue,
  (newValue) => {
    contactInfo.value = { ...newValue };
  },
  { deep: true }
);

function emitUpdate() {
  emit("update:modelValue", { ...contactInfo.value });
}
</script>

<style scoped>
.section {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.section-header {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 2rem;
}

.section-header h2 {
  font-size: 1.5rem;
  font-weight: 600;
  color: #111827;
  margin-bottom: 0.25rem;
}

.form-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 1rem;
}

.form-group {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.form-group.col-span-2 {
  grid-column: span 2;
  width: 100%;
}

label {
  font-size: 0.875rem;
  font-weight: 500;
  color: #374151;
  text-align: left;
  margin-bottom: 0.25rem;
}

.form-control {
  padding: 0.75rem;
  border: 1px solid #e5e7eb;
  border-radius: 8px;
  font-size: 0.875rem;
  transition: all 0.2s ease;
  background: #f9fafb;
}

.form-control:focus {
  outline: none;
  border-color: #0c6efd;
  background: white;
  box-shadow: 0 0 0 3px rgba(12, 110, 253, 0.1);
}
</style>
