<template>
  <div class="resume-list-container">
    <div class="resume-grid">
      <div v-if="loading" class="loading-state">
        <div class="spinner-border text-primary" role="status">
          <span class="visually-hidden">Loading...</span>
        </div>
        <p>Loading resumes...</p>
      </div>
      <div v-else-if="resumes.length === 0" class="empty-state">
        <i class="display-1 text-muted bi bi-file-post"></i>
        <h4 class="mt-3">No Resumes Found</h4>
        <p class="text-muted">Create a new resume to get started</p>
      </div>
      <div
        v-else
        v-for="resume in resumes"
        :key="resume.id"
        class="resume-card"
        @click="$emit('select', resume.id)"
      >
        <div class="resume-preview">
          <img :src="getPreviewImage(resume)" :alt="resume.name" />
        </div>
        <div class="resume-info">
          <h5 class="resume-name">{{ resume.name }}</h5>
          <p class="resume-date">Last modified: {{ formatDate(resume.lastModified) }}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { defineProps, defineEmits } from "vue";
import { format } from "date-fns";

interface ResumeListItem {
  id: string;
  name: string;
  lastModified: Date;
  previewUrl?: string | null;
  template?: string;
}

const props = defineProps<{
  resumes: ResumeListItem[];
  loading: boolean;
  templates: Record<string, any>;
}>();

defineEmits<{
  (e: "select", id: string): void;
}>();

function getPreviewImage(resume: ResumeListItem) {
  if (resume.previewUrl) {
    return resume.previewUrl;
  }
  // Fallback to template preview image
  return props.templates[0]?.previewImage || "";
}

function formatDate(date: Date | undefined | null): string {
  if (!date) return "";
  return format(new Date(date), "MMM d, yyyy");
}
</script>

<style scoped>
.resume-list-container {
  padding: 2rem;
  height: 100%;
  overflow-y: auto;
}

.resume-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  gap: 1.5rem;
}

.resume-card {
  background: white;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
  overflow: hidden;
  cursor: pointer;
  transition: all 0.2s ease;
}

.resume-card:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.resume-preview {
  aspect-ratio: 0.7071;
  overflow: hidden;
  background: #f8f9fa;
  border-bottom: 1px solid #e9ecef;
}

.resume-preview img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.resume-info {
  padding: 1rem;
}

.resume-name {
  margin: 0;
  font-size: 0.9rem;
  font-weight: 600;
  color: #344767;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.resume-date {
  margin: 0.25rem 0 0;
  font-size: 0.8rem;
  color: #6c757d;
}

.loading-state,
.empty-state {
  grid-column: 1 / -1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 3rem;
  text-align: center;
  color: #6c757d;
}
</style>
