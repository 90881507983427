<template>
  <div
    class="modal fade"
    :class="[
      modalXxl ? 'custom-modal-90' : '',
      modalLg ? 'modal-lg' : '',
      modalMd ? 'modal-md' : '',
    ]"
    id="exampleModal"
    tabindex="-1"
    ref="modalEle"
  >
    <div class="modal-dialog">
      <div class="modal-content">
        <div v-if="!hideHeader" class="modal-header border-0">
          <h5 class="modal-title" id="exampleModalLabel">{{ title }}</h5>
          <div v-if="!hideCloseButton" class="p-1">
            <button type="button" class="btn-close close-button" data-bs-dismiss="modal"></button>
          </div>
        </div>
        <div class="modal-body h-100">
          <slot name="body" />
        </div>
        <div v-if="!hideFooter" class="modal-footer border-0">
          <slot name="footer"></slot>

          <button
            v-if="!hideCloseButton"
            type="button"
            class="btn btn-secondary"
            data-bs-dismiss="modal"
          >
            Close
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, onMounted, onBeforeUnmount, ref } from "vue";
import { Modal as BootstrapModal } from "bootstrap";

export default defineComponent({
  name: "Modal",
  props: {
    title: {
      type: String,
      default: "",
    },
    hideCloseButton: {
      type: Boolean,
      default: false,
    },
    hideFooter: {
      type: Boolean,
      default: false,
    },
    hideHeader: {
      type: Boolean,
      default: false,
    },
    modalXxl: {
      type: Boolean,
      default: false,
    },
    modalLg: {
      type: Boolean,
      default: false,
    },
    modalMd: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    const modalEle = ref<HTMLElement | null>(null);
    let thisModalObj: BootstrapModal | null = null;

    // Function to clean up modal backdrop
    const cleanupModal = () => {
      if (thisModalObj) {
        thisModalObj.hide();

        // Remove backdrop manually if it exists
        const backdrop = document.querySelector(".modal-backdrop");
        if (backdrop) {
          backdrop.remove();
        }

        // Remove modal-open class from body
        document.body.classList.remove("modal-open");
        document.body.style.overflow = "";
        document.body.style.paddingRight = "";
      }
    };

    // Handle browser navigation events
    const handlePopState = () => {
      cleanupModal();
    };

    // Handler for modal hidden event
    const handleModalHidden = () => {
      // This ensures the modal is properly cleaned up when closed
      const backdrop = document.querySelector(".modal-backdrop");
      if (backdrop) {
        backdrop.remove();
      }
      document.body.classList.remove("modal-open");
      document.body.style.overflow = "";
      document.body.style.paddingRight = "";
    };

    onMounted(() => {
      if (modalEle.value) {
        thisModalObj = new BootstrapModal(modalEle.value);

        // Add event listener for modal hidden event
        modalEle.value.addEventListener("hidden.bs.modal", handleModalHidden);
      }

      // Add popstate event listener for browser navigation
      window.addEventListener("popstate", handlePopState);
    });

    onBeforeUnmount(() => {
      // Clean up event listeners and modal when component is unmounted
      window.removeEventListener("popstate", handlePopState);
      cleanupModal();

      if (modalEle.value) {
        modalEle.value.removeEventListener("hidden.bs.modal", handleModalHidden);
      }
    });

    function show() {
      thisModalObj?.show();
    }

    function hide() {
      thisModalObj?.hide();
    }

    return {
      modalEle,
      show,
      hide,
    };
  },
});
</script>

<style scoped>
.modal-header {
  display: flex;
  justify-content: center; /* Center the items horizontally */
}

.modal-title {
  flex-grow: 1; /* Allows the title to take up the maximum width */
  text-align: center; /* Centers the title text */
}

.modal-header .close-button {
  position: absolute;
  right: 1rem;
  top: 1rem;
}

.modal-md {
  padding-right: 0 !important;
}

.modal-md .modal-dialog {
  max-width: 40vw;
}

.modal-lg {
  padding-right: 0 !important;
}

.modal-lg .modal-dialog {
  max-width: 60vw;
  width: 60vw;
  height: 80vh;
  display: flex;
  align-items: center;
}

.modal-lg .modal-content {
  height: 100%;
  width: 100%;
  border-radius: 0.5rem;
  padding: 25px;
}

.modal-lg .modal-body {
  overflow-y: hidden;
  padding: 0;
  height: 100%;
}

.modal-lg.btn-close {
  paadding: 3px;
}

.modal-lg .modal-header {
  padding: 0;
}

.modal-lg .modal-header .btn-close {
  padding: 3px;
}

.custom-modal-90 {
  padding-right: 0 !important;
}

.custom-modal-90 .modal-dialog {
  max-width: 90vw;
  width: 90vw;
  height: 95vh;
  display: flex;
  align-items: center;
}

.custom-modal-90 .modal-content {
  height: 100%;
  width: 100%;
  border-radius: 0.5rem;
  padding: 0;
}

.custom-modal-90 .modal-body {
  overflow-y: hidden;
  padding: 0;
  height: 100%;
}

.custom-modal-90.btn-close {
  paadding: 3px;
}

.custom-modal-90 .modal-header {
  padding: 0;
}

/* New styles to ensure centering */
.modal.fade.custom-modal-90 .modal-dialog {
  transform: translate(0, 0);
  transition: transform 0.3s ease-out;
}

.modal.show.custom-modal-90 .modal-dialog {
  transform: translate(0, 0);
}
</style>
